import React, { useEffect, useMemo, useState } from 'react'

import useLogAndCaptureError from '../hooks/useLogAndCaptureError'
import useSiteMetadata from '../hooks/useSiteMetadata'
import { CartContext, useCartGet } from '../lib/cart'
import {
  autoRemoveErrantCartItems$,
  cartItemCheckSetFromFeatureFlags,
  latestCartItemErrors$,
} from '../lib/cart/state/cart-items'

const CartContextProvider: React.FC = ({ children }) => {
  const { featureFlags } = useSiteMetadata() ?? {}
  const [allowGuestCart, setAllowGuestCart] = useState(featureFlags?.anonymousCart ?? false)
  const { cart, error, loading, refetch } = useCartGet({ allowGuestCart })

  useLogAndCaptureError(error)

  useEffect(() => {
    const checkSet = cartItemCheckSetFromFeatureFlags(featureFlags)
    if (checkSet) {
      const subscriptions = [
        latestCartItemErrors$(checkSet).subscribe(),
        autoRemoveErrantCartItems$(checkSet).subscribe(),
      ]
      return () => subscriptions.forEach((subscription) => subscription.unsubscribe())
    }
  }, [featureFlags])

  const cartContext = useMemo(() => ({ cart, error, loading, setAllowGuestCart, refetch }), [
    cart,
    error,
    loading,
    setAllowGuestCart,
    refetch,
  ])

  return <CartContext.Provider value={cartContext}>{children}</CartContext.Provider>
}

export default CartContextProvider
