import { useCallback } from 'react'

import { useCartContext } from '../../../cart'
import { useEScriptParamsLoader } from '../../../escript/hooks/useEScriptParamsLoader'
import { invalidCouponCodeError, setEScriptErrors } from '../../../escript/state'
import { setDispensaryCartState } from '../../events/cart'
import { useDispensaryContext } from '../useDispensaryContext'
import { useAddProductsToCart } from './useAddProductsToCart'
import { isIgnorablePromoCodeError, useApplyPromoCodeToCart } from './useApplyPromoCodeToCart'
import { useEmptyCart } from './useEmptyCart'
import { useGtagEventTrigger } from './useGtagEventTrigger'
import { useRemoveDispensaryPromoCodeFromCart } from './useRemoveDispensaryPromoCodeFromCart'
import { useUpdateCartAttributes } from './useUpdateCartAttributes'

export type UseDispensaryUpdateCart = [updateCartDispensary: UpdateCartDispensary]

export type UpdateCartDispensary = (options: {
  forceClearEScriptParams: boolean
  shouldClearDispensaryPromoCode: boolean
}) => Promise<void>

export const useDispensaryUpdateCart = (): UseDispensaryUpdateCart => {
  const dispensaryContext = useDispensaryContext()
  const cartContext = useCartContext()
  const eScriptParamsLoader = useEScriptParamsLoader()

  const [emptyCart] = useEmptyCart({ cartContext, eScriptParamsLoader })
  const [addProductsToCart] = useAddProductsToCart({ eScriptParamsLoader })
  const [removeDispensaryPromoCodeFromCart] = useRemoveDispensaryPromoCodeFromCart({ cartContext })
  const [applyPromoCodeToCart] = useApplyPromoCodeToCart({ eScriptParamsLoader })
  const [updateCartAttributes] = useUpdateCartAttributes({ dispensaryContext, eScriptParamsLoader })
  const [triggerEvent] = useGtagEventTrigger({ eScriptParamsLoader })

  const { clearParams } = eScriptParamsLoader

  const updateCartDispensary = useCallback<UpdateCartDispensary>(
    async ({ forceClearEScriptParams, shouldClearDispensaryPromoCode }) => {
      await emptyCart()
      await addProductsToCart()
      await removeDispensaryPromoCodeFromCart({ shouldClearDispensaryPromoCode }).catch(
        (err: unknown) => {
          if (isIgnorablePromoCodeError(err)) {
            return
          }
          throw err
        }
      )
      await applyPromoCodeToCart().catch((err: unknown) => {
        if (isIgnorablePromoCodeError(err)) {
          const invalidCouponCodeErrorMessage = invalidCouponCodeError(err)
          if (invalidCouponCodeErrorMessage) {
            setEScriptErrors([
              { _tag: 'InvalidPromoCode', error_message: invalidCouponCodeErrorMessage },
            ])
          }
          console.warn(err)
          return
        }
        throw err
      })
      await updateCartAttributes({
        forceClearEScriptParams,
        shouldClearDispensaryPromoCode,
      })
      await triggerEvent()
      setDispensaryCartState({ type: 'cart_final' })
      clearParams()
    },
    [
      emptyCart,
      addProductsToCart,
      removeDispensaryPromoCodeFromCart,
      applyPromoCodeToCart,
      updateCartAttributes,
      triggerEvent,
      clearParams,
    ]
  )

  return [updateCartDispensary]
}
