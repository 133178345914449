import { ApolloClient, ObservableQuery } from '@apollo/client'
import { ApolloQueryResult } from '@apollo/client/core/types'
import { combineLatest, interval, Observable, switchMap } from 'rxjs'
import { fromInteropObservable } from 'rxjs/internal/observable/innerFrom'
import { catchError, filter, map } from 'rxjs/operators'

import { Exact, GetCustomerCartDocument, GetCustomerCartQuery } from '../../../graphql/magento'
import { isNotNull } from '../../../utils/collectionTools'
import { coerceCaughtToLeft } from '../../../utils/rx/errors'
import { latestAccessToken$ } from '../../auth/state'
import { latestApolloClient$ } from '../../graphql/apollo'

/**
 * Get observable query from Apollo client for customer's cart
 */
const getCustomerCartObservableQueryFactory = (
  client: ApolloClient<unknown>,
  token: string
): ObservableQuery<GetCustomerCartQuery, Exact<{ [key: string]: never }>> =>
  client.watchQuery({
    query: GetCustomerCartDocument,
    context: { token },
    // override global error policy
    errorPolicy: 'none',
  })

const watchGetCustomerCartFactory = (client: ApolloClient<unknown>, token: string) =>
  fromInteropObservable<ApolloQueryResult<GetCustomerCartQuery>>(
    getCustomerCartObservableQueryFactory(client, token)
  ).pipe(
    // ignore `errors` in FetchResult since `errorPolicy` is `none` (Apollo will throw if errors returned from server)
    map(({ data }) => ({ _tag: 'Right' as const, data })),
    catchError(coerceCaughtToLeft)
  )

/**
 * Combine latest Apollo client with access token and return the customer's cart, watching for updates.
 *
 * NOTE: this does not support guest carts.
 */
export const latestCustomerCart$: Observable<
  { _tag: 'Left'; error: Error } | { _tag: 'Right'; data: GetCustomerCartQuery }
> = combineLatest([latestApolloClient$, latestAccessToken$.pipe(filter(isNotNull))]).pipe(
  switchMap(([client, token]) => watchGetCustomerCartFactory(client, token))
)

/**
 * Combine latest Apollo client with access token and refetch the customer's cart with the given interval.
 */
export const refetchCustomerCartWithInterval$ = (
  milliseconds: number
): Observable<{ _tag: 'Left'; error: Error } | { _tag: 'Right'; data: GetCustomerCartQuery }> =>
  combineLatest([latestApolloClient$, latestAccessToken$.pipe(filter(isNotNull))]).pipe(
    switchMap(([client, token]) => {
      const obsQuery = getCustomerCartObservableQueryFactory(client, token)
      return interval(milliseconds).pipe(
        switchMap(() => obsQuery.refetch()),
        map(({ data }) => ({ _tag: 'Right' as const, data })),
        catchError(coerceCaughtToLeft)
      )
    })
  )
