import type { ApolloError } from '@apollo/client'
import type { Reducer } from 'react'

import type { CartFragment } from '../../../graphql/magento'
import { areCartsEqual } from '../utils'

export interface CartGetState {
  type: CartType
  cart?: CartFragment
  loading: boolean
  error?: ApolloError | Error
  loadingAuth: boolean
  guestStatus: CartGetActionStatus
  customerStatus: CartGetActionStatus
  mergeStatus: CartGetActionStatus
  guestCartId: string | undefined
  guestCartAllowed: boolean
  refetchRequested: boolean
}

export type CartType = 'none' | 'guest' | 'customer'

export type CartGetActionStatus = 'initial' | 'loading' | 'complete' | 'error'

export type CartActionStatusKey = 'guestStatus' | 'customerStatus' | 'mergeStatus'

export type CartGetAction =
  | { type: 'loggedIn' }
  | { type: 'loggedOut' }
  | { type: 'allowGuest' }
  | { type: 'disallowGuest' }
  | { type: 'updateGuestCartId'; guestCartId: string | undefined }
  | { type: 'guestLoadRequest' }
  | { type: 'guestLoadComplete'; cart: CartFragment }
  | { type: 'guestLoadError'; error: ApolloError | Error }
  | { type: 'customerLoadRequest' }
  | { type: 'customerLoadComplete'; cart: CartFragment }
  | { type: 'customerLoadError'; error: ApolloError | Error }
  | { type: 'mergeRequest' }
  | { type: 'mergeComplete'; cart: CartFragment }
  | { type: 'mergeError'; error: ApolloError | Error }
  | { type: 'cartUpdate'; cart: CartFragment }
  | { type: 'refetch' }

export const cartGetInitialState: CartGetState = {
  type: 'none',
  cart: undefined,
  loading: true,
  error: undefined,
  loadingAuth: true,
  guestStatus: 'initial',
  customerStatus: 'initial',
  mergeStatus: 'initial',
  guestCartId: undefined,
  guestCartAllowed: false,
  refetchRequested: false,
}

const reduceActionRequest = (
  prevState: CartGetState,
  typeCondition: CartType,
  statusKey: CartActionStatusKey
): CartGetState => {
  if (prevState.type !== typeCondition) {
    return prevState
  }
  return {
    ...prevState,
    loading: true,
    error: undefined,
    refetchRequested: false,
    [statusKey]: 'loading',
  }
}

const reduceActionComplete = (
  prevState: CartGetState,
  typeCondition: CartType,
  statusKey: CartActionStatusKey,
  { cart }: { cart: CartFragment }
): CartGetState => {
  if (prevState.type !== typeCondition) {
    return prevState
  }
  // don't empty cart while loading
  return {
    ...prevState,
    cart,
    loading: false,
    error: undefined,
    [statusKey]: 'complete',
  }
}

const reduceActionError = (
  prevState: CartGetState,
  typeCondition: CartType,
  statusKey: CartActionStatusKey,
  { error }: { error: ApolloError | Error }
): CartGetState => {
  if (prevState.type !== typeCondition) {
    return prevState
  }
  // don't empty cart on error, to keep previous cart
  return {
    ...prevState,
    loading: false,
    error,
    [statusKey]: 'error',
  }
}

const getPrevCartIfEqual = (
  prevCart: CartFragment | undefined,
  cart: CartFragment
): CartFragment => {
  return prevCart && areCartsEqual(prevCart, cart) ? prevCart : cart
}

export const reduceCartGet: Reducer<CartGetState, CartGetAction> = (
  prevState,
  action
): CartGetState => {
  if (action.type === 'loggedIn') {
    if (prevState.type === 'customer') {
      return {
        ...prevState,
        loadingAuth: false,
      }
    }
    // update cart type, empty cart
    return {
      ...prevState,
      type: 'customer',
      loadingAuth: false,
      cart: undefined,
    }
  }
  if (action.type === 'loggedOut') {
    if (prevState.type === 'customer') {
      // update cart type, empty cart
      return {
        ...prevState,
        type: prevState.guestCartAllowed ? 'guest' : 'none',
        loadingAuth: false,
        cart: undefined,
      }
    }
    if (prevState.type === 'none' && prevState.guestCartAllowed) {
      return {
        ...prevState,
        type: 'guest',
        loadingAuth: false,
        cart: undefined,
      }
    }
    if (prevState.type === 'guest' && !prevState.guestCartAllowed) {
      return {
        ...prevState,
        type: 'none',
        loadingAuth: false,
        cart: undefined,
      }
    }
    return {
      ...prevState,
      loadingAuth: false,
    }
  }

  if (action.type === 'allowGuest') {
    // only update cart type if auth finished loading
    if (prevState.loadingAuth) {
      return {
        ...prevState,
        guestCartAllowed: true,
      }
    }
    return {
      ...prevState,
      type: prevState.type === 'none' ? 'guest' : prevState.type,
      guestCartAllowed: true,
    }
  }
  if (action.type === 'disallowGuest') {
    // only update cart type if auth finished loading
    if (prevState.loadingAuth) {
      return {
        ...prevState,
        guestCartAllowed: false,
      }
    }
    return {
      ...prevState,
      type: prevState.type === 'guest' ? 'none' : prevState.type,
      guestCartAllowed: false,
    }
  }

  if (action.type === 'updateGuestCartId') {
    return {
      ...prevState,
      guestCartId: action.guestCartId,
    }
  }

  if (action.type === 'guestLoadRequest') {
    return reduceActionRequest(prevState, 'guest', 'guestStatus')
  }
  if (action.type === 'guestLoadComplete') {
    const nextState = reduceActionComplete(prevState, 'guest', 'guestStatus', {
      cart: getPrevCartIfEqual(prevState.cart, action.cart),
    })
    return {
      ...nextState,
      guestCartId: action.cart.id,
      loading: prevState.loadingAuth,
    }
  }
  if (action.type === 'guestLoadError') {
    return reduceActionError(prevState, 'guest', 'guestStatus', {
      error: action.error,
    })
  }

  if (action.type === 'customerLoadRequest') {
    return reduceActionRequest(prevState, 'customer', 'customerStatus')
  }
  if (action.type === 'customerLoadComplete') {
    const nextState = reduceActionComplete(prevState, 'customer', 'customerStatus', {
      cart: getPrevCartIfEqual(prevState.cart, action.cart),
    })
    return {
      ...nextState,
      loading: !!prevState.guestCartId,
    }
  }
  if (action.type === 'customerLoadError') {
    return reduceActionError(prevState, 'customer', 'customerStatus', {
      error: action.error,
    })
  }

  if (action.type === 'mergeRequest') {
    if (!prevState.guestCartId) {
      return prevState
    }
    const nextState = reduceActionRequest(prevState, 'customer', 'mergeStatus')
    return {
      ...nextState,
    }
  }
  if (action.type === 'mergeComplete') {
    if (!prevState.guestCartId) {
      return prevState
    }
    const nextState = reduceActionComplete(prevState, 'customer', 'mergeStatus', {
      cart: getPrevCartIfEqual(prevState.cart, action.cart),
    })
    return {
      ...nextState,
      guestCartId: undefined,
    }
  }
  if (action.type === 'mergeError') {
    if (prevState.type !== 'customer' || !prevState.guestCartId) {
      return prevState
    }
    const nextState = reduceActionError(prevState, 'customer', 'mergeStatus', {
      error: action.error,
    })
    return {
      ...nextState,
    }
  }

  if (action.type === 'cartUpdate') {
    return {
      ...prevState,
      cart: getPrevCartIfEqual(prevState.cart, action.cart),
      // avoid unintended consequences from cache updates triggered during merge actions
      ...(prevState.mergeStatus !== 'loading' && {
        loading: false,
        error: undefined,
      }),
    }
  }

  // Set refetchRequested causing guest/customerLoadRequest to occur
  if (action.type === 'refetch') {
    return prevState.refetchRequested
      ? prevState
      : {
          ...prevState,
          refetchRequested: true,
        }
  }

  return prevState
}
