import { ApolloError, useLazyQuery } from '@apollo/client'
import { useCallback, useContext, useEffect, useState } from 'react'

import { CartFragment, GetCustomerCartDocument } from '../../../graphql/magento'
import { TokenContext } from '../../../layouts/context'

export type UseCustomerCartGet = [
  getCustomerCart: (shouldRefetch: boolean) => void,
  state: {
    cart?: CartFragment
    loading: boolean
    error?: ApolloError | Error
    called: boolean
  }
]

export const useCustomerCartGet = (): UseCustomerCartGet => {
  const token = useContext(TokenContext)
  const [called, setCalled] = useState(false)
  const [doGetCustomerCart, { data, loading, error, refetch }] = useLazyQuery(
    GetCustomerCartDocument,
    {
      context: { token },
      // return partial data if available
      errorPolicy: 'all',
    }
  )

  const { customerCart: cart } = data || {}

  const getCustomerCart = useCallback(
    (shouldRefetch: boolean) => {
      shouldRefetch ? refetch() : setCalled(true)
    },
    [refetch]
  )

  useEffect(() => {
    if (!called || !token) {
      return
    }
    doGetCustomerCart()
  }, [called, token, doGetCustomerCart])

  return [getCustomerCart, { cart, loading, error, called }]
}
