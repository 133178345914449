import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: unknown
}

export type AppMetadata = {
  __typename?: 'AppMetadata'
  isEmployee?: Maybe<Scalars['Boolean']>
  isPLUnlicensed?: Maybe<Scalars['Boolean']>
  isPatient?: Maybe<Scalars['Boolean']>
  isPerson?: Maybe<Scalars['Boolean']>
  isPractitioner?: Maybe<Scalars['Boolean']>
  isProfessional?: Maybe<Scalars['Boolean']>
  netsuiteId?: Maybe<Scalars['String']>
  netsuitePriceLevel?: Maybe<Scalars['String']>
}

/** Metadata for collections */
export type CollectionMetadata = {
  __typename?: 'CollectionMetadata'
  pageInfo: PageInfo
  /** Date/time of collection snapshot */
  timestamp: Scalars['String']
  /** Total number of resources available */
  totalCount: Scalars['Int']
}

/** Continue request that was deferred after timeout */
export type ContinueDeferredInput = {
  /** Unique cursor for deferred request */
  cursor: Scalars['String']
}

export const CountryCode = {
  Ad: 'AD',
  Ae: 'AE',
  Af: 'AF',
  Ag: 'AG',
  Ai: 'AI',
  Al: 'AL',
  Am: 'AM',
  An: 'AN',
  Ao: 'AO',
  Aq: 'AQ',
  Ar: 'AR',
  As: 'AS',
  At: 'AT',
  Au: 'AU',
  Aw: 'AW',
  Ax: 'AX',
  Az: 'AZ',
  Ba: 'BA',
  Bb: 'BB',
  Bd: 'BD',
  Be: 'BE',
  Bf: 'BF',
  Bg: 'BG',
  Bh: 'BH',
  Bi: 'BI',
  Bj: 'BJ',
  Bl: 'BL',
  Bm: 'BM',
  Bn: 'BN',
  Bo: 'BO',
  Br: 'BR',
  Bs: 'BS',
  Bt: 'BT',
  Bv: 'BV',
  Bw: 'BW',
  By: 'BY',
  Bz: 'BZ',
  Ca: 'CA',
  Cc: 'CC',
  Cd: 'CD',
  Cf: 'CF',
  Cg: 'CG',
  Ch: 'CH',
  Ci: 'CI',
  Ck: 'CK',
  Cl: 'CL',
  Cm: 'CM',
  Cn: 'CN',
  Co: 'CO',
  Cr: 'CR',
  Cu: 'CU',
  Cv: 'CV',
  Cx: 'CX',
  Cy: 'CY',
  Cz: 'CZ',
  De: 'DE',
  Dj: 'DJ',
  Dk: 'DK',
  Dm: 'DM',
  Do: 'DO',
  Dz: 'DZ',
  Ec: 'EC',
  Ee: 'EE',
  Eg: 'EG',
  Eh: 'EH',
  Er: 'ER',
  Es: 'ES',
  Et: 'ET',
  Fi: 'FI',
  Fj: 'FJ',
  Fk: 'FK',
  Fm: 'FM',
  Fo: 'FO',
  Fr: 'FR',
  Ga: 'GA',
  Gb: 'GB',
  Gd: 'GD',
  Ge: 'GE',
  Gf: 'GF',
  Gg: 'GG',
  Gh: 'GH',
  Gi: 'GI',
  Gl: 'GL',
  Gm: 'GM',
  Gn: 'GN',
  Gp: 'GP',
  Gq: 'GQ',
  Gr: 'GR',
  Gs: 'GS',
  Gt: 'GT',
  Gu: 'GU',
  Gw: 'GW',
  Gy: 'GY',
  Hk: 'HK',
  Hm: 'HM',
  Hn: 'HN',
  Hr: 'HR',
  Ht: 'HT',
  Hu: 'HU',
  Id: 'ID',
  Ie: 'IE',
  Il: 'IL',
  Im: 'IM',
  In: 'IN',
  Io: 'IO',
  Iq: 'IQ',
  Ir: 'IR',
  Is: 'IS',
  It: 'IT',
  Je: 'JE',
  Jm: 'JM',
  Jo: 'JO',
  Jp: 'JP',
  Ke: 'KE',
  Kg: 'KG',
  Kh: 'KH',
  Ki: 'KI',
  Km: 'KM',
  Kn: 'KN',
  Kr: 'KR',
  Kw: 'KW',
  Ky: 'KY',
  Kz: 'KZ',
  La: 'LA',
  Lb: 'LB',
  Lc: 'LC',
  Li: 'LI',
  Lk: 'LK',
  Lr: 'LR',
  Ls: 'LS',
  Lt: 'LT',
  Lu: 'LU',
  Lv: 'LV',
  Ly: 'LY',
  Ma: 'MA',
  Mc: 'MC',
  Md: 'MD',
  Me: 'ME',
  Mf: 'MF',
  Mg: 'MG',
  Mh: 'MH',
  Mk: 'MK',
  Ml: 'ML',
  Mm: 'MM',
  Mn: 'MN',
  Mo: 'MO',
  Mp: 'MP',
  Mq: 'MQ',
  Mr: 'MR',
  Ms: 'MS',
  Mt: 'MT',
  Mu: 'MU',
  Mv: 'MV',
  Mw: 'MW',
  Mx: 'MX',
  My: 'MY',
  Mz: 'MZ',
  Na: 'NA',
  Nc: 'NC',
  Ne: 'NE',
  Nf: 'NF',
  Ng: 'NG',
  Ni: 'NI',
  Nl: 'NL',
  No: 'NO',
  Np: 'NP',
  Nr: 'NR',
  Nu: 'NU',
  Nz: 'NZ',
  Om: 'OM',
  Pa: 'PA',
  Pe: 'PE',
  Pf: 'PF',
  Pg: 'PG',
  Ph: 'PH',
  Pk: 'PK',
  Pl: 'PL',
  Pm: 'PM',
  Pn: 'PN',
  Pr: 'PR',
  Ps: 'PS',
  Pt: 'PT',
  Pw: 'PW',
  Py: 'PY',
  Qa: 'QA',
  Re: 'RE',
  Ro: 'RO',
  Rs: 'RS',
  Ru: 'RU',
  Rw: 'RW',
  Sa: 'SA',
  Sb: 'SB',
  Sc: 'SC',
  Sd: 'SD',
  Se: 'SE',
  Sg: 'SG',
  Sh: 'SH',
  Si: 'SI',
  Sj: 'SJ',
  Sk: 'SK',
  Sl: 'SL',
  Sm: 'SM',
  Sn: 'SN',
  So: 'SO',
  Sr: 'SR',
  St: 'ST',
  Sv: 'SV',
  Sy: 'SY',
  Sz: 'SZ',
  Tc: 'TC',
  Td: 'TD',
  Tf: 'TF',
  Tg: 'TG',
  Th: 'TH',
  Tj: 'TJ',
  Tk: 'TK',
  Tl: 'TL',
  Tm: 'TM',
  Tn: 'TN',
  To: 'TO',
  Tr: 'TR',
  Tt: 'TT',
  Tv: 'TV',
  Tw: 'TW',
  Tz: 'TZ',
  Ua: 'UA',
  Ug: 'UG',
  Um: 'UM',
  Us: 'US',
  Uy: 'UY',
  Uz: 'UZ',
  Va: 'VA',
  Vc: 'VC',
  Ve: 'VE',
  Vg: 'VG',
  Vi: 'VI',
  Vn: 'VN',
  Vu: 'VU',
  Wf: 'WF',
  Ws: 'WS',
  Ye: 'YE',
  Yt: 'YT',
  Za: 'ZA',
  Zm: 'ZM',
  Zw: 'ZW',
} as const

export type CountryCode = typeof CountryCode[keyof typeof CountryCode]
/** Order from which invoice created (if any) */
export type CreatedFromOrder = {
  __typename?: 'CreatedFromOrder'
  /** Order identifier (for API) */
  orderId: Scalars['String']
  /** Order number (for use in communication, print, display) */
  orderNumber: Scalars['String']
}

export const Currency = {
  Aud: 'AUD',
  Cad: 'CAD',
  Usd: 'USD',
} as const

export type Currency = typeof Currency[keyof typeof Currency]
export type CurrentPeriod = {
  __typename?: 'CurrentPeriod'
  /** Name of current period */
  period: Scalars['String']
  /** Rebates earned for this period */
  periodRebatesEarned: Scalars['Float']
  /** Projected balance at end of period */
  projectedEOMBalance: Scalars['Float']
  salesTotals: SalesTotals
}

/** Composed object including account balance data and metadata */
export type CustomerAccountBalance = {
  __typename?: 'CustomerAccountBalance'
  balanceOwed: Scalars['Float']
  creditLimit: Scalars['Float']
  currency: Currency
  /** Customer identifier */
  customerId: Scalars['String']
  daysOverdue: Scalars['Int']
  meta: ResourceMetadata
  /** Whether customer is currently on credit hold */
  onCreditHold?: Maybe<Scalars['Boolean']>
  overdueBalance: Scalars['Float']
  terms: Scalars['String']
}

/** Composed object including account history data and metadata */
export type CustomerAccountHistory = {
  __typename?: 'CustomerAccountHistory'
  balanceOwed: Scalars['Float']
  creditLimit: Scalars['Float']
  currency: Currency
  /** Customer identifier */
  customerId: Scalars['String']
  daysOverdue: Scalars['Int']
  meta: ResourceMetadata
  overdueBalance: Scalars['Float']
  terms: Scalars['String']
  /** Recent transactions */
  transactions: Array<Maybe<CustomerAccountHistoryTransaction>>
}

export type CustomerAccountHistoryTransaction = {
  __typename?: 'CustomerAccountHistoryTransaction'
  /** Amount debited from account */
  charge?: Maybe<Scalars['Float']>
  currency: Currency
  date: Scalars['String']
  /** Transaction identifier (for API) */
  id: Scalars['String']
  /** Amount credited to account */
  payment?: Maybe<Scalars['Float']>
  /** Transaction number (for use in communication, print, display) */
  transactionNumber: Scalars['String']
  /** Transaction type (e.g. invoice, payment) */
  type: Scalars['String']
}

export type CustomerAddress = {
  __typename?: 'CustomerAddress'
  addressee?: Maybe<Scalars['String']>
  attention?: Maybe<Scalars['String']>
  city: Scalars['String']
  /** Name of country */
  country: Scalars['String']
  /** ISO-3166-1 Alpha-2 code (two letter country code) */
  countryCode: CountryCode
  phone?: Maybe<Scalars['String']>
  state: Scalars['String']
  street: Array<Maybe<Scalars['String']>>
  zip: Scalars['String']
}

/** Websites publish this event when a customer accepts a clickwrap agreement. Event data may include any number of additional evidence fields to aide in proving authenticity. */
export type CustomerAgreementAcceptedEventInput = {
  /** Accepted agreement id */
  agreementId: Scalars['String']
  /** The internal id of the customer record in NetSuite */
  customerId: Scalars['String']
  /** Evidence of acceptance event's authenticity */
  evidence: EvidenceInput
  meta?: Maybe<EventMetadataInput>
  /** Additional user input as part of an agreement acceptance event */
  userInput?: Maybe<Scalars['JSON']>
}

/** Composed object including auto-ship data and metadata */
export type CustomerAutoShip = {
  __typename?: 'CustomerAutoShip'
  /** Auto-Ship identifier */
  autoShipId: Scalars['String']
  /** Auto-Ship number (for use in communication, print, display) */
  autoShipNumber: Scalars['String']
  currency: Currency
  /** Customer identifier */
  customerId: Scalars['String']
  frequency: Scalars['String']
  items: Array<Maybe<CustomerAutoShipLineItem>>
  meta: ResourceMetadata
  /** Date of next Auto-Ship */
  nextShipDate?: Maybe<Scalars['String']>
  originalOrder?: Maybe<CustomerOrder>
  /** Order number from which Auto-Ship originated */
  originalOrderNumber: Scalars['String']
  /** Collection of order ids related to Auto-Ship */
  relatedOrderIds?: Maybe<Array<Maybe<Scalars['String']>>>
  relatedOrders?: Maybe<Array<Maybe<CustomerOrder>>>
  shippingAddress: CustomerAddress
  startDate: Scalars['String']
  status: Scalars['String']
  /** External storefront order number referenced by Auto-Ship for future shipments */
  storefrontRefOrder?: Maybe<Scalars['String']>
}

export type CustomerAutoShipLineItem = {
  __typename?: 'CustomerAutoShipLineItem'
  /** Quantity of item */
  quantity: Scalars['Float']
  /** Cumulative number of shipments */
  shipmentCount: Scalars['Int']
  /** Item SKU */
  sku: Scalars['String']
}

export type CustomerAutoShipUpdateInput = {
  /** Enum to constrain the status types used in an Auto-Ship status update */
  status: CustomerAutoShipUpdateStatus
}

export const CustomerAutoShipUpdateStatus = {
  Active: 'ACTIVE',
  Suspended: 'SUSPENDED',
} as const

export type CustomerAutoShipUpdateStatus = typeof CustomerAutoShipUpdateStatus[keyof typeof CustomerAutoShipUpdateStatus]
export type CustomerAutoShips = {
  __typename?: 'CustomerAutoShips'
  /** Collection of Auto-Ship resources */
  autoShips: Array<Maybe<CustomerAutoShip>>
  /** Metadata for collections */
  meta: CollectionMetadata
}

/** Composed object including the practitioner's main dispensary data and metadata */
export type CustomerDispensary = {
  __typename?: 'CustomerDispensary'
  /** Creation date of the dispensary */
  createdDate: Scalars['String']
  meta: ResourceMetadata
  /** Dispensary name */
  name: Scalars['String']
  /** URL slug for the dispensary */
  slug: Scalars['String']
}

export type CustomerDispensaryPromo = {
  __typename?: 'CustomerDispensaryPromo'
  /** Promo code alias created by practitioner */
  dispensaryCode: Scalars['String']
  /** Date promo should be deactivated, chosen by the practitioner */
  expirationDate?: Maybe<Scalars['String']>
  /** NetSuite internal id of promo */
  id: Scalars['String']
  /** Is promo inactive */
  inactive: Scalars['Boolean']
  /** NetSuite internal id of promo storing the magento code for which the dispensary code is an alias */
  rootPromoId: Scalars['String']
  /** Display name of promo storing the magento code for which the dispensary code is an alias */
  rootPromoName: Scalars['String']
}

export type CustomerDispensaryPromoDeleted = {
  __typename?: 'CustomerDispensaryPromoDeleted'
  /** Promo code alias created by practitioner */
  dispensaryCode: Scalars['String']
  /** Informational messages generated during operation */
  messages?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CustomerDispensaryPromoNewInput = {
  /** Promo code alias created by practitioner */
  dispensaryCode: Scalars['String']
  /** Date promo should be deactivated */
  expirationDate?: Maybe<Scalars['String']>
  /** NetSuite internal id of promo storing the magento code for which the dispensary code is an alias */
  rootPromoId: Scalars['String']
}

export type CustomerDispensaryPromoUpdateInput = {
  /** Promo code alias created by practitioner */
  dispensaryCode: Scalars['String']
  /** Date promo should be deactivated */
  expirationDate?: Maybe<Scalars['String']>
  /** Is promo inactive */
  inactive: Scalars['Boolean']
  /** NetSuite internal id of promo storing the magento code for which the dispensary code is an alias */
  rootPromoId: Scalars['String']
}

/** Composed object including dispensary promo data and metadata */
export type CustomerDispensaryPromos = {
  __typename?: 'CustomerDispensaryPromos'
  meta: ResourceMetadata
  promos: Array<Maybe<CustomerDispensaryPromo>>
  rootPromos: Array<Maybe<CustomerDispensaryRootPromo>>
}

export type CustomerDispensaryRootPromo = {
  __typename?: 'CustomerDispensaryRootPromo'
  /** NetSuite internal id of root promo */
  id: Scalars['String']
  /** Display name of root promo */
  name: Scalars['String']
}

export type CustomerEhpMigration = {
  __typename?: 'CustomerEHPMigration'
  dispensaryUrl: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
}

/** Migrate existing EHP customer (status must be NS_CUSTOMER_FOUND) */
export type CustomerEhpMigrationNewInput = {
  dispensaryUrl: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
}

/** Result of checking existing EHP customer status. */
export type CustomerEhpStatus = {
  __typename?: 'CustomerEHPStatus'
  /** Code representing status of NetSuite customer and Auth0 user */
  status: CustomerEhpStatusCode
}

/** Check status of existing EHP customer. */
export type CustomerEhpStatusCheckInput = {
  email: Scalars['String']
}

export const CustomerEhpStatusCode = {
  NoneFound: 'NONE_FOUND',
  NsCustomerAndAuth0UserFound: 'NS_CUSTOMER_AND_AUTH0_USER_FOUND',
  NsCustomerFound: 'NS_CUSTOMER_FOUND',
} as const

export type CustomerEhpStatusCode = typeof CustomerEhpStatusCode[keyof typeof CustomerEhpStatusCode]
/** Composed object including invoice data and metadata */
export type CustomerInvoice = {
  __typename?: 'CustomerInvoice'
  amountRemaining: Scalars['Float']
  billingAddress: CustomerAddress
  coloradoRetailDeliveryFee?: Maybe<Scalars['Float']>
  /** Order from which invoice created (if any) */
  createdFromOrder?: Maybe<CreatedFromOrder>
  currency: Currency
  /** Customer identifier */
  customerId: Scalars['String']
  /** Invoice transaction date */
  date?: Maybe<Scalars['String']>
  discountTotal: Scalars['Float']
  dueDate?: Maybe<Scalars['String']>
  /** Transaction identifier (for use in API) */
  invoiceId: Scalars['String']
  /** Transaction number (for use in communication, print, display) */
  invoiceNumber: Scalars['String']
  items: Array<Maybe<CustomerInvoiceLineItem>>
  meta: ResourceMetadata
  /** Purchase order number, for customers to locate this invoice in their own records */
  otherRefNum?: Maybe<Scalars['String']>
  /** Deprecated, use date instead */
  shipDate?: Maybe<Scalars['String']>
  shippingAddress: CustomerAddress
  /** Total of shipping costs (including handling if any) */
  shippingTotal: Scalars['Float']
  /** Total excluding any discounts, shipping, tax */
  subtotal: Scalars['Float']
  taxTotal: Scalars['Float']
  /** Invoice total including discounts, shipping, tax */
  total: Scalars['Float']
}

export type CustomerInvoiceLineItem = {
  __typename?: 'CustomerInvoiceLineItem'
  /** Line amount total excluding discount */
  amount: Scalars['Float']
  /** Short description of item */
  description?: Maybe<Scalars['String']>
  discountAmount?: Maybe<Scalars['Float']>
  /** Item SKU */
  item: Scalars['String']
  /** Quantity invoiced */
  quantity: Scalars['Float']
  /** Unit price of item */
  rate: Scalars['Float']
}

export const CustomerInvoiceType = {
  CashSale: 'CASH_SALE',
  Invoice: 'INVOICE',
} as const

export type CustomerInvoiceType = typeof CustomerInvoiceType[keyof typeof CustomerInvoiceType]
export type CustomerInvoices = {
  __typename?: 'CustomerInvoices'
  /** Collection of invoice resources */
  invoices: Array<Maybe<CustomerInvoice>>
  /** Metadata for collections */
  meta: CollectionMetadata
}

/** Composed object including order data and metadata */
export type CustomerOrder = {
  __typename?: 'CustomerOrder'
  autoShip?: Maybe<CustomerAutoShip>
  /** Identifier of auto-ship associated with order */
  autoShipId?: Maybe<Scalars['String']>
  coloradoRetailDeliveryFee?: Maybe<Scalars['Float']>
  currency: Currency
  /** Customer identifier */
  customerId: Scalars['String']
  date: Scalars['String']
  discountTotal: Scalars['Float']
  fulfillments: Array<Maybe<CustomerOrderFulfillment>>
  /** Should order be held until nearest Monday to ship? */
  holdUntilMonday: Scalars['Boolean']
  /** Invoices or cash sales associated with order */
  invoices?: Maybe<Array<Maybe<CustomerOrderInvoice>>>
  items: Array<Maybe<CustomerOrderLineItem>>
  meta: ResourceMetadata
  /** Order identifer (for API) */
  orderId: Scalars['String']
  /** Order number (for use in communication, print, display) */
  orderNumber: Scalars['String']
  payment: CustomerOrderPayment
  promoCode?: Maybe<Scalars['String']>
  refundTotal: Scalars['Float']
  /** Shipping method selected for order */
  shipMethod: Scalars['String']
  shippingAddress: CustomerAddress
  /** Total of shipping costs (including handling if any) */
  shippingTotal: Scalars['Float']
  status: Scalars['String']
  /** Originating storefront identifier */
  storefront: Scalars['String']
  /** Storefront specific order number */
  storefrontOrderNumber: Scalars['String']
  /** Total excluding any discounts, shipping, tax */
  subtotal: Scalars['Float']
  taxTotal: Scalars['Float']
  /** Order total including discounts, shipping, tax */
  total: Scalars['Float']
}

export type CustomerOrderFulfillment = {
  __typename?: 'CustomerOrderFulfillment'
  /** Date of fulfillment */
  date: Scalars['String']
  items: Array<Maybe<CustomerOrderFulfillmentLineItem>>
  shipMethod: Scalars['String']
  /** Shipment status */
  status: Scalars['String']
  trackingLink?: Maybe<Scalars['String']>
  trackingNumber?: Maybe<Scalars['String']>
  warehouseLocation: Scalars['String']
}

export type CustomerOrderFulfillmentLineItem = {
  __typename?: 'CustomerOrderFulfillmentLineItem'
  /** Quantity of item in fulfillment */
  quantity: Scalars['Float']
  sku: Scalars['String']
}

export type CustomerOrderInvoice = {
  __typename?: 'CustomerOrderInvoice'
  /** Transaction identifier (for API) */
  id: Scalars['String']
  /** Transaction number (for use in communication, print, display) */
  transactionNumber: Scalars['String']
  type: CustomerInvoiceType
}

export type CustomerOrderLineItem = {
  __typename?: 'CustomerOrderLineItem'
  /** Line amount total excluding discount */
  amount: Scalars['Float']
  discountAmount: Scalars['Float']
  /** Is item heat sensitive? */
  heatSensitive: Scalars['Boolean']
  /** Order quantity */
  quantity: Scalars['Float']
  /** Quantity on backorder */
  quantityBackordered?: Maybe<Scalars['Float']>
  /** Quantity invoiced */
  quantityInvoiced?: Maybe<Scalars['Float']>
  quantityRefunded: Scalars['Float']
  /** Quantity shipped (if fulfillable) */
  quantityShipped: Scalars['Float']
  /** Unit price of item */
  rate: Scalars['Float']
  sku: Scalars['String']
}

export type CustomerOrderPayment = {
  __typename?: 'CustomerOrderPayment'
  creditCard?: Maybe<CustomerOrderPaymentCreditCard>
  /** Whether a gift certificate was applied to order */
  giftCertificateApplied: Scalars['Boolean']
  /** Terms of net order */
  terms?: Maybe<Scalars['String']>
}

export type CustomerOrderPaymentCreditCard = {
  __typename?: 'CustomerOrderPaymentCreditCard'
  /** Credit card expiration month and year */
  ccExpDate?: Maybe<Scalars['String']>
  /** Last 4 digits of credit card */
  ccLast4?: Maybe<Scalars['String']>
  /** Credit card type */
  ccType: Scalars['String']
}

export const CustomerOrderTag = {
  AutoShip: 'AUTO_SHIP',
  GeneratedLead: 'GENERATED_LEAD',
} as const

export type CustomerOrderTag = typeof CustomerOrderTag[keyof typeof CustomerOrderTag]
export type CustomerOrders = {
  __typename?: 'CustomerOrders'
  /** Metadata for collections */
  meta: CollectionMetadata
  /** Collection of order resources */
  orders: Array<Maybe<CustomerOrder>>
}

/** Deprecated, use CustomerRegistrationPatientV2 instead. */
export type CustomerPatientData = {
  __typename?: 'CustomerPatientData'
  category: Scalars['Float']
  email: Scalars['String']
  firstName: Scalars['String']
  internalid: Scalars['Float']
  isPerson: Scalars['Boolean']
  lastName: Scalars['String']
  practitionerCode: Scalars['String']
  subsidiary: Scalars['Float']
  webStoreRole: Scalars['Float']
}

/** Deprecated, use CustomerRegistrationPractitionerV2 instead. */
export type CustomerPractitionerData = {
  __typename?: 'CustomerPractitionerData'
  category: Scalars['Float']
  companyName: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  internalid: Scalars['Float']
  isPerson: Scalars['Boolean']
  lastName: Scalars['String']
  licenseNumber: Scalars['String']
  phone: Scalars['String']
  subsidiary: Scalars['Float']
  webStoreRole: Scalars['Float']
}

/** Composed object including rebate balance info and metadata */
export type CustomerRebateBalance = {
  __typename?: 'CustomerRebateBalance'
  currency: Currency
  currentBalance: Scalars['Float']
  customerId: Scalars['String']
  meta: ResourceMetadata
  pendingRebatesEarned: Scalars['Float']
  storeCreditBalance: Scalars['Float']
}

/** Composed object including rebate estimate info and metadata */
export type CustomerRebateEstimate = {
  __typename?: 'CustomerRebateEstimate'
  currency: Currency
  currentPeriod: CurrentPeriod
  customerId: Scalars['String']
  meta: ResourceMetadata
}

export type CustomerRebateRedemptionCashRequest = {
  __typename?: 'CustomerRebateRedemptionCashRequest'
  /** Cash value (in USD) of rebates to redeem */
  cashValue: Scalars['Float']
  /** Customer identifier */
  customerId: Scalars['String']
  /** Rebate value (in USD) to redeem for cash */
  rebateValue: Scalars['Float']
  /** Request/ticket identifier */
  requestId: Scalars['String']
  /** Status of request/ticket (e.g. New, Open, Pending, Solved, Closed) */
  status: Scalars['String']
}

export type CustomerRebateRedemptionCashRequestNewInput = {
  /** Rebate value (in USD) to redeem for cash */
  rebateValue: Scalars['Float']
}

export type CustomerRegistrationAddressNewInput = {
  /** City/town/locality */
  city: Scalars['String']
  /** ISO-3166-1 Alpha-2 code (two letter country code) */
  countryCode: CountryCode
  /** Phone number */
  phone: Scalars['String']
  /** ISO-3166-2 code (two letter state code) */
  state: Scalars['String']
  /** Street address lines */
  street: Array<Maybe<Scalars['String']>>
  /** Postal/zip code */
  zip: Scalars['String']
}

/** Auth0 properties for new customer registration */
export type CustomerRegistrationAuth0 = {
  __typename?: 'CustomerRegistrationAuth0'
  appMetadata: AppMetadata
  userMetadata?: Maybe<UserMetadata>
}

/** Customer patient created outside of NetSuite. Deprecated, use CustomerRegistrationPatientNew instead. */
export type CustomerRegistrationPatientInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  practitionerCode: Scalars['String']
  type: CustomerRegistrationTypePatient
}

/** Customer patient created outside of NetSuite. */
export type CustomerRegistrationPatientNewInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  inboundPatientZip?: Maybe<Scalars['String']>
  lastName: Scalars['String']
  practitionerCode?: Maybe<Scalars['String']>
  type: CustomerRegistrationTypePatient
}

export type CustomerRegistrationPatientV2 = {
  __typename?: 'CustomerRegistrationPatientV2'
  /** Auth0 properties for new customer registration */
  auth0: CustomerRegistrationAuth0
  email: Scalars['String']
  firstName: Scalars['String']
  inboundPatientZip?: Maybe<Scalars['String']>
  lastName: Scalars['String']
  practitionerCode?: Maybe<Scalars['String']>
}

/** Customer practitioner created outside of NetSuite. Deprecated, use CustomerRegistrationPractitionerNew instead. */
export type CustomerRegistrationPractitionerInput = {
  companyName: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  licenseNumber: Scalars['String']
  phone: Scalars['String']
  specialty: Scalars['String']
  state: Scalars['String']
  type: CustomerRegistrationTypePractitioner
  zipCode: Scalars['String']
}

/** Customer practitioner created outside of NetSuite. */
export type CustomerRegistrationPractitionerNewInput = {
  businessAddress: CustomerRegistrationAddressNewInput
  companyName: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  licenseNumber: Scalars['String']
  /** NetSuite internal id of specialty list option */
  specialty: Scalars['String']
  type: CustomerRegistrationTypePractitioner
}

export type CustomerRegistrationPractitionerV2 = {
  __typename?: 'CustomerRegistrationPractitionerV2'
  /** Auth0 properties for new customer registration */
  auth0: CustomerRegistrationAuth0
  companyName: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  licenseNumber: Scalars['String']
}

export type CustomerRegistrationPrivateLabelPractitioner = {
  __typename?: 'CustomerRegistrationPrivateLabelPractitioner'
  /** Auth0 properties for new customer registration */
  auth0: CustomerRegistrationAuth0
  companyName: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  licenseNumber: Scalars['String']
}

/** Customer practitioner created outside of NetSuite for Private Label. */
export type CustomerRegistrationPrivateLabelPractitionerNewInput = {
  businessAddress: CustomerRegistrationAddressNewInput
  companyName: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  licenseNumber: Scalars['String']
  /** NetSuite internal id of specialty list option */
  specialty: Scalars['String']
  type: CustomerRegistrationTypePrivateLabelPractitioner
}

export type CustomerRegistrationPrivateLabelUnlicensed = {
  __typename?: 'CustomerRegistrationPrivateLabelUnlicensed'
  /** Auth0 properties for new customer registration */
  auth0: CustomerRegistrationAuth0
  companyName: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
}

/** Customer unlicensed created outside of NetSuite for Private Label. */
export type CustomerRegistrationPrivateLabelUnlicensedNewInput = {
  businessAddress: CustomerRegistrationAddressNewInput
  companyName: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  type: CustomerRegistrationTypePrivateLabelUnlicensed
}

export type CustomerRegistrationStudent = {
  __typename?: 'CustomerRegistrationStudent'
  /** Auth0 properties for new customer registration */
  auth0: CustomerRegistrationAuth0
  email: Scalars['String']
  firstName: Scalars['String']
  graduationDate: Scalars['String']
  lastName: Scalars['String']
  universityName: Scalars['String']
}

/** Customer student created outside of NetSuite. */
export type CustomerRegistrationStudentNewInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  graduationDate: Scalars['String']
  lastName: Scalars['String']
  mailingAddress: CustomerRegistrationAddressNewInput
  /** NetSuite internal id of specialty list option */
  specialty: Scalars['String']
  type: CustomerRegistrationTypeStudent
  universityName: Scalars['String']
}

export const CustomerRegistrationTypePatient = {
  Patient: 'PATIENT',
} as const

export type CustomerRegistrationTypePatient = typeof CustomerRegistrationTypePatient[keyof typeof CustomerRegistrationTypePatient]
export const CustomerRegistrationTypePractitioner = {
  Practitioner: 'PRACTITIONER',
} as const

export type CustomerRegistrationTypePractitioner = typeof CustomerRegistrationTypePractitioner[keyof typeof CustomerRegistrationTypePractitioner]
export const CustomerRegistrationTypePrivateLabelPractitioner = {
  PrivateLabelPractitioner: 'PRIVATE_LABEL_PRACTITIONER',
} as const

export type CustomerRegistrationTypePrivateLabelPractitioner = typeof CustomerRegistrationTypePrivateLabelPractitioner[keyof typeof CustomerRegistrationTypePrivateLabelPractitioner]
export const CustomerRegistrationTypePrivateLabelUnlicensed = {
  PrivateLabelUnlicensed: 'PRIVATE_LABEL_UNLICENSED',
} as const

export type CustomerRegistrationTypePrivateLabelUnlicensed = typeof CustomerRegistrationTypePrivateLabelUnlicensed[keyof typeof CustomerRegistrationTypePrivateLabelUnlicensed]
export const CustomerRegistrationTypeStudent = {
  Student: 'STUDENT',
} as const

export type CustomerRegistrationTypeStudent = typeof CustomerRegistrationTypeStudent[keyof typeof CustomerRegistrationTypeStudent]
/** Composed object including rewards info and metadata */
export type CustomerRewardsInfo = {
  __typename?: 'CustomerRewardsInfo'
  customerId: Scalars['String']
  /** Whether customer is eligible for rebate program */
  eligibleForRebate: Scalars['Boolean']
  /** Whether customer is currently enrolled in rebate program */
  enrolledInRebate: Scalars['Boolean']
  meta: ResourceMetadata
}

/** Composed object including customer rewards order data and metadata */
export type CustomerRewardsOrder = {
  __typename?: 'CustomerRewardsOrder'
  /** Identifier of Auto-Ship associated with order */
  autoShipId?: Maybe<Scalars['String']>
  coloradoRetailDeliveryFee?: Maybe<Scalars['Float']>
  currency: Currency
  date: Scalars['String']
  discountTotal: Scalars['Float']
  fulfillments: Array<Maybe<CustomerOrderFulfillment>>
  /** Should order be held until nearest Monday to ship? */
  holdUntilMonday: Scalars['Boolean']
  items: Array<Maybe<CustomerOrderLineItem>>
  meta: ResourceMetadata
  /** Whether the customer associated with the order had not been consulted by their practitioner when placing order */
  notConsulted?: Maybe<Scalars['Boolean']>
  /** Customer email sourced from the order customer */
  orderCustomerEmail?: Maybe<Scalars['String']>
  /** Customer identifier associated with the order */
  orderCustomerId: Scalars['String']
  /** Customer name associated with the order */
  orderCustomerName?: Maybe<Scalars['String']>
  /** Order identifer */
  orderId: Scalars['String']
  /** Order number (for use in communication, print, display) */
  orderNumber: Scalars['String']
  promoCode?: Maybe<Scalars['String']>
  refundTotal: Scalars['Float']
  /** Customer identifier to which rewards for the order are attributed */
  rewardsCustomerId: Scalars['String']
  /** Rewards source/classification (IN_PRACTICE, DFH_WEBSTORE, etc.) */
  rewardsSource: Scalars['String']
  /** Shipping method selected for order */
  shipMethod: Scalars['String']
  /** Total of shipping costs (including handling if any) */
  shippingTotal: Scalars['Float']
  status: Scalars['String']
  /** Originating storefront identifier */
  storefront: Scalars['String']
  /** Storefront specific order number */
  storefrontOrderNumber: Scalars['String']
  /** Total excluding any discounts, shipping, tax */
  subtotal: Scalars['Float']
  /** Informative tags about the order */
  tags?: Maybe<Array<Maybe<CustomerOrderTag>>>
  taxTotal: Scalars['Float']
  /** Order total including discounts, shipping, tax */
  total: Scalars['Float']
}

export type CustomerRewardsOrders = {
  __typename?: 'CustomerRewardsOrders'
  /** Metadata for collections */
  meta: CollectionMetadata
  /** Collection of customer rewards order resources */
  orders: Array<Maybe<CustomerRewardsOrder>>
}

export type CustomerRewardsPeriodSalesTotal = {
  __typename?: 'CustomerRewardsPeriodSalesTotal'
  pracPrice: Scalars['Float']
  rebates: Scalars['Float']
  salesTotal: Scalars['Float']
  title: Scalars['String']
}

/** Composed object including customer settings and metadata */
export type CustomerSettings = {
  __typename?: 'CustomerSettings'
  /** Customer email */
  customerEmail: Scalars['String']
  /** Customer identifier */
  customerId: Scalars['String']
  meta: ResourceMetadata
  /** Patient customer settings */
  patient?: Maybe<CustomerSettingsPatient>
  /** Personal customer settings */
  personal?: Maybe<CustomerSettingsPersonal>
  /** Practitioner settings */
  practitioner?: Maybe<CustomerSettingsPractitioner>
}

/** Patient customer settings */
export type CustomerSettingsPatient = {
  __typename?: 'CustomerSettingsPatient'
  /** Practitioner code */
  practitionerCode: Scalars['String']
}

/** Personal customer settings */
export type CustomerSettingsPersonal = {
  __typename?: 'CustomerSettingsPersonal'
  /** Phone number */
  phone?: Maybe<Scalars['String']>
  /** Zip/Postal code */
  zip?: Maybe<Scalars['String']>
}

/** Practitioner settings */
export type CustomerSettingsPractitioner = {
  __typename?: 'CustomerSettingsPractitioner'
  /** Indicates if ACH Form was received */
  achFormReceived: Scalars['Boolean']
  /** Indicates if Commissions Opt-out Form was received */
  commissionsOptOutFormReceived?: Maybe<Scalars['Boolean']>
  /** Company/practice name */
  companyName: Scalars['String']
  /** Practitioner desired and enabled dispensing options */
  dispensingOptions: DispensingOptions
  /** Practitioner external ID for DSL */
  dslPractitionerId?: Maybe<Scalars['String']>
  /** Indicates if the practitioner is enrolled in Rebate Program */
  enrolledInRebate: Scalars['Boolean']
  /** How did you hear about us? */
  howDidYouHearAboutUs?: Maybe<Scalars['String']>
  /** State where licensed (ISO 3166-2 code) */
  licensedInState?: Maybe<Scalars['String']>
  /** Indicates if the practitioner has opted out of having access to dispensary data and commissions */
  optedOutOfDispensary?: Maybe<Scalars['Boolean']>
  /** Indicates if the practitioner has opted out of receiving emails related to orders in their dispensary */
  optedOutOfDispensaryOrderEmails?: Maybe<Scalars['Boolean']>
  /** Indicates if the practitioner's dispensary should be excluded from suggestions generated for inbound patients */
  optedOutOfInboundPatient?: Maybe<Scalars['Boolean']>
  /** Practitioner partner code */
  partnerCode: Scalars['String']
  /** Practice specialties */
  specialties?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Indicates if Tax ID form was received (i.e. `true` if W-9 form received) */
  taxIdFormReceived: Scalars['Boolean']
}

/** Practitioner settings to update */
export type CustomerSettingsPractitionerUpdateInput = {
  /** Desired dispensing options */
  dispensingOptions?: Maybe<DispensingOptions2Input>
  /** Set/unset enrollment to Rebate Program */
  enrolledInRebate?: Maybe<Scalars['Boolean']>
  /** Set/unset opt out of Dispensary Order Emails */
  optedOutOfDispensaryOrderEmails?: Maybe<Scalars['Boolean']>
  /** Set/unset opt out of Inbound Patient */
  optedOutOfInboundPatient?: Maybe<Scalars['Boolean']>
}

export type CustomerSuggestedDispensaries = {
  __typename?: 'CustomerSuggestedDispensaries'
  /** Metadata for collections */
  meta: CollectionMetadata
  /** Collection of suggested dispensaries */
  suggestedDispensaries: Array<Maybe<CustomerSuggestedDispensary>>
}

export type CustomerSuggestedDispensary = {
  __typename?: 'CustomerSuggestedDispensary'
  /** Dispensary name */
  name: Scalars['String']
  /** URL slug for the dispensary */
  slug: Scalars['String']
}

export type CustomerSuggestedDispensarySelectionUpdateInput = {
  /** URL slug for the selected dispensary */
  slug: Scalars['String']
}

/** DFH Webstore dispensing option settings */
export type DfhWebstore = {
  __typename?: 'DfhWebstore'
  /** DFH Webstore option is marked as desired */
  isDesired: Scalars['Boolean']
  /** DFH Webstore option is marked as enabled */
  isEnabled: Scalars['Boolean']
}

/** DFH Webstore dispensing option */
export type DfhWebstore2Input = {
  /** Set/unset dispensing option as desired */
  isDesired: Scalars['Boolean']
}

export type Dispensaries = {
  __typename?: 'Dispensaries'
  /** Collection of dispensary resources */
  dispensaries: Array<Maybe<Dispensary>>
  /** Metadata for collections */
  meta: CollectionMetadata
}

/** Composed object including dispensary data and metadata */
export type Dispensary = {
  __typename?: 'Dispensary'
  /** Expiration date of the dispensary if superseded by another */
  expirationDate?: Maybe<Scalars['String']>
  meta: ResourceMetadata
  /** Dispensary name */
  name: Scalars['String']
  /** Partner code for the dispensary's practitioner */
  practitionerCode: Scalars['String']
  /** URL slug for the dispensary */
  slug: Scalars['String']
  /** Dispensary name for the main dispensary that supersedes the current one */
  supersededByName?: Maybe<Scalars['String']>
  /** URL slug for the main dispensary that supersedes the current one */
  supersededBySlug?: Maybe<Scalars['String']>
}

export type DispensaryPromo = {
  __typename?: 'DispensaryPromo'
  /** SHA-256 hash (hex encoding) of promo code alias created by practitioner */
  dispensaryCodeHash: Scalars['String']
  /** Magento code for which the dispensary code is an alias. Encrypted by AES-256-CBC (PKCS#7 padding) with key derived from dispensary promo code (PBKDF2 - 10 iterations, SHA-256) and Base64 encoded with OpenSSL formatting (salt in bytes 8-15, cipher text following). */
  rootCodeSecret: Scalars['String']
}

/** Composed object including dispensary promo data and metadata */
export type DispensaryPromos = {
  __typename?: 'DispensaryPromos'
  meta: ResourceMetadata
  /** Collection of promos for dispensary identified by slug (if any) */
  promos: Array<Maybe<DispensaryPromo>>
  /** Collection of root promos shared by all dispensaries */
  rootPromos: Array<Maybe<DispensaryRootPromo>>
}

export type DispensaryRootPromo = {
  __typename?: 'DispensaryRootPromo'
  /** Display name of root promo */
  name: Scalars['String']
  /** SHA-256 hash (hex encoding) of Magento promo code */
  rootCodeHash: Scalars['String']
}

/** Practitioner desired and enabled dispensing options */
export type DispensingOptions = {
  __typename?: 'DispensingOptions'
  /** DFH Webstore dispensing option settings */
  dfhWebstore: DfhWebstore
  /** E-Store dispensing option settings */
  eStore: EStore
  /** In-Practice dispensing option settings */
  inPractice: InPractice
}

/** Desired dispensing options */
export type DispensingOptions2Input = {
  /** DFH Webstore dispensing option */
  dfhWebstore?: Maybe<DfhWebstore2Input>
  /** E-Store dispensing option */
  eStore?: Maybe<EStore2Input>
}

/** E-Store dispensing option settings */
export type EStore = {
  __typename?: 'EStore'
  /** E-Store option is marked as desired */
  isDesired: Scalars['Boolean']
  /** E-Store option is marked as enabled */
  isEnabled: Scalars['Boolean']
  /** Subdomain (of ehealthpro.com) */
  subdomain?: Maybe<Scalars['String']>
}

/** E-Store dispensing option */
export type EStore2Input = {
  /** Set/unset dispensing option as desired */
  isDesired: Scalars['Boolean']
  /** Desired subdomain (of ehealthpro.com) */
  subdomain?: Maybe<Scalars['String']>
}

export type EventMetadataInput = {
  /** System from which event originated */
  origin?: Maybe<Scalars['String']>
  /** Date/time event was generated */
  timestamp?: Maybe<Scalars['String']>
}

export type EventPosted = {
  __typename?: 'EventPosted'
  messages?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** Evidence of acceptance event's authenticity */
export type EvidenceInput = {
  /** Acceptance time (ISO 8601) */
  acceptedAt: Scalars['String']
  /** Accepting user's IP address */
  clientIP?: Maybe<Scalars['String']>
  /** Accepting user's company name */
  company?: Maybe<Scalars['String']>
  /** Accepting user's email address */
  email?: Maybe<Scalars['String']>
  /** Accepting user's login timestamp */
  loggedInAt?: Maybe<Scalars['String']>
  /** Accepting user's name */
  name?: Maybe<Scalars['String']>
  /** Customer's user agent of client environment */
  userAgent?: Maybe<Scalars['String']>
}

/** In-Practice dispensing option settings */
export type InPractice = {
  __typename?: 'InPractice'
  /** In-Practice option is marked as desired */
  isDesired: Scalars['Boolean']
  /** In-Practice option is marked as enabled */
  isEnabled: Scalars['Boolean']
}

export type Mutation = {
  __typename?: 'Mutation'
  /**
   * Check customer status for EHealthPro user
   *
   * Equivalent to POST /v1/customer/ehp/status
   */
  checkCustomerRecordPresence?: Maybe<CustomerEhpStatus>
  /**
   * Remove dispensary promo
   *
   * Equivalent to DELETE /v1/customer/{customerId}/dispensaries/{dispensarySlug}/promos/{promoId}
   */
  deleteCustomerDispensaryPromo?: Maybe<CustomerDispensaryPromoDeleted>
  /**
   * Migrate existing EHP user with status NS_CUSTOMER_FOUND
   *
   * Equivalent to POST /v1/customer/ehp/migration
   */
  postCustomerAuth0UserCreation?: Maybe<CustomerEhpMigration>
  /**
   * Create promo for dispensary
   *
   * Equivalent to POST /v1/customer/{customerId}/dispensaries/{dispensarySlug}/promos
   */
  postCustomerDispensaryPromo?: Maybe<CustomerDispensaryPromo>
  /**
   * Post Customer:Registration Patient (deprecated)
   *
   * Equivalent to POST /v1/customer/registration/patient
   */
  postCustomerPatientRegistration?: Maybe<CustomerPatientData>
  /**
   * Post Customer:Registration Patient V2
   *
   * Equivalent to POST /v2/customer/registration/patient
   */
  postCustomerPatientRegistrationV2?: Maybe<CustomerRegistrationPatientV2>
  /**
   * Post Customer:Registration Patient V2 (continue)
   *
   * Equivalent to POST /v2/customer/registration/patient/continue
   */
  postCustomerPatientRegistrationV2Continue?: Maybe<CustomerRegistrationPatientV2>
  /**
   * Post Customer:Registration Practitioner (deprecated)
   *
   * Equivalent to POST /v1/customer/registration/practitioner
   */
  postCustomerPractitionerRegistration?: Maybe<CustomerPractitionerData>
  /**
   * Post Customer:Registration Practitioner V2
   *
   * Equivalent to POST /v2/customer/registration/practitioner
   */
  postCustomerPractitionerRegistrationV2?: Maybe<CustomerRegistrationPractitionerV2>
  /**
   * Post Customer:Registration Practitioner V2 (continue)
   *
   * Equivalent to POST /v2/customer/registration/practitioner/continue
   */
  postCustomerPractitionerRegistrationV2Continue?: Maybe<CustomerRegistrationPractitionerV2>
  /**
   * Post Customer:Registration Private Label Practitioner
   *
   * Equivalent to POST /v1/customer/registration/private-label-practitioner
   */
  postCustomerPrivateLabelPractitionerRegistration?: Maybe<
    CustomerRegistrationPrivateLabelPractitioner
  >
  /**
   * Post Customer:Registration Private Label Practitioner (continue)
   *
   * Equivalent to POST /v1/customer/registration/private-label-practitioner/continue
   */
  postCustomerPrivateLabelPractitionerRegistrationContinue?: Maybe<
    CustomerRegistrationPrivateLabelPractitioner
  >
  /**
   * Post Customer:Registration Private Label Unlicensed
   *
   * Equivalent to POST /v1/customer/registration/private-label-unlicensed
   */
  postCustomerPrivateLabelUnlicensedRegistration?: Maybe<CustomerRegistrationPrivateLabelUnlicensed>
  /**
   * Post Customer:Registration Private Label Unlicensed (continue)
   *
   * Equivalent to POST /v1/customer/registration/private-label-unlicensed/continue
   */
  postCustomerPrivateLabelUnlicensedRegistrationContinue?: Maybe<
    CustomerRegistrationPrivateLabelUnlicensed
  >
  /**
   * Create new request for cash redemption of rebates
   *
   * Equivalent to POST /v1/customer/{customerId}/rewards/rebate/redemption/cash
   */
  postCustomerRebateRedemptionCashRequest?: Maybe<CustomerRebateRedemptionCashRequest>
  /**
   * Post Customer:Registration Student
   *
   * Equivalent to POST /v1/customer/registration/student
   */
  postCustomerStudentRegistration?: Maybe<CustomerRegistrationStudent>
  /**
   * Post Customer:Registration Student (continue)
   *
   * Equivalent to POST /v1/customer/registration/student/continue
   */
  postCustomerStudentRegistrationContinue?: Maybe<CustomerRegistrationStudent>
  /**
   * Publish Customer:AgreementAccepted event
   *
   * Equivalent to PUT /v1/customer/agreement-accepted
   */
  publishCustomerAgreementAcceptedEvent?: Maybe<EventPosted>
  /**
   * Update Auto-Ship
   *
   * Equivalent to PUT /v1/customer/{customerId}/auto-ship/{autoShipId}
   */
  putCustomerAutoShip?: Maybe<CustomerAutoShip>
  /**
   * Update promo for dispensary
   *
   * Equivalent to PUT /v1/customer/{customerId}/dispensaries/{dispensarySlug}/promos/{promoId}
   */
  putCustomerDispensaryPromo?: Maybe<CustomerDispensaryPromo>
  /**
   * Put settings (e.g. dispensing) for practitioner
   *
   * Equivalent to PUT /v1/customer/{customerId}/settings/practitioner
   */
  putCustomerSettingsPractitioner?: Maybe<CustomerSettings>
  /**
   * Record a (patient) customer's selection from a list of suggested dispensaries
   *
   *
   * Equivalent to PUT /v1/customer/{customerId}/suggested-dispensaries
   */
  putCustomerSuggestedDispensarySelection?: Maybe<CustomerSuggestedDispensary>
}

export type MutationCheckCustomerRecordPresenceArgs = {
  customerEHPStatusCheckInput: CustomerEhpStatusCheckInput
}

export type MutationDeleteCustomerDispensaryPromoArgs = {
  customerId: Scalars['String']
  dispensarySlug: Scalars['String']
  promoId: Scalars['String']
}

export type MutationPostCustomerAuth0UserCreationArgs = {
  customerEHPMigrationNewInput: CustomerEhpMigrationNewInput
}

export type MutationPostCustomerDispensaryPromoArgs = {
  customerDispensaryPromoNewInput: CustomerDispensaryPromoNewInput
  customerId: Scalars['String']
  dispensarySlug: Scalars['String']
}

export type MutationPostCustomerPatientRegistrationArgs = {
  customerRegistrationPatientInput: CustomerRegistrationPatientInput
}

export type MutationPostCustomerPatientRegistrationV2Args = {
  customerRegistrationPatientNewInput: CustomerRegistrationPatientNewInput
  deferAfter?: Maybe<Scalars['String']>
}

export type MutationPostCustomerPatientRegistrationV2ContinueArgs = {
  continueDeferredInput: ContinueDeferredInput
  deferAfter?: Maybe<Scalars['String']>
}

export type MutationPostCustomerPractitionerRegistrationArgs = {
  customerRegistrationPractitionerInput: CustomerRegistrationPractitionerInput
}

export type MutationPostCustomerPractitionerRegistrationV2Args = {
  customerRegistrationPractitionerNewInput: CustomerRegistrationPractitionerNewInput
  deferAfter?: Maybe<Scalars['String']>
}

export type MutationPostCustomerPractitionerRegistrationV2ContinueArgs = {
  continueDeferredInput: ContinueDeferredInput
  deferAfter?: Maybe<Scalars['String']>
}

export type MutationPostCustomerPrivateLabelPractitionerRegistrationArgs = {
  customerRegistrationPrivateLabelPractitionerNewInput: CustomerRegistrationPrivateLabelPractitionerNewInput
  deferAfter?: Maybe<Scalars['String']>
}

export type MutationPostCustomerPrivateLabelPractitionerRegistrationContinueArgs = {
  continueDeferredInput: ContinueDeferredInput
  deferAfter?: Maybe<Scalars['String']>
}

export type MutationPostCustomerPrivateLabelUnlicensedRegistrationArgs = {
  customerRegistrationPrivateLabelUnlicensedNewInput: CustomerRegistrationPrivateLabelUnlicensedNewInput
  deferAfter?: Maybe<Scalars['String']>
}

export type MutationPostCustomerPrivateLabelUnlicensedRegistrationContinueArgs = {
  continueDeferredInput: ContinueDeferredInput
  deferAfter?: Maybe<Scalars['String']>
}

export type MutationPostCustomerRebateRedemptionCashRequestArgs = {
  customerId: Scalars['String']
  customerRebateRedemptionCashRequestNewInput: CustomerRebateRedemptionCashRequestNewInput
}

export type MutationPostCustomerStudentRegistrationArgs = {
  customerRegistrationStudentNewInput: CustomerRegistrationStudentNewInput
  deferAfter?: Maybe<Scalars['String']>
}

export type MutationPostCustomerStudentRegistrationContinueArgs = {
  continueDeferredInput: ContinueDeferredInput
  deferAfter?: Maybe<Scalars['String']>
}

export type MutationPublishCustomerAgreementAcceptedEventArgs = {
  customerAgreementAcceptedEventInput: CustomerAgreementAcceptedEventInput
}

export type MutationPutCustomerAutoShipArgs = {
  autoShipId: Scalars['String']
  customerAutoShipUpdateInput: CustomerAutoShipUpdateInput
  customerId: Scalars['String']
}

export type MutationPutCustomerDispensaryPromoArgs = {
  customerDispensaryPromoUpdateInput: CustomerDispensaryPromoUpdateInput
  customerId: Scalars['String']
  dispensarySlug: Scalars['String']
  promoId: Scalars['String']
}

export type MutationPutCustomerSettingsPractitionerArgs = {
  customerId: Scalars['String']
  customerSettingsPractitionerUpdateInput: CustomerSettingsPractitionerUpdateInput
}

export type MutationPutCustomerSuggestedDispensarySelectionArgs = {
  customerId: Scalars['String']
  customerSuggestedDispensarySelectionUpdateInput: CustomerSuggestedDispensarySelectionUpdateInput
}

export type PageInfo = {
  __typename?: 'PageInfo'
  /** Current page number of collection */
  currentPage: Scalars['Int']
  /** Page size of collection */
  pageSize: Scalars['Int']
  /** Total pages available */
  totalPages: Scalars['Int']
}

export type Query = {
  __typename?: 'Query'
  /**
   * Get account balance for customer
   *
   * Equivalent to GET /v1/customer/{customerId}/account-balance
   */
  getCustomerAccountBalance?: Maybe<CustomerAccountBalance>
  /**
   * Get account history for customer
   *
   * Equivalent to GET /v1/customer/{customerId}/account-history
   */
  getCustomerAccountHistory?: Maybe<CustomerAccountHistory>
  /**
   * Get details of single Auto-Ship for customer
   *
   * Equivalent to GET /v1/customer/{customerId}/auto-ship/{autoShipId}
   */
  getCustomerAutoShip?: Maybe<CustomerAutoShip>
  /**
   * Get page of Auto-Ships for customer
   *
   * Equivalent to GET /v1/customer/{customerId}/auto-ships
   */
  getCustomerAutoShips?: Maybe<CustomerAutoShips>
  /**
   * Get main dispensary for practitioner
   *
   * Equivalent to GET /v1/customer/{customerId}/dispensary
   */
  getCustomerDispensary?: Maybe<CustomerDispensary>
  /**
   * Get all promos for dispensary by URL slug
   *
   * Equivalent to GET /v1/customer/{customerId}/dispensaries/{dispensarySlug}/promos
   */
  getCustomerDispensaryPromos?: Maybe<CustomerDispensaryPromos>
  /**
   * Get details of single invoice for customer
   *
   * Equivalent to GET /v1/customer/{customerId}/invoice/{invoiceId}
   */
  getCustomerInvoice?: Maybe<CustomerInvoice>
  /**
   * Get page of invoices for customer
   *
   * Equivalent to GET /v1/customer/{customerId}/invoices
   */
  getCustomerInvoices?: Maybe<CustomerInvoices>
  /**
   * Get details of single order for customer
   *
   * Equivalent to GET /v1/customer/{customerId}/order/{orderId}
   */
  getCustomerOrder?: Maybe<CustomerOrder>
  /**
   * Get page of orders for customer
   *
   * Equivalent to GET /v1/customer/{customerId}/orders
   */
  getCustomerOrders?: Maybe<CustomerOrders>
  /**
   * Get rebate balance data for customer
   *
   * Equivalent to GET /v1/customer/{customerId}/rewards/rebate/balance
   */
  getCustomerRebateBalance?: Maybe<CustomerRebateBalance>
  /**
   * Get rebate estimate data for customer
   *
   * Equivalent to GET /v1/customer/{customerId}/rewards/rebate/estimate
   */
  getCustomerRebateEstimate?: Maybe<CustomerRebateEstimate>
  /**
   * List requests for cash redemption of rebates
   *
   * Equivalent to GET /v1/customer/{customerId}/rewards/rebate/redemption/cash
   */
  getCustomerRebateRedemptionCashRequests?: Maybe<Array<Maybe<CustomerRebateRedemptionCashRequest>>>
  /**
   * Get rewards info for customer
   *
   * Equivalent to GET /v1/customer/{customerId}/rewards/info
   */
  getCustomerRewards?: Maybe<CustomerRewardsInfo>
  /**
   * Get details of single order identified by orderId and associated with a practitioner's rewards identified by the customerId
   *
   * Equivalent to GET /v1/customer/{customerId}/rewards/order/{orderId}
   */
  getCustomerRewardsOrder?: Maybe<CustomerRewardsOrder>
  /**
   * Get page of orders associated with rewards for a practitioner identified by customerId
   *
   * Equivalent to GET /v1/customer/{customerId}/rewards/orders
   */
  getCustomerRewardsOrders?: Maybe<CustomerRewardsOrders>
  /**
   * Get settings (e.g. profile info) for customer
   *
   * Equivalent to GET /v1/customer/{customerId}/settings
   */
  getCustomerSettings?: Maybe<CustomerSettings>
  /**
   * Dispensaries may be suggested for patient customer depending on registration data (e.g. zip code)
   *
   *
   * Equivalent to GET /v1/customer/{customerId}/suggested-dispensaries
   */
  getCustomerSuggestedDispensaries?: Maybe<CustomerSuggestedDispensaries>
  /**
   * Get page of dispensaries or all dispensaries if no pagination requested
   *
   * Equivalent to GET /v1/dispensaries
   */
  getDispensaries?: Maybe<Dispensaries>
  /**
   * Get single dispensary by URL slug
   *
   * Equivalent to GET /v1/dispensaries/{dispensarySlug}
   */
  getDispensary?: Maybe<Dispensary>
  /**
   * Get single dispensary by practitioner code
   *
   * Equivalent to GET /v1/dispensaries/code/{practitionerCode}
   */
  getDispensaryByCode?: Maybe<Dispensary>
  /**
   * Get encoded root promos and, optionally, promos for dispensary by URL slug
   *
   * Equivalent to GET /v1/dispensaries/promos
   */
  getEncodedDispensaryPromos?: Maybe<DispensaryPromos>
}

export type QueryGetCustomerAccountBalanceArgs = {
  customerId: Scalars['String']
}

export type QueryGetCustomerAccountHistoryArgs = {
  customerId: Scalars['String']
}

export type QueryGetCustomerAutoShipArgs = {
  autoShipId: Scalars['String']
  customerId: Scalars['String']
}

export type QueryGetCustomerAutoShipsArgs = {
  customerId: Scalars['String']
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
}

export type QueryGetCustomerDispensaryArgs = {
  customerId: Scalars['String']
}

export type QueryGetCustomerDispensaryPromosArgs = {
  customerId: Scalars['String']
  dispensarySlug: Scalars['String']
}

export type QueryGetCustomerInvoiceArgs = {
  customerId: Scalars['String']
  invoiceId: Scalars['String']
  otherCustomerIds?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryGetCustomerInvoicesArgs = {
  customerId: Scalars['String']
  otherCustomerIds?: Maybe<Array<Maybe<Scalars['String']>>>
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
}

export type QueryGetCustomerOrderArgs = {
  customerId: Scalars['String']
  orderId: Scalars['String']
  otherCustomerIds?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryGetCustomerOrdersArgs = {
  customerId: Scalars['String']
  otherCustomerIds?: Maybe<Array<Maybe<Scalars['String']>>>
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
}

export type QueryGetCustomerRebateBalanceArgs = {
  customerId: Scalars['String']
}

export type QueryGetCustomerRebateEstimateArgs = {
  customerId: Scalars['String']
}

export type QueryGetCustomerRebateRedemptionCashRequestsArgs = {
  customerId: Scalars['String']
}

export type QueryGetCustomerRewardsArgs = {
  customerId: Scalars['String']
}

export type QueryGetCustomerRewardsOrderArgs = {
  customerId: Scalars['String']
  orderId: Scalars['String']
}

export type QueryGetCustomerRewardsOrdersArgs = {
  customerId: Scalars['String']
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  rewardsSource?: Maybe<RewardsSource>
}

export type QueryGetCustomerSettingsArgs = {
  customerId: Scalars['String']
}

export type QueryGetCustomerSuggestedDispensariesArgs = {
  customerId: Scalars['String']
}

export type QueryGetDispensariesArgs = {
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
}

export type QueryGetDispensaryArgs = {
  dispensarySlug: Scalars['String']
}

export type QueryGetDispensaryByCodeArgs = {
  practitionerCode: Scalars['String']
}

export type QueryGetEncodedDispensaryPromosArgs = {
  dispensarySlug?: Maybe<Scalars['String']>
}

export type ResourceMetadata = {
  __typename?: 'ResourceMetadata'
  /** Date/time of resource snapshot */
  timestamp: Scalars['String']
}

export const RewardsSource = {
  DfhWebstore: 'DFH_WEBSTORE',
  Dispensary: 'DISPENSARY',
  EStore: 'E_STORE',
  InPractice: 'IN_PRACTICE',
  WellWorld: 'WELL_WORLD',
} as const

export type RewardsSource = typeof RewardsSource[keyof typeof RewardsSource]
export type SalesTotals = {
  __typename?: 'SalesTotals'
  retail: Array<Maybe<CustomerRewardsPeriodSalesTotal>>
  wholeSale: Array<Maybe<CustomerRewardsPeriodSalesTotal>>
}

export type UserMetadata = {
  __typename?: 'UserMetadata'
  companyName?: Maybe<Scalars['String']>
}

export type GetCustomerAccountBalanceQueryVariables = Exact<{
  customerId: Scalars['String']
}>

export type GetCustomerAccountBalanceQuery = { __typename?: 'Query' } & {
  getCustomerAccountBalance?: Maybe<
    { __typename?: 'CustomerAccountBalance' } & Pick<
      CustomerAccountBalance,
      | 'balanceOwed'
      | 'creditLimit'
      | 'currency'
      | 'customerId'
      | 'daysOverdue'
      | 'onCreditHold'
      | 'terms'
    > & { meta: { __typename?: 'ResourceMetadata' } & Pick<ResourceMetadata, 'timestamp'> }
  >
}

export type GetCustomerAccountHistoryQueryVariables = Exact<{
  customerId: Scalars['String']
}>

export type GetCustomerAccountHistoryQuery = { __typename?: 'Query' } & {
  getCustomerAccountHistory?: Maybe<
    { __typename?: 'CustomerAccountHistory' } & Pick<
      CustomerAccountHistory,
      'balanceOwed' | 'creditLimit' | 'currency' | 'customerId' | 'daysOverdue' | 'terms'
    > & {
        transactions: Array<
          Maybe<
            { __typename?: 'CustomerAccountHistoryTransaction' } & Pick<
              CustomerAccountHistoryTransaction,
              'charge' | 'currency' | 'date' | 'id' | 'payment' | 'type' | 'transactionNumber'
            >
          >
        >
        meta: { __typename?: 'ResourceMetadata' } & Pick<ResourceMetadata, 'timestamp'>
      }
  >
}

export type PutCustomerAutoShipMutationVariables = Exact<{
  autoShipId: Scalars['String']
  customerAutoShipUpdateInput: CustomerAutoShipUpdateInput
  customerId: Scalars['String']
}>

export type PutCustomerAutoShipMutation = { __typename?: 'Mutation' } & {
  putCustomerAutoShip?: Maybe<
    { __typename?: 'CustomerAutoShip' } & Pick<
      CustomerAutoShip,
      | 'autoShipId'
      | 'autoShipNumber'
      | 'currency'
      | 'customerId'
      | 'frequency'
      | 'nextShipDate'
      | 'originalOrderNumber'
      | 'relatedOrderIds'
      | 'startDate'
      | 'status'
    > & {
        items: Array<
          Maybe<
            { __typename?: 'CustomerAutoShipLineItem' } & Pick<
              CustomerAutoShipLineItem,
              'quantity' | 'shipmentCount' | 'sku'
            >
          >
        >
        shippingAddress: { __typename?: 'CustomerAddress' } & Pick<
          CustomerAddress,
          'addressee' | 'city' | 'country' | 'countryCode' | 'state' | 'street' | 'zip'
        >
        meta: { __typename?: 'ResourceMetadata' } & Pick<ResourceMetadata, 'timestamp'>
      }
  >
}

export type GetCustomerAutoShipsQueryVariables = Exact<{
  customerId: Scalars['String']
}>

export type GetCustomerAutoShipsQuery = { __typename?: 'Query' } & {
  getCustomerAutoShips?: Maybe<
    { __typename?: 'CustomerAutoShips' } & {
      autoShips: Array<
        Maybe<
          { __typename?: 'CustomerAutoShip' } & Pick<
            CustomerAutoShip,
            | 'autoShipId'
            | 'autoShipNumber'
            | 'currency'
            | 'customerId'
            | 'frequency'
            | 'nextShipDate'
            | 'originalOrderNumber'
            | 'relatedOrderIds'
            | 'startDate'
            | 'status'
          > & {
              items: Array<
                Maybe<
                  { __typename?: 'CustomerAutoShipLineItem' } & Pick<
                    CustomerAutoShipLineItem,
                    'quantity' | 'shipmentCount' | 'sku'
                  >
                >
              >
              shippingAddress: { __typename?: 'CustomerAddress' } & Pick<
                CustomerAddress,
                'addressee' | 'city' | 'country' | 'countryCode' | 'state' | 'street' | 'zip'
              >
              meta: { __typename?: 'ResourceMetadata' } & Pick<ResourceMetadata, 'timestamp'>
            }
        >
      >
      meta: { __typename?: 'CollectionMetadata' } & Pick<
        CollectionMetadata,
        'timestamp' | 'totalCount'
      > & {
          pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'pageSize' | 'currentPage' | 'totalPages'
          >
        }
    }
  >
}

export type DeleteCustomerDispensaryPromoMutationVariables = Exact<{
  customerId: Scalars['String']
  dispensarySlug: Scalars['String']
  promoId: Scalars['String']
}>

export type DeleteCustomerDispensaryPromoMutation = { __typename?: 'Mutation' } & {
  deleteCustomerDispensaryPromo?: Maybe<
    { __typename?: 'CustomerDispensaryPromoDeleted' } & Pick<
      CustomerDispensaryPromoDeleted,
      'dispensaryCode' | 'messages'
    >
  >
}

export type PostCustomerDispensaryPromoMutationVariables = Exact<{
  customerId: Scalars['String']
  dispensarySlug: Scalars['String']
  customerDispensaryPromoNewInput: CustomerDispensaryPromoNewInput
}>

export type PostCustomerDispensaryPromoMutation = { __typename?: 'Mutation' } & {
  postCustomerDispensaryPromo?: Maybe<
    { __typename?: 'CustomerDispensaryPromo' } & Pick<
      CustomerDispensaryPromo,
      'dispensaryCode' | 'id' | 'inactive' | 'expirationDate' | 'rootPromoName' | 'rootPromoId'
    >
  >
}

export type PutCustomerDispensaryPromoMutationVariables = Exact<{
  customerId: Scalars['String']
  dispensarySlug: Scalars['String']
  promoId: Scalars['String']
  customerDispensaryPromoUpdateInput: CustomerDispensaryPromoUpdateInput
}>

export type PutCustomerDispensaryPromoMutation = { __typename?: 'Mutation' } & {
  putCustomerDispensaryPromo?: Maybe<
    { __typename?: 'CustomerDispensaryPromo' } & Pick<
      CustomerDispensaryPromo,
      'dispensaryCode' | 'id' | 'inactive' | 'expirationDate' | 'rootPromoName' | 'rootPromoId'
    >
  >
}

export type GetCustomerDispensaryPromosQueryVariables = Exact<{
  customerId: Scalars['String']
  dispensarySlug: Scalars['String']
}>

export type GetCustomerDispensaryPromosQuery = { __typename?: 'Query' } & {
  getCustomerDispensaryPromos?: Maybe<
    { __typename?: 'CustomerDispensaryPromos' } & {
      promos: Array<
        Maybe<
          { __typename?: 'CustomerDispensaryPromo' } & Pick<
            CustomerDispensaryPromo,
            | 'dispensaryCode'
            | 'id'
            | 'inactive'
            | 'expirationDate'
            | 'rootPromoName'
            | 'rootPromoId'
          >
        >
      >
      rootPromos: Array<
        Maybe<
          { __typename?: 'CustomerDispensaryRootPromo' } & Pick<
            CustomerDispensaryRootPromo,
            'id' | 'name'
          >
        >
      >
      meta: { __typename?: 'ResourceMetadata' } & Pick<ResourceMetadata, 'timestamp'>
    }
  >
}

export type CustomerInvoiceFragment = { __typename?: 'CustomerInvoice' } & Pick<
  CustomerInvoice,
  | 'amountRemaining'
  | 'coloradoRetailDeliveryFee'
  | 'currency'
  | 'customerId'
  | 'date'
  | 'discountTotal'
  | 'dueDate'
  | 'invoiceId'
  | 'invoiceNumber'
  | 'otherRefNum'
  | 'shippingTotal'
  | 'subtotal'
  | 'taxTotal'
  | 'total'
> & {
    billingAddress: { __typename?: 'CustomerAddress' } & Pick<
      CustomerAddress,
      | 'addressee'
      | 'attention'
      | 'city'
      | 'country'
      | 'countryCode'
      | 'phone'
      | 'state'
      | 'street'
      | 'zip'
    >
    createdFromOrder?: Maybe<
      { __typename?: 'CreatedFromOrder' } & Pick<CreatedFromOrder, 'orderId' | 'orderNumber'>
    >
    items: Array<
      Maybe<
        { __typename?: 'CustomerInvoiceLineItem' } & Pick<
          CustomerInvoiceLineItem,
          'quantity' | 'discountAmount' | 'item' | 'description' | 'rate' | 'amount'
        >
      >
    >
    shippingAddress: { __typename?: 'CustomerAddress' } & Pick<
      CustomerAddress,
      | 'addressee'
      | 'attention'
      | 'city'
      | 'country'
      | 'countryCode'
      | 'phone'
      | 'state'
      | 'street'
      | 'zip'
    >
  }

export type GetCustomerInvoiceQueryVariables = Exact<{
  customerId: Scalars['String']
  otherCustomerIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>
  invoiceId: Scalars['String']
}>

export type GetCustomerInvoiceQuery = { __typename?: 'Query' } & {
  getCustomerInvoice?: Maybe<{ __typename?: 'CustomerInvoice' } & CustomerInvoiceFragment>
}

export type GetCustomerInvoicesQueryVariables = Exact<{
  customerId: Scalars['String']
  otherCustomerIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
}>

export type GetCustomerInvoicesQuery = { __typename?: 'Query' } & {
  getCustomerInvoices?: Maybe<
    { __typename?: 'CustomerInvoices' } & {
      invoices: Array<Maybe<{ __typename?: 'CustomerInvoice' } & CustomerInvoiceFragment>>
      meta: { __typename?: 'CollectionMetadata' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'currentPage' | 'pageSize' | 'totalPages'
        >
      }
    }
  >
}

export type GetCustomerOrderQueryVariables = Exact<{
  customerId: Scalars['String']
  otherCustomerIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>
  orderId: Scalars['String']
}>

export type GetCustomerOrderQuery = { __typename?: 'Query' } & {
  getCustomerOrder?: Maybe<
    { __typename?: 'CustomerOrder' } & Pick<
      CustomerOrder,
      | 'coloradoRetailDeliveryFee'
      | 'date'
      | 'discountTotal'
      | 'holdUntilMonday'
      | 'orderId'
      | 'orderNumber'
      | 'promoCode'
      | 'shipMethod'
      | 'shippingTotal'
      | 'status'
      | 'subtotal'
      | 'taxTotal'
      | 'total'
    > & {
        invoices?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CustomerOrderInvoice' } & Pick<
                CustomerOrderInvoice,
                'id' | 'type' | 'transactionNumber'
              >
            >
          >
        >
        fulfillments: Array<
          Maybe<
            { __typename?: 'CustomerOrderFulfillment' } & Pick<
              CustomerOrderFulfillment,
              | 'date'
              | 'status'
              | 'warehouseLocation'
              | 'shipMethod'
              | 'trackingLink'
              | 'trackingNumber'
            > & {
                items: Array<
                  Maybe<
                    { __typename?: 'CustomerOrderFulfillmentLineItem' } & Pick<
                      CustomerOrderFulfillmentLineItem,
                      'quantity' | 'sku'
                    >
                  >
                >
              }
          >
        >
        items: Array<
          Maybe<
            { __typename?: 'CustomerOrderLineItem' } & Pick<
              CustomerOrderLineItem,
              | 'amount'
              | 'rate'
              | 'discountAmount'
              | 'quantity'
              | 'quantityBackordered'
              | 'quantityInvoiced'
              | 'quantityShipped'
              | 'sku'
            >
          >
        >
        payment: { __typename?: 'CustomerOrderPayment' } & Pick<
          CustomerOrderPayment,
          'terms' | 'giftCertificateApplied'
        > & {
            creditCard?: Maybe<
              { __typename?: 'CustomerOrderPaymentCreditCard' } & Pick<
                CustomerOrderPaymentCreditCard,
                'ccType' | 'ccExpDate' | 'ccLast4'
              >
            >
          }
        shippingAddress: { __typename?: 'CustomerAddress' } & Pick<
          CustomerAddress,
          | 'addressee'
          | 'attention'
          | 'city'
          | 'country'
          | 'countryCode'
          | 'phone'
          | 'state'
          | 'street'
          | 'zip'
        >
      }
  >
}

export type GetCustomerOrdersQueryVariables = Exact<{
  customerId: Scalars['String']
  otherCustomerIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
}>

export type GetCustomerOrdersQuery = { __typename?: 'Query' } & {
  getCustomerOrders?: Maybe<
    { __typename?: 'CustomerOrders' } & {
      orders: Array<
        Maybe<
          { __typename?: 'CustomerOrder' } & Pick<
            CustomerOrder,
            'currency' | 'date' | 'orderId' | 'orderNumber' | 'status' | 'total'
          > & {
              invoices?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'CustomerOrderInvoice' } & Pick<
                      CustomerOrderInvoice,
                      'id' | 'type' | 'transactionNumber'
                    >
                  >
                >
              >
              fulfillments: Array<
                Maybe<
                  { __typename?: 'CustomerOrderFulfillment' } & Pick<
                    CustomerOrderFulfillment,
                    'trackingNumber' | 'trackingLink'
                  >
                >
              >
              items: Array<
                Maybe<
                  { __typename?: 'CustomerOrderLineItem' } & Pick<
                    CustomerOrderLineItem,
                    | 'sku'
                    | 'quantity'
                    | 'quantityBackordered'
                    | 'quantityInvoiced'
                    | 'quantityShipped'
                  >
                >
              >
              shippingAddress: { __typename?: 'CustomerAddress' } & Pick<
                CustomerAddress,
                'addressee'
              >
              payment: { __typename?: 'CustomerOrderPayment' } & Pick<
                CustomerOrderPayment,
                'terms' | 'giftCertificateApplied'
              > & {
                  creditCard?: Maybe<
                    { __typename?: 'CustomerOrderPaymentCreditCard' } & Pick<
                      CustomerOrderPaymentCreditCard,
                      'ccType' | 'ccExpDate' | 'ccLast4'
                    >
                  >
                }
            }
        >
      >
      meta: { __typename?: 'CollectionMetadata' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'currentPage' | 'pageSize' | 'totalPages'
        >
      }
    }
  >
}

export type GetCustomerRebateBalanceQueryVariables = Exact<{
  customerId: Scalars['String']
}>

export type GetCustomerRebateBalanceQuery = { __typename?: 'Query' } & {
  getCustomerRebateBalance?: Maybe<
    { __typename?: 'CustomerRebateBalance' } & Pick<
      CustomerRebateBalance,
      'currentBalance' | 'pendingRebatesEarned' | 'storeCreditBalance'
    >
  >
}

export type GetCustomerRebateEstimateQueryVariables = Exact<{
  customerId: Scalars['String']
}>

export type GetCustomerRebateEstimateQuery = { __typename?: 'Query' } & {
  getCustomerRebateEstimate?: Maybe<
    { __typename?: 'CustomerRebateEstimate' } & {
      currentPeriod: { __typename?: 'CurrentPeriod' } & Pick<
        CurrentPeriod,
        'period' | 'periodRebatesEarned' | 'projectedEOMBalance'
      > & {
          salesTotals: { __typename?: 'SalesTotals' } & {
            retail: Array<
              Maybe<
                { __typename?: 'CustomerRewardsPeriodSalesTotal' } & Pick<
                  CustomerRewardsPeriodSalesTotal,
                  'pracPrice' | 'rebates' | 'salesTotal' | 'title'
                >
              >
            >
            wholeSale: Array<
              Maybe<
                { __typename?: 'CustomerRewardsPeriodSalesTotal' } & Pick<
                  CustomerRewardsPeriodSalesTotal,
                  'pracPrice' | 'rebates' | 'salesTotal' | 'title'
                >
              >
            >
          }
        }
      meta: { __typename?: 'ResourceMetadata' } & Pick<ResourceMetadata, 'timestamp'>
    }
  >
}

export type PostCustomerRebateRedemptionCashRequestMutationVariables = Exact<{
  customerId: Scalars['String']
  rebateValue: Scalars['Float']
}>

export type PostCustomerRebateRedemptionCashRequestMutation = { __typename?: 'Mutation' } & {
  postCustomerRebateRedemptionCashRequest?: Maybe<
    { __typename?: 'CustomerRebateRedemptionCashRequest' } & Pick<
      CustomerRebateRedemptionCashRequest,
      'customerId' | 'requestId' | 'status' | 'rebateValue' | 'cashValue'
    >
  >
}

export type GetCustomerRebateRedemptionCashRequestsQueryVariables = Exact<{
  customerId: Scalars['String']
}>

export type GetCustomerRebateRedemptionCashRequestsQuery = { __typename?: 'Query' } & {
  getCustomerRebateRedemptionCashRequests?: Maybe<
    Array<
      Maybe<
        { __typename?: 'CustomerRebateRedemptionCashRequest' } & Pick<
          CustomerRebateRedemptionCashRequest,
          'customerId' | 'requestId' | 'status' | 'rebateValue' | 'cashValue'
        >
      >
    >
  >
}

export type GetCustomerRewardsOrderQueryVariables = Exact<{
  customerId: Scalars['String']
  orderId: Scalars['String']
}>

export type GetCustomerRewardsOrderQuery = { __typename?: 'Query' } & {
  getCustomerRewardsOrder?: Maybe<
    { __typename?: 'CustomerRewardsOrder' } & Pick<
      CustomerRewardsOrder,
      | 'coloradoRetailDeliveryFee'
      | 'date'
      | 'discountTotal'
      | 'holdUntilMonday'
      | 'notConsulted'
      | 'orderCustomerEmail'
      | 'orderCustomerName'
      | 'orderId'
      | 'orderNumber'
      | 'promoCode'
      | 'shipMethod'
      | 'shippingTotal'
      | 'status'
      | 'subtotal'
      | 'tags'
      | 'taxTotal'
      | 'total'
    > & {
        fulfillments: Array<
          Maybe<
            { __typename?: 'CustomerOrderFulfillment' } & Pick<
              CustomerOrderFulfillment,
              | 'date'
              | 'status'
              | 'warehouseLocation'
              | 'shipMethod'
              | 'trackingLink'
              | 'trackingNumber'
            > & {
                items: Array<
                  Maybe<
                    { __typename?: 'CustomerOrderFulfillmentLineItem' } & Pick<
                      CustomerOrderFulfillmentLineItem,
                      'quantity' | 'sku'
                    >
                  >
                >
              }
          >
        >
        items: Array<
          Maybe<
            { __typename?: 'CustomerOrderLineItem' } & Pick<
              CustomerOrderLineItem,
              | 'amount'
              | 'rate'
              | 'discountAmount'
              | 'quantity'
              | 'quantityBackordered'
              | 'quantityInvoiced'
              | 'quantityShipped'
              | 'sku'
            >
          >
        >
      }
  >
}

export type GetCustomerRewardsOrdersQueryVariables = Exact<{
  customerId: Scalars['String']
  page?: Maybe<Scalars['Int']>
  rewardsSource: RewardsSource
}>

export type GetCustomerRewardsOrdersQuery = { __typename?: 'Query' } & {
  getCustomerRewardsOrders?: Maybe<
    { __typename?: 'CustomerRewardsOrders' } & {
      orders: Array<
        Maybe<
          { __typename?: 'CustomerRewardsOrder' } & Pick<
            CustomerRewardsOrder,
            | 'currency'
            | 'date'
            | 'notConsulted'
            | 'orderCustomerName'
            | 'orderId'
            | 'orderNumber'
            | 'status'
            | 'tags'
            | 'total'
          > & {
              fulfillments: Array<
                Maybe<
                  { __typename?: 'CustomerOrderFulfillment' } & Pick<
                    CustomerOrderFulfillment,
                    'trackingNumber' | 'trackingLink'
                  >
                >
              >
              items: Array<
                Maybe<{ __typename?: 'CustomerOrderLineItem' } & Pick<CustomerOrderLineItem, 'sku'>>
              >
            }
        >
      >
      meta: { __typename?: 'CollectionMetadata' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'currentPage' | 'pageSize' | 'totalPages'
        >
      }
    }
  >
}

export type CustomerSettingsPractitionerFragment = {
  __typename?: 'CustomerSettingsPractitioner'
} & Pick<
  CustomerSettingsPractitioner,
  | 'companyName'
  | 'commissionsOptOutFormReceived'
  | 'achFormReceived'
  | 'taxIdFormReceived'
  | 'partnerCode'
  | 'howDidYouHearAboutUs'
  | 'licensedInState'
  | 'specialties'
  | 'enrolledInRebate'
  | 'optedOutOfDispensary'
  | 'optedOutOfInboundPatient'
  | 'optedOutOfDispensaryOrderEmails'
> & {
    dispensingOptions: { __typename?: 'DispensingOptions' } & {
      inPractice: { __typename?: 'InPractice' } & Pick<InPractice, 'isDesired' | 'isEnabled'>
      eStore: { __typename?: 'EStore' } & Pick<EStore, 'isDesired' | 'isEnabled' | 'subdomain'>
      dfhWebstore: { __typename?: 'DfhWebstore' } & Pick<DfhWebstore, 'isDesired' | 'isEnabled'>
    }
  }

export type PutCustomerSettingsPractitionerMutationVariables = Exact<{
  customerId: Scalars['String']
  customerSettingsPractitionerUpdateInput: CustomerSettingsPractitionerUpdateInput
}>

export type PutCustomerSettingsPractitionerMutation = { __typename?: 'Mutation' } & {
  putCustomerSettingsPractitioner?: Maybe<
    { __typename?: 'CustomerSettings' } & Pick<CustomerSettings, 'customerId' | 'customerEmail'> & {
        personal?: Maybe<
          { __typename?: 'CustomerSettingsPersonal' } & Pick<
            CustomerSettingsPersonal,
            'zip' | 'phone'
          >
        >
        practitioner?: Maybe<
          { __typename?: 'CustomerSettingsPractitioner' } & CustomerSettingsPractitionerFragment
        >
        meta: { __typename?: 'ResourceMetadata' } & Pick<ResourceMetadata, 'timestamp'>
      }
  >
}

export type GetCustomerSettingsPractitionerQueryVariables = Exact<{
  customerId: Scalars['String']
}>

export type GetCustomerSettingsPractitionerQuery = { __typename?: 'Query' } & {
  getCustomerSettings?: Maybe<
    { __typename?: 'CustomerSettings' } & Pick<CustomerSettings, 'customerId' | 'customerEmail'> & {
        personal?: Maybe<
          { __typename?: 'CustomerSettingsPersonal' } & Pick<
            CustomerSettingsPersonal,
            'zip' | 'phone'
          >
        >
        practitioner?: Maybe<
          { __typename?: 'CustomerSettingsPractitioner' } & CustomerSettingsPractitionerFragment
        >
        meta: { __typename?: 'ResourceMetadata' } & Pick<ResourceMetadata, 'timestamp'>
      }
  >
}

export type GetCustomerSuggestedDispensariesQueryVariables = Exact<{
  customerId: Scalars['String']
}>

export type GetCustomerSuggestedDispensariesQuery = { __typename?: 'Query' } & {
  getCustomerSuggestedDispensaries?: Maybe<
    { __typename?: 'CustomerSuggestedDispensaries' } & {
      suggestedDispensaries: Array<
        Maybe<
          { __typename?: 'CustomerSuggestedDispensary' } & Pick<
            CustomerSuggestedDispensary,
            'name' | 'slug'
          >
        >
      >
      meta: { __typename?: 'CollectionMetadata' } & Pick<
        CollectionMetadata,
        'timestamp' | 'totalCount'
      >
    }
  >
}

export type PutCustomerSuggestedDispensarySelectionMutationVariables = Exact<{
  customerId: Scalars['String']
  customerSuggestedDispensarySelectionUpdateInput: CustomerSuggestedDispensarySelectionUpdateInput
}>

export type PutCustomerSuggestedDispensarySelectionMutation = { __typename?: 'Mutation' } & {
  putCustomerSuggestedDispensarySelection?: Maybe<
    { __typename?: 'CustomerSuggestedDispensary' } & Pick<
      CustomerSuggestedDispensary,
      'name' | 'slug'
    >
  >
}

export type GetDispensariesQueryVariables = Exact<{ [key: string]: never }>

export type GetDispensariesQuery = { __typename?: 'Query' } & {
  getDispensaries?: Maybe<
    { __typename?: 'Dispensaries' } & {
      dispensaries: Array<
        Maybe<
          { __typename?: 'Dispensary' } & Pick<Dispensary, 'slug'> & {
              meta: { __typename?: 'ResourceMetadata' } & Pick<ResourceMetadata, 'timestamp'>
            }
        >
      >
      meta: { __typename?: 'CollectionMetadata' } & Pick<
        CollectionMetadata,
        'totalCount' | 'timestamp'
      > & {
          pageInfo: { __typename?: 'PageInfo' } & Pick<
            PageInfo,
            'currentPage' | 'pageSize' | 'totalPages'
          >
        }
    }
  >
}

export type GetDispensaryByCodeQueryVariables = Exact<{
  practitionerCode: Scalars['String']
}>

export type GetDispensaryByCodeQuery = { __typename?: 'Query' } & {
  getDispensaryByCode?: Maybe<
    { __typename?: 'Dispensary' } & Pick<Dispensary, 'name' | 'practitionerCode' | 'slug'> & {
        meta: { __typename?: 'ResourceMetadata' } & Pick<ResourceMetadata, 'timestamp'>
      }
  >
}

export type GetDispensaryQueryVariables = Exact<{
  dispensarySlug: Scalars['String']
}>

export type GetDispensaryQuery = { __typename?: 'Query' } & {
  getDispensary?: Maybe<
    { __typename?: 'Dispensary' } & Pick<
      Dispensary,
      | 'name'
      | 'practitionerCode'
      | 'slug'
      | 'expirationDate'
      | 'supersededByName'
      | 'supersededBySlug'
    > & { meta: { __typename?: 'ResourceMetadata' } & Pick<ResourceMetadata, 'timestamp'> }
  >
}

export type GetEncodedDispensaryPromosQueryVariables = Exact<{
  dispensarySlug?: Maybe<Scalars['String']>
}>

export type GetEncodedDispensaryPromosQuery = { __typename?: 'Query' } & {
  getEncodedDispensaryPromos?: Maybe<
    { __typename?: 'DispensaryPromos' } & {
      promos: Array<
        Maybe<
          { __typename?: 'DispensaryPromo' } & Pick<
            DispensaryPromo,
            'dispensaryCodeHash' | 'rootCodeSecret'
          >
        >
      >
      rootPromos: Array<
        Maybe<
          { __typename?: 'DispensaryRootPromo' } & Pick<
            DispensaryRootPromo,
            'rootCodeHash' | 'name'
          >
        >
      >
      meta: { __typename?: 'ResourceMetadata' } & Pick<ResourceMetadata, 'timestamp'>
    }
  >
}

export const CustomerInvoiceFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'customerInvoice' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomerInvoice' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amountRemaining' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'addressee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attention' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coloradoRetailDeliveryFee' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdFromOrder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discountTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'item' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'otherRefNum' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'addressee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attention' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shippingTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CustomerInvoiceFragment, unknown>
export const CustomerSettingsPractitionerFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'customerSettingsPractitioner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomerSettingsPractitioner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commissionsOptOutFormReceived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'achFormReceived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxIdFormReceived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'howDidYouHearAboutUs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licensedInState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specialties' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dispensingOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inPractice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'isDesired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eStore' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'isDesired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subdomain' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dfhWebstore' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'isDesired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'enrolledInRebate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optedOutOfDispensary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optedOutOfInboundPatient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optedOutOfDispensaryOrderEmails' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CustomerSettingsPractitionerFragment, unknown>
export const GetCustomerAccountBalanceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerAccountBalance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerAccountBalance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'balanceOwed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creditLimit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'daysOverdue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'onCreditHold' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terms' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'timestamp' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCustomerAccountBalanceQuery,
  GetCustomerAccountBalanceQueryVariables
>
export const GetCustomerAccountHistoryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerAccountHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerAccountHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'balanceOwed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creditLimit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'daysOverdue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terms' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'charge' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'payment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transactionNumber' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'timestamp' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCustomerAccountHistoryQuery,
  GetCustomerAccountHistoryQueryVariables
>
export const PutCustomerAutoShipDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'putCustomerAutoShip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoShipId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerAutoShipUpdateInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomerAutoShipUpdateInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'putCustomerAutoShip' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'autoShipId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'autoShipId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerAutoShipUpdateInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerAutoShipUpdateInput' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'autoShipId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoShipNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextShipDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalOrderNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedOrderIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shippingAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'addressee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'timestamp' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PutCustomerAutoShipMutation, PutCustomerAutoShipMutationVariables>
export const GetCustomerAutoShipsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerAutoShips' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerAutoShips' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'autoShips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoShipId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoShipNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipmentCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextShipDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalOrderNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'relatedOrderIds' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shippingAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'addressee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'meta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetCustomerAutoShipsQuery, GetCustomerAutoShipsQueryVariables>
export const DeleteCustomerDispensaryPromoDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCustomerDispensaryPromo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dispensarySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'promoId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomerDispensaryPromo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dispensarySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dispensarySlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'promoId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'promoId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dispensaryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteCustomerDispensaryPromoMutation,
  DeleteCustomerDispensaryPromoMutationVariables
>
export const PostCustomerDispensaryPromoDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'postCustomerDispensaryPromo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dispensarySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerDispensaryPromoNewInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomerDispensaryPromoNewInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postCustomerDispensaryPromo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dispensarySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dispensarySlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerDispensaryPromoNewInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerDispensaryPromoNewInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dispensaryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inactive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rootPromoName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rootPromoId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  PostCustomerDispensaryPromoMutation,
  PostCustomerDispensaryPromoMutationVariables
>
export const PutCustomerDispensaryPromoDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'putCustomerDispensaryPromo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dispensarySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'promoId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerDispensaryPromoUpdateInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomerDispensaryPromoUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'putCustomerDispensaryPromo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dispensarySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dispensarySlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'promoId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'promoId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerDispensaryPromoUpdateInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerDispensaryPromoUpdateInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dispensaryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inactive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rootPromoName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rootPromoId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  PutCustomerDispensaryPromoMutation,
  PutCustomerDispensaryPromoMutationVariables
>
export const GetCustomerDispensaryPromosDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerDispensaryPromos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dispensarySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerDispensaryPromos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dispensarySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dispensarySlug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'promos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dispensaryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inactive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rootPromoName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rootPromoId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rootPromos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'timestamp' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCustomerDispensaryPromosQuery,
  GetCustomerDispensaryPromosQueryVariables
>
export const GetCustomerInvoiceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'otherCustomerIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'otherCustomerIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'otherCustomerIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'customerInvoice' } },
              ],
            },
          },
        ],
      },
    },
    ...CustomerInvoiceFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<GetCustomerInvoiceQuery, GetCustomerInvoiceQueryVariables>
export const GetCustomerInvoicesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerInvoices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'otherCustomerIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerInvoices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'otherCustomerIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'otherCustomerIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'customerInvoice' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CustomerInvoiceFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>
export const GetCustomerOrderDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'otherCustomerIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'otherCustomerIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'otherCustomerIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transactionNumber' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'coloradoRetailDeliveryFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountTotal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'warehouseLocation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'holdUntilMonday' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantityBackordered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantityInvoiced' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantityShipped' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creditCard' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'ccType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ccExpDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ccLast4' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'terms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftCertificateApplied' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipMethod' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shippingAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'addressee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attention' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shippingTotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxTotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetCustomerOrderQuery, GetCustomerOrderQueryVariables>
export const GetCustomerOrdersDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'otherCustomerIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'otherCustomerIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'otherCustomerIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoices' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'transactionNumber' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfillments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'trackingLink' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quantityBackordered' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quantityInvoiced' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quantityShipped' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shippingAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'addressee' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'creditCard' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'ccType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ccExpDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ccLast4' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'terms' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'giftCertificateApplied' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetCustomerOrdersQuery, GetCustomerOrdersQueryVariables>
export const GetCustomerRebateBalanceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerRebateBalance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerRebateBalance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pendingRebatesEarned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeCreditBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetCustomerRebateBalanceQuery, GetCustomerRebateBalanceQueryVariables>
export const GetCustomerRebateEstimateDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerRebateEstimate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerRebateEstimate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'period' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'periodRebatesEarned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectedEOMBalance' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salesTotals' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'retail' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'pracPrice' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rebates' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'salesTotal' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'wholeSale' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'pracPrice' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rebates' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'salesTotal' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'timestamp' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCustomerRebateEstimateQuery,
  GetCustomerRebateEstimateQueryVariables
>
export const PostCustomerRebateRedemptionCashRequestDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'postCustomerRebateRedemptionCashRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rebateValue' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postCustomerRebateRedemptionCashRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerRebateRedemptionCashRequestNewInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rebateValue' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'rebateValue' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requestId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rebateValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cashValue' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  PostCustomerRebateRedemptionCashRequestMutation,
  PostCustomerRebateRedemptionCashRequestMutationVariables
>
export const GetCustomerRebateRedemptionCashRequestsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerRebateRedemptionCashRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerRebateRedemptionCashRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requestId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rebateValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cashValue' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCustomerRebateRedemptionCashRequestsQuery,
  GetCustomerRebateRedemptionCashRequestsQueryVariables
>
export const GetCustomerRewardsOrderDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerRewardsOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerRewardsOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coloradoRetailDeliveryFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountTotal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'warehouseLocation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'holdUntilMonday' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantityBackordered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantityInvoiced' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantityShipped' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'notConsulted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderCustomerEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderCustomerName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shippingTotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxTotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetCustomerRewardsOrderQuery, GetCustomerRewardsOrderQueryVariables>
export const GetCustomerRewardsOrdersDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerRewardsOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rewardsSource' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RewardsSource' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerRewardsOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rewardsSource' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rewardsSource' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfillments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'trackingLink' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sku' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notConsulted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderCustomerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetCustomerRewardsOrdersQuery, GetCustomerRewardsOrdersQueryVariables>
export const PutCustomerSettingsPractitionerDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'putCustomerSettingsPractitioner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerSettingsPractitionerUpdateInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomerSettingsPractitionerUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'putCustomerSettingsPractitioner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerSettingsPractitionerUpdateInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerSettingsPractitionerUpdateInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerEmail' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'personal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'practitioner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'customerSettingsPractitioner' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'timestamp' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CustomerSettingsPractitionerFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  PutCustomerSettingsPractitionerMutation,
  PutCustomerSettingsPractitionerMutationVariables
>
export const GetCustomerSettingsPractitionerDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerSettingsPractitioner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerEmail' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'personal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'practitioner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'customerSettingsPractitioner' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'timestamp' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CustomerSettingsPractitionerFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  GetCustomerSettingsPractitionerQuery,
  GetCustomerSettingsPractitionerQueryVariables
>
export const GetCustomerSuggestedDispensariesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerSuggestedDispensaries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCustomerSuggestedDispensaries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'suggestedDispensaries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCustomerSuggestedDispensariesQuery,
  GetCustomerSuggestedDispensariesQueryVariables
>
export const PutCustomerSuggestedDispensarySelectionDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'putCustomerSuggestedDispensarySelection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerSuggestedDispensarySelectionUpdateInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomerSuggestedDispensarySelectionUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'putCustomerSuggestedDispensarySelection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerSuggestedDispensarySelectionUpdateInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerSuggestedDispensarySelectionUpdateInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  PutCustomerSuggestedDispensarySelectionMutation,
  PutCustomerSuggestedDispensarySelectionMutationVariables
>
export const GetDispensariesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDispensaries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDispensaries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dispensaries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'meta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetDispensariesQuery, GetDispensariesQueryVariables>
export const GetDispensaryByCodeDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDispensaryByCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'practitionerCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDispensaryByCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'practitionerCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'practitionerCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'practitionerCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'timestamp' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetDispensaryByCodeQuery, GetDispensaryByCodeQueryVariables>
export const GetDispensaryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDispensary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dispensarySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDispensary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dispensarySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dispensarySlug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'practitionerCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supersededByName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supersededBySlug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'timestamp' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetDispensaryQuery, GetDispensaryQueryVariables>
export const GetEncodedDispensaryPromosDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEncodedDispensaryPromos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dispensarySlug' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEncodedDispensaryPromos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dispensarySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dispensarySlug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'promos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'dispensaryCodeHash' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rootCodeSecret' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rootPromos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rootCodeHash' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'timestamp' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetEncodedDispensaryPromosQuery,
  GetEncodedDispensaryPromosQueryVariables
>
