import { useContext, useMemo } from 'react'

import { CartFragment } from '../../../graphql/magento'
import { UsePromisify, usePromisify } from '../../../hooks/usePromisify'
import { CartContext, CartContextValue } from '../context'

export type UseCartContext = CartContextValue & {
  loadCart: UsePromisify<CartFragment | undefined>['load']
}

export const useCartContext = (): UseCartContext => {
  const context = useContext(CartContext)
  const { cart, loading, error } = context

  const { load: loadCart } = usePromisify({ loading, error, data: cart })

  return useMemo(() => ({ ...context, loadCart }), [context, loadCart])
}
