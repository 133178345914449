import { ApolloClient, ApolloError } from '@apollo/client'
import {
  catchError,
  defaultIfEmpty,
  defer,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  retry,
  skipWhile,
  switchMap,
  take,
  timer,
} from 'rxjs'

import { GetDispensaryByCodeDocument, GetDispensaryByCodeQueryVariables } from '../../graphql/camel'
import { isNotNull } from '../../utils/collectionTools'
import { logAndCaptureException } from '../../utils/errorTools'
import { coerceCaughtToLeft } from '../../utils/rx/errors'
import { userCategories, userClaimIds } from '../../utils/userClaims'
import { AuthState, latestAuthState$ } from '../auth/state'
import { latestApolloClient$ } from '../graphql/apollo'
import { clearExternalNotifyEffect, handleExternalNotifyEffect } from './effects/notify'
import { handleExternalRedirectEffect } from './effects/redirect'
import { DispensaryExternalEffect } from './effects/types'
import { latestDispensary$ } from './state'

/**
 * Default action to take when user with assigned dispensary browses outside of all dispensaries.
 */
const DEFAULT_EXTERNAL_EFFECT: DispensaryExternalEffect['_tag'] = 'notify'

const isDispensaryExternalEffectTag = (value: unknown): value is DispensaryExternalEffect['_tag'] =>
  typeof value === 'string' && (value === 'notify' || value === 'redirect')

const readExternalEffectTagOrDefault: (v: unknown) => DispensaryExternalEffect['_tag'] = (v) =>
  isDispensaryExternalEffectTag(v) ? v : DEFAULT_EXTERNAL_EFFECT

const readString: (v: unknown) => string | undefined = (v) =>
  typeof v === 'string' ? v : undefined

const dispensaryByCodeFactory = (
  client: ApolloClient<unknown>,
  variables: GetDispensaryByCodeQueryVariables
) =>
  defer(() =>
    client.query({
      query: GetDispensaryByCodeDocument,
      variables,
      context: {
        uri: process.env.GATSBY_CAMEL_URL,
      },
      // don't throw on GraphQL errors
      errorPolicy: 'all',
    })
  ).pipe(
    // retry network errors
    retry({
      count: 3,
      delay: (_, count) =>
        // retry with exponential backoff and max of 60 seconds
        timer(Math.min(60000, 2 ^ (count * 1000))),
    }),
    map(({ data, errors }) => {
      if (errors?.length) {
        // log GraphQL errors and return left
        const error = new ApolloError({ graphQLErrors: errors })
        logAndCaptureException(error)
        return {
          _tag: 'Left' as const,
          error,
        }
      }
      return {
        _tag: 'Right' as const,
        data,
      }
    }),
    catchError(coerceCaughtToLeft)
  )

/**
 * Given a practitioner code, return an observable that uses the latest Apollo client to
 * resolve the default dispensary for that code.
 */
const dispensaryByCodeOrEmpty = (practitionerCode: string) =>
  latestApolloClient$.pipe(
    take(1),
    switchMap((client) =>
      dispensaryByCodeFactory(client, {
        practitionerCode,
      }).pipe(
        switchMap((result) => {
          // not much we can do if the dispensary cannot be resolved
          if (result._tag === 'Left') {
            return EMPTY
          }
          const dispensary = result.data.getDispensaryByCode
          // return EMPTY if dispensary not found for practitioner code
          return !dispensary ? EMPTY : of(dispensary)
        })
      )
    )
  )

const isUserEligibleForDispExternalEffect = (
  user: AuthState['user']
): user is NonNullable<AuthState['user']> =>
  !!user && user[userClaimIds.category] === userCategories.patient

/**
 * Given a user object from Auth0, return an observable that watches the latest dispensary
 * context (state) and emits an effect if the user is browsing outside of all dispensaries. If
 * no effect is warranted, the observable emits `null`.
 *
 * This observable completes if the user is not eligible for dispensary external effects (e.g.
 * is not a patient) or if the user does not have a dispensary assigned. Otherwise, the
 * observable never completes (continues to monitor dispensary state).
 */
const watchDispensaryExternalEffect = (
  user: AuthState['user']
): Observable<DispensaryExternalEffect | null> =>
  !isUserEligibleForDispExternalEffect(user)
    ? of(null)
    : of({
        externalEffectTag: readExternalEffectTagOrDefault(
          user[userClaimIds.dispensaryExternalEffect]
        ),
        dispensaryPractitionerCode: readString(user[userClaimIds.dispensaryPractitionerCode]),
      }).pipe(
        switchMap(({ dispensaryPractitionerCode, externalEffectTag }) =>
          !dispensaryPractitionerCode
            ? of(null)
            : latestDispensary$.pipe(
                switchMap((latestDispensary) =>
                  latestDispensary
                    ? of(null)
                    : // resolve dispensary from practitioner code
                      dispensaryByCodeOrEmpty(dispensaryPractitionerCode).pipe(
                        map((dispensary) => ({
                          _tag: externalEffectTag,
                          dispensaryPractitionerCode,
                          dispensary,
                        })),
                        defaultIfEmpty(null)
                      )
                )
              )
        )
      )

/**
 * Observable waits for auth state to settle and then evaluates whether the user is
 * assigned to a dispensary. If so, the dispensary context (state) is read, and an
 * effect is emitted if the user is browsing outside of all dispensaries. Otherwise,
 * the observable completes without emitting a value.
 *
 * The emitted effect (if any) indicates what action to take (e.g. notify or redirect)
 * to aide the user in navigating back to a dispensary.
 */
export const dispensaryExternalEffect$: Observable<DispensaryExternalEffect> = latestAuthState$.pipe(
  skipWhile((authState) => authState.isLoading),
  take(1),
  switchMap(({ user }) => watchDispensaryExternalEffect(user)),
  take(1),
  filter(isNotNull)
)

const AUTH_PARAM_CODE_RE = /[?&]code=[^&]+/
const AUTH_PARAM_STATE_RE = /[?&]state=[^&]+/
const AUTH_PARAM_ERROR_RE = /[?&]error=[^&]+/
const PATH_LOGGING_IN_RE = /^\/logging-in\/?$/

/**
 * Adapted from `auth0-react` library
 * https://github.com/auth0/auth0-react/blob/e9e319bee7b0df2fd16f1b108dc6045dfd02f358/src/utils.tsx#L7
 */
const hasAuthParams = (searchParams: string): boolean =>
  (AUTH_PARAM_CODE_RE.test(searchParams) || AUTH_PARAM_ERROR_RE.test(searchParams)) &&
  AUTH_PARAM_STATE_RE.test(searchParams)

const isPathExemptFromEffects = (path: string): boolean => PATH_LOGGING_IN_RE.test(path)

/**
 * Observable monitors the current location every 100ms and emits when the location does not
 * include authentication params or specifies a path that is exempt from dispensary effects.
 */
const waitForLocation$: Observable<void> = timer(0, 100).pipe(
  switchMap(() =>
    typeof window === 'undefined' ||
    hasAuthParams(window.location.search) ||
    isPathExemptFromEffects(window.location.pathname)
      ? EMPTY
      : of(void 0)
  )
)

/**
 * Watch authentication state while ignoring locations that explicitly check for dispensary effects
 * (e.g. location containing Auth0 callback params, /logging-in/ page).
 *
 * Once user is authenticated, watch dispensary state to determine appropriate effects (e.g. notify
 * or redirect when not using assigned dispensary) and execute the corresponding handler.
 */
export const dispensaryEffects$ = latestAuthState$.pipe(
  skipWhile((authState) => authState.isLoading),
  switchMap((authState) =>
    waitForLocation$.pipe(
      take(1),
      map(() => authState)
    )
  ),
  map(({ user }) => user),
  // filter out `null` user to avoid emitting a `null` effect when not authenticated (and for the
  // initial auth state)
  filter(isNotNull),
  distinctUntilChanged(),
  switchMap((user) =>
    watchDispensaryExternalEffect(user).pipe(
      switchMap((effect) => {
        if (effect == null) {
          return clearExternalNotifyEffect()
        }
        switch (effect._tag) {
          case 'notify':
            return handleExternalNotifyEffect(effect)
          case 'redirect':
            return handleExternalRedirectEffect(effect)
        }
      })
    )
  )
)
