import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type AddBundleProductsToCartInput = {
  cart_id: Scalars['String']
  cart_items: Array<Maybe<BundleProductCartItemInput>>
}

export type AddBundleProductsToCartOutput = {
  __typename?: 'AddBundleProductsToCartOutput'
  cart: Cart
}

export type AddConfigurableProductsToCartInput = {
  cart_id: Scalars['String']
  cart_items: Array<Maybe<ConfigurableProductCartItemInput>>
}

export type AddConfigurableProductsToCartOutput = {
  __typename?: 'AddConfigurableProductsToCartOutput'
  cart: Cart
}

export type AddDownloadableProductsToCartInput = {
  cart_id: Scalars['String']
  cart_items: Array<Maybe<DownloadableProductCartItemInput>>
}

export type AddDownloadableProductsToCartOutput = {
  __typename?: 'AddDownloadableProductsToCartOutput'
  cart: Cart
}

export type AddSimpleProductsToCartInput = {
  cart_id: Scalars['String']
  cart_items: Array<Maybe<SimpleProductCartItemInput>>
}

export type AddSimpleProductsToCartOutput = {
  __typename?: 'AddSimpleProductsToCartOutput'
  cart: Cart
}

export type AddVirtualProductsToCartInput = {
  cart_id: Scalars['String']
  cart_items: Array<Maybe<VirtualProductCartItemInput>>
}

export type AddVirtualProductsToCartOutput = {
  __typename?: 'AddVirtualProductsToCartOutput'
  cart: Cart
}

/** A bucket that contains information for each filterable option (such as price, category ID, and custom attributes). */
export type Aggregation = {
  __typename?: 'Aggregation'
  /** Attribute code of the aggregation group. */
  attribute_code: Scalars['String']
  /** The number of options in the aggregation group. */
  count?: Maybe<Scalars['Int']>
  /** The aggregation display name. */
  label?: Maybe<Scalars['String']>
  /** Array of options for the aggregation. */
  options?: Maybe<Array<Maybe<AggregationOption>>>
}

export type AggregationOption = AggregationOptionInterface & {
  __typename?: 'AggregationOption'
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']>
  /** Aggregation option display label. */
  label?: Maybe<Scalars['String']>
  /** The internal ID that represents the value of the option. */
  value: Scalars['String']
}

export type AggregationOptionInterface = {
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']>
  /** Aggregation option display label. */
  label?: Maybe<Scalars['String']>
  /** The internal ID that represents the value of the option. */
  value: Scalars['String']
}

export type AppliedCoupon = {
  __typename?: 'AppliedCoupon'
  code: Scalars['String']
}

export type ApplyCouponToCartInput = {
  cart_id: Scalars['String']
  coupon_code: Scalars['String']
}

export type ApplyCouponToCartOutput = {
  __typename?: 'ApplyCouponToCartOutput'
  cart: Cart
}

/** Attribute contains the attribute_type of the specified attribute_code and entity_type */
export type Attribute = {
  __typename?: 'Attribute'
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: Maybe<Scalars['String']>
  /** Attribute options list. */
  attribute_options?: Maybe<Array<Maybe<AttributeOption>>>
  /** The data type of the attribute */
  attribute_type?: Maybe<Scalars['String']>
  /** The type of entity that defines the attribute */
  entity_type?: Maybe<Scalars['String']>
  /** The frontend input type of the attribute */
  input_type?: Maybe<Scalars['String']>
}

/** AttributeInput specifies the attribute_code and entity_type to search */
export type AttributeInput = {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: Maybe<Scalars['String']>
  /** The type of entity that defines the attribute */
  entity_type?: Maybe<Scalars['String']>
}

/** Attribute option. */
export type AttributeOption = {
  __typename?: 'AttributeOption'
  /** Attribute option label. */
  label?: Maybe<Scalars['String']>
  /** Attribute option value. */
  value?: Maybe<Scalars['String']>
}

export type AvailablePaymentMethod = {
  __typename?: 'AvailablePaymentMethod'
  /** The payment method code */
  code: Scalars['String']
  /** The payment method title. */
  title: Scalars['String']
}

export type AvailableShippingMethod = {
  __typename?: 'AvailableShippingMethod'
  amount: Money
  available: Scalars['Boolean']
  /** @deprecated The field should not be used on the storefront */
  base_amount?: Maybe<Money>
  carrier_code: Scalars['String']
  carrier_title: Scalars['String']
  error_message?: Maybe<Scalars['String']>
  /** Could be null if method is not available */
  method_code?: Maybe<Scalars['String']>
  /** Could be null if method is not available */
  method_title?: Maybe<Scalars['String']>
  price_excl_tax: Money
  price_incl_tax: Money
}

export type BillingAddressInput = {
  address?: Maybe<CartAddressInput>
  customer_address_id?: Maybe<Scalars['Int']>
  /** Set billing address same as shipping */
  same_as_shipping?: Maybe<Scalars['Boolean']>
  /** Deprecated: use `same_as_shipping` field instead */
  use_for_shipping?: Maybe<Scalars['Boolean']>
}

export type BillingCartAddress = CartAddressInterface & {
  __typename?: 'BillingCartAddress'
  city: Scalars['String']
  company?: Maybe<Scalars['String']>
  country: CartAddressCountry
  /** @deprecated The field is used only in shipping address */
  customer_notes?: Maybe<Scalars['String']>
  firstname: Scalars['String']
  lastname: Scalars['String']
  postcode?: Maybe<Scalars['String']>
  region?: Maybe<CartAddressRegion>
  street: Array<Maybe<Scalars['String']>>
  telephone: Scalars['String']
}

/** Breadcrumb item. */
export type Breadcrumb = {
  __typename?: 'Breadcrumb'
  /** Category ID. */
  category_id?: Maybe<Scalars['Int']>
  /** Category level. */
  category_level?: Maybe<Scalars['Int']>
  /** Category name. */
  category_name?: Maybe<Scalars['String']>
  /** Category URL key. */
  category_url_key?: Maybe<Scalars['String']>
  /** Category URL path. */
  category_url_path?: Maybe<Scalars['String']>
}

export type BundleCartItem = CartItemInterface & {
  __typename?: 'BundleCartItem'
  bundle_options: Array<Maybe<SelectedBundleOption>>
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  id: Scalars['String']
  /** Cart item attributes */
  item_attributes?: Maybe<CartItemAttributes>
  prices?: Maybe<CartItemPrices>
  product: ProductInterface
  quantity: Scalars['Float']
}

/** BundleItem defines an individual item in a bundle product. */
export type BundleItem = {
  __typename?: 'BundleItem'
  /** An ID assigned to each type of item in a bundle product. */
  option_id?: Maybe<Scalars['Int']>
  /** An array of additional options for this bundle item. */
  options?: Maybe<Array<Maybe<BundleItemOption>>>
  /** he relative position of this item compared to the other bundle items. */
  position?: Maybe<Scalars['Int']>
  /** Indicates whether the item must be included in the bundle. */
  required?: Maybe<Scalars['Boolean']>
  /** The SKU of the bundle product. */
  sku?: Maybe<Scalars['String']>
  /** The display name of the item. */
  title?: Maybe<Scalars['String']>
  /** The input type that the customer uses to select the item. Examples include radio button and checkbox. */
  type?: Maybe<Scalars['String']>
}

/** BundleItemOption defines characteristics and options for a specific bundle item. */
export type BundleItemOption = {
  __typename?: 'BundleItemOption'
  /** Indicates whether the customer can change the number of items for this option. */
  can_change_quantity?: Maybe<Scalars['Boolean']>
  /** The ID assigned to the bundled item option. */
  id?: Maybe<Scalars['Int']>
  /** Indicates whether this option is the default option. */
  is_default?: Maybe<Scalars['Boolean']>
  /** The text that identifies the bundled item option. */
  label?: Maybe<Scalars['String']>
  /** When a bundle item contains multiple options, the relative position of this option compared to the other options. */
  position?: Maybe<Scalars['Int']>
  /** The price of the selected option. */
  price?: Maybe<Scalars['Float']>
  /** One of FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** Contains details about this product option. */
  product?: Maybe<ProductInterface>
  /**
   * Indicates the quantity of this specific bundle item.
   * @deprecated The `qty` is deprecated. Use `quantity` instead.
   */
  qty?: Maybe<Scalars['Float']>
  /** Indicates the quantity of this specific bundle item. */
  quantity?: Maybe<Scalars['Float']>
}

export type BundleOptionInput = {
  id: Scalars['Int']
  quantity: Scalars['Float']
  value: Array<Maybe<Scalars['String']>>
}

/** BundleProduct defines basic features of a bundle product and contains multiple BundleItems. */
export type BundleProduct = CustomizableProductInterface &
  PhysicalProductInterface &
  ProductInterface & {
    __typename?: 'BundleProduct'
    a_z?: Maybe<Scalars['Int']>
    activity?: Maybe<Scalars['String']>
    /** Advisor Tags based on advisor_tags attribute */
    advisor_tags?: Maybe<Array<Maybe<Scalars['String']>>>
    allow_credit_range?: Maybe<Scalars['Int']>
    /** The attribute set assigned to the product. */
    attribute_set_id?: Maybe<Scalars['Int']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    category_gear?: Maybe<Scalars['String']>
    climate?: Maybe<Scalars['String']>
    collar?: Maybe<Scalars['String']>
    color?: Maybe<Scalars['Int']>
    content_left_both?: Maybe<Scalars['String']>
    content_left_patient?: Maybe<Scalars['String']>
    content_left_professional?: Maybe<Scalars['String']>
    content_left_public?: Maybe<Scalars['String']>
    content_right_both?: Maybe<Scalars['String']>
    content_right_patient?: Maybe<Scalars['String']>
    content_right_professional?: Maybe<Scalars['String']>
    content_right_public?: Maybe<Scalars['String']>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was created. */
    created_at?: Maybe<Scalars['String']>
    credit_amount?: Maybe<Scalars['Float']>
    credit_rate?: Maybe<Scalars['Float']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    doc_clinical_trials?: Maybe<Scalars['String']>
    doc_patient_education?: Maybe<Scalars['String']>
    doc_tech_sheet?: Maybe<Scalars['String']>
    /** Indicates whether the bundle product has a dynamic price. */
    dynamic_price?: Maybe<Scalars['Boolean']>
    /** Indicates whether the bundle product has a dynamic SK. */
    dynamic_sku?: Maybe<Scalars['Boolean']>
    /** Indicates whether the bundle product has a dynamically calculated weight. */
    dynamic_weight?: Maybe<Scalars['Boolean']>
    eco_collection?: Maybe<Scalars['Int']>
    erin_recommends?: Maybe<Scalars['Int']>
    features_bags?: Maybe<Scalars['String']>
    format?: Maybe<Scalars['Int']>
    gender?: Maybe<Scalars['String']>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']>
    heat_sensitive?: Maybe<Scalars['Int']>
    /** The ID number assigned to the product. */
    id?: Maybe<Scalars['Int']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** An array containing information about individual bundle items. */
    items?: Maybe<Array<Maybe<BundleItem>>>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']>
    material?: Maybe<Scalars['String']>
    max_credit?: Maybe<Scalars['Float']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']>
    min_credit?: Maybe<Scalars['Float']>
    /** Store Credit product attributes */
    mp_store_credit_attributes?: Maybe<Array<Maybe<StoreCreditProductAttributes>>>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']>
    natural_product_number?: Maybe<Scalars['String']>
    new?: Maybe<Scalars['Int']>
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    new_from_date?: Maybe<Scalars['String']>
    /** The end date for new product listings. */
    new_to_date?: Maybe<Scalars['String']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']>
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']>
    out_of_stock_message?: Maybe<Scalars['String']>
    pattern?: Maybe<Scalars['String']>
    performance_fabric?: Maybe<Scalars['Int']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    /** One of PRICE_RANGE or AS_LOW_AS. */
    price_view?: Maybe<PriceViewEnum>
    /** Product Form based on product_form attribute */
    product_form?: Maybe<Scalars['String']>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    /** One of simple, virtual, bundle, downloadable, grouped, or configurable. */
    product_type_id?: Maybe<Scalars['String']>
    /** Prop65 Message */
    prop65_message?: Maybe<Scalars['String']>
    prop_sixty_five?: Maybe<Scalars['Int']>
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    sale?: Maybe<Scalars['Int']>
    /** Indicates whether to ship bundle items together or individually. */
    ship_bundle_items?: Maybe<ShipBundleItemsEnum>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size?: Maybe<Scalars['Int']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']>
    sleeve?: Maybe<Scalars['String']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /** The beginning date that a product has a special price. */
    special_from_date?: Maybe<Scalars['String']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']>
    /** Specialty based on specialty attribute */
    specialty?: Maybe<Array<Maybe<Scalars['String']>>>
    /** Stock Item Configuration */
    stock_configuration?: Maybe<StockItemConfiguration>
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    strap_bags?: Maybe<Scalars['String']>
    style_bags?: Maybe<Scalars['String']>
    style_bottom?: Maybe<Scalars['String']>
    style_general?: Maybe<Scalars['String']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was updated. */
    updated_at?: Maybe<Scalars['String']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<Scalars['Float']>
  }

export type BundleProductCartItemInput = {
  bundle_options: Array<Maybe<BundleOptionInput>>
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>
  data: CartItemInput
}

export type Cart = {
  __typename?: 'Cart'
  /**
   * An array of coupons that have been applied to the cart
   * @deprecated Use applied_coupons instead
   */
  applied_coupon?: Maybe<AppliedCoupon>
  /** An array of `AppliedCoupon` objects. Each object contains the `code` text attribute, which specifies the coupon code */
  applied_coupons?: Maybe<Array<Maybe<AppliedCoupon>>>
  /** Available payment methods */
  available_payment_methods?: Maybe<Array<Maybe<AvailablePaymentMethod>>>
  billing_address?: Maybe<BillingCartAddress>
  dispensary_promo_code?: Maybe<Scalars['String']>
  drop_ship?: Maybe<Scalars['String']>
  e_script_campaign?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  hold_until_monday?: Maybe<Scalars['String']>
  /** The ID of the cart. */
  id: Scalars['ID']
  is_virtual: Scalars['Boolean']
  items?: Maybe<Array<Maybe<CartItemInterface>>>
  ns_customer_id?: Maybe<Scalars['String']>
  practitioner_code?: Maybe<Scalars['String']>
  prices?: Maybe<CartPrices>
  /** Applied store credit to cart */
  rebate?: Maybe<Money>
  referrer_uri?: Maybe<Scalars['String']>
  selected_payment_method?: Maybe<SelectedPaymentMethod>
  ship_date?: Maybe<Scalars['String']>
  shipping_addresses: Array<Maybe<ShippingCartAddress>>
  total_quantity: Scalars['Float']
  ww_id?: Maybe<Scalars['String']>
  ww_practice_id?: Maybe<Scalars['String']>
  ww_practitioner_id?: Maybe<Scalars['String']>
  ww_store_id?: Maybe<Scalars['String']>
}

export type CartAddressCountry = {
  __typename?: 'CartAddressCountry'
  code: Scalars['String']
  label: Scalars['String']
}

export type CartAddressInput = {
  city: Scalars['String']
  company?: Maybe<Scalars['String']>
  country_code: Scalars['String']
  firstname: Scalars['String']
  lastname: Scalars['String']
  postcode?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  save_in_address_book?: Maybe<Scalars['Boolean']>
  street: Array<Maybe<Scalars['String']>>
  telephone: Scalars['String']
}

export type CartAddressInterface = {
  city: Scalars['String']
  company?: Maybe<Scalars['String']>
  country: CartAddressCountry
  firstname: Scalars['String']
  lastname: Scalars['String']
  postcode?: Maybe<Scalars['String']>
  region?: Maybe<CartAddressRegion>
  street: Array<Maybe<Scalars['String']>>
  telephone: Scalars['String']
}

export type CartAddressRegion = {
  __typename?: 'CartAddressRegion'
  code: Scalars['String']
  label: Scalars['String']
}

export type CartDiscount = {
  __typename?: 'CartDiscount'
  amount: Money
  label: Array<Maybe<Scalars['String']>>
}

export type CartItemAttributes = {
  __typename?: 'CartItemAttributes'
  description?: Maybe<Scalars['String']>
  dfh_autoship_interval?: Maybe<Scalars['Int']>
  dfh_order_type?: Maybe<Scalars['Int']>
  dosage?: Maybe<Scalars['String']>
  flavor?: Maybe<Scalars['String']>
  flavor_size?: Maybe<Scalars['String']>
  heat_sensitive?: Maybe<Scalars['Int']>
  prop65_message?: Maybe<Scalars['String']>
  prop_sixty_five?: Maybe<Scalars['Int']>
  short_description?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
}

export type CartItemInput = {
  dfh_autoship_interval?: Maybe<Scalars['Int']>
  dfh_order_type?: Maybe<Scalars['Int']>
  quantity: Scalars['Float']
  sku: Scalars['String']
}

export type CartItemInterface = {
  id: Scalars['String']
  /** Cart item attributes */
  item_attributes?: Maybe<CartItemAttributes>
  prices?: Maybe<CartItemPrices>
  product: ProductInterface
  quantity: Scalars['Float']
}

export type CartItemPrices = {
  __typename?: 'CartItemPrices'
  /** An array of discounts to be applied to the cart item */
  discounts?: Maybe<Array<Maybe<Discount>>>
  price: Money
  row_total: Money
  row_total_including_tax: Money
  /** The total of all discounts applied to the item */
  total_item_discount?: Maybe<Money>
}

/** Deprecated: `cart_items` field of `ShippingCartAddress` returns now  `CartItemInterface` instead of `CartItemQuantity` */
export type CartItemQuantity = {
  __typename?: 'CartItemQuantity'
  /** @deprecated `cart_items` field of `ShippingCartAddress` returns now `CartItemInterface` instead of `CartItemQuantity` */
  cart_item_id: Scalars['Int']
  /** @deprecated `cart_items` field of `ShippingCartAddress` returns now `CartItemInterface` instead of `CartItemQuantity` */
  quantity: Scalars['Float']
}

export type CartItemSelectedOptionValuePrice = {
  __typename?: 'CartItemSelectedOptionValuePrice'
  type: PriceTypeEnum
  units: Scalars['String']
  value: Scalars['Float']
}

export type CartItemUpdateInput = {
  cart_item_id: Scalars['Int']
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>
  dfh_autoship_interval?: Maybe<Scalars['Int']>
  dfh_order_type?: Maybe<Scalars['Int']>
  quantity?: Maybe<Scalars['Float']>
}

export type CartPrices = {
  __typename?: 'CartPrices'
  applied_taxes?: Maybe<Array<Maybe<CartTaxItem>>>
  /** Amount for Colorado Retail Delivery Fee if applicable */
  colorado_retail_delivery_fee?: Maybe<Money>
  /** @deprecated Use discounts instead  */
  discount?: Maybe<CartDiscount>
  /** An array of applied discounts */
  discounts?: Maybe<Array<Maybe<Discount>>>
  grand_total?: Maybe<Money>
  subtotal_excluding_tax?: Maybe<Money>
  subtotal_including_tax?: Maybe<Money>
  subtotal_with_discount_excluding_tax?: Maybe<Money>
}

export type CartTaxItem = {
  __typename?: 'CartTaxItem'
  amount: Money
  label: Scalars['String']
}

/** CategoryFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type CategoryFilterInput = {
  /** Filter by category ID that uniquely identifies the category. */
  ids?: Maybe<FilterEqualTypeInput>
  /** Filter by the display name of the category. */
  name?: Maybe<FilterMatchTypeInput>
  /** Filter by the part of the URL that identifies the category */
  url_key?: Maybe<FilterEqualTypeInput>
}

/** CategoryInterface contains the full set of attributes that can be returned in a category search. */
export type CategoryInterface = {
  available_sort_by?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Breadcrumbs, parent categories info. */
  breadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled */
  canonical_url?: Maybe<Scalars['String']>
  children_count?: Maybe<Scalars['String']>
  /** Category CMS Block. */
  cms_block?: Maybe<CmsBlock>
  /** Timestamp indicating when the category was created. */
  created_at?: Maybe<Scalars['String']>
  custom_layout_update_file?: Maybe<Scalars['String']>
  /** The attribute to use for sorting. */
  default_sort_by?: Maybe<Scalars['String']>
  /** An optional description of the category. */
  description?: Maybe<Scalars['String']>
  display_mode?: Maybe<Scalars['String']>
  filter_price_range?: Maybe<Scalars['Float']>
  /** An ID that uniquely identifies the category. */
  id?: Maybe<Scalars['Int']>
  image?: Maybe<Scalars['String']>
  include_in_menu?: Maybe<Scalars['Int']>
  is_anchor?: Maybe<Scalars['Int']>
  landing_page?: Maybe<Scalars['Int']>
  /** Indicates the depth of the category within the tree. */
  level?: Maybe<Scalars['Int']>
  meta_description?: Maybe<Scalars['String']>
  meta_keywords?: Maybe<Scalars['String']>
  meta_title?: Maybe<Scalars['String']>
  /** The display name of the category. */
  name?: Maybe<Scalars['String']>
  /** Category Path. */
  path?: Maybe<Scalars['String']>
  /** Category path in store. */
  path_in_store?: Maybe<Scalars['String']>
  /** The position of the category relative to other categories at the same level in tree. */
  position?: Maybe<Scalars['Int']>
  /** The number of products in the category. */
  product_count?: Maybe<Scalars['Int']>
  /** The list of products assigned to the category. */
  products?: Maybe<CategoryProducts>
  /** Timestamp indicating when the category was updated. */
  updated_at?: Maybe<Scalars['String']>
  /** The url key assigned to the category. */
  url_key?: Maybe<Scalars['String']>
  /** The url path assigned to the category. */
  url_path?: Maybe<Scalars['String']>
  /** The part of the category URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>
}

/** CategoryInterface contains the full set of attributes that can be returned in a category search. */
export type CategoryInterfaceProductsArgs = {
  currentPage?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  sort?: Maybe<ProductAttributeSortInput>
}

/** The category products object returned in the Category query. */
export type CategoryProducts = {
  __typename?: 'CategoryProducts'
  /** An array of products that are assigned to the category. */
  items?: Maybe<Array<Maybe<ProductInterface>>>
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>
  /** The number of products returned. */
  total_count?: Maybe<Scalars['Int']>
}

/** Category Tree implementation. */
export type CategoryTree = CategoryInterface & {
  __typename?: 'CategoryTree'
  available_sort_by?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Breadcrumbs, parent categories info. */
  breadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled */
  canonical_url?: Maybe<Scalars['String']>
  /** Child categories tree. */
  children?: Maybe<Array<Maybe<CategoryTree>>>
  children_count?: Maybe<Scalars['String']>
  /** Category CMS Block. */
  cms_block?: Maybe<CmsBlock>
  /** Timestamp indicating when the category was created. */
  created_at?: Maybe<Scalars['String']>
  custom_layout_update_file?: Maybe<Scalars['String']>
  /** The attribute to use for sorting. */
  default_sort_by?: Maybe<Scalars['String']>
  /** An optional description of the category. */
  description?: Maybe<Scalars['String']>
  display_mode?: Maybe<Scalars['String']>
  filter_price_range?: Maybe<Scalars['Float']>
  /** An ID that uniquely identifies the category. */
  id?: Maybe<Scalars['Int']>
  image?: Maybe<Scalars['String']>
  include_in_menu?: Maybe<Scalars['Int']>
  is_anchor?: Maybe<Scalars['Int']>
  landing_page?: Maybe<Scalars['Int']>
  /** Indicates the depth of the category within the tree. */
  level?: Maybe<Scalars['Int']>
  meta_description?: Maybe<Scalars['String']>
  meta_keywords?: Maybe<Scalars['String']>
  meta_title?: Maybe<Scalars['String']>
  /** The display name of the category. */
  name?: Maybe<Scalars['String']>
  /** Category Path. */
  path?: Maybe<Scalars['String']>
  /** Category path in store. */
  path_in_store?: Maybe<Scalars['String']>
  /** The position of the category relative to other categories at the same level in tree. */
  position?: Maybe<Scalars['Int']>
  /** The number of products in the category. */
  product_count?: Maybe<Scalars['Int']>
  /** The list of products assigned to the category. */
  products?: Maybe<CategoryProducts>
  /** Timestamp indicating when the category was updated. */
  updated_at?: Maybe<Scalars['String']>
  /** The url key assigned to the category. */
  url_key?: Maybe<Scalars['String']>
  /** The url path assigned to the category. */
  url_path?: Maybe<Scalars['String']>
  /** The part of the category URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>
}

/** Category Tree implementation. */
export type CategoryTreeProductsArgs = {
  currentPage?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  sort?: Maybe<ProductAttributeSortInput>
}

/** Defines all Checkout Agreement information */
export type CheckoutAgreement = {
  __typename?: 'CheckoutAgreement'
  /** Checkout Agreement identifier */
  agreement_id: Scalars['Int']
  /** Checkout Agreement checkbox text */
  checkbox_text: Scalars['String']
  /** Checkout Agreement content */
  content: Scalars['String']
  /** Checkout Agreement content height */
  content_height?: Maybe<Scalars['String']>
  /** Is Checkout Agreement content in HTML format */
  is_html: Scalars['Boolean']
  mode: CheckoutAgreementMode
  /** Checkout Agreement name */
  name: Scalars['String']
}

export const CheckoutAgreementMode = {
  Auto: 'AUTO',
  Manual: 'MANUAL',
} as const

export type CheckoutAgreementMode = typeof CheckoutAgreementMode[keyof typeof CheckoutAgreementMode]
/** CMS block defines all CMS block information */
export type CmsBlock = {
  __typename?: 'CmsBlock'
  /** CMS block content */
  content?: Maybe<Scalars['String']>
  /** CMS block identifier */
  identifier?: Maybe<Scalars['String']>
  /** CMS block title */
  title?: Maybe<Scalars['String']>
}

/** CMS blocks information */
export type CmsBlocks = {
  __typename?: 'CmsBlocks'
  /** An array of CMS blocks */
  items?: Maybe<Array<Maybe<CmsBlock>>>
}

/** CMS page defines all CMS page information */
export type CmsPage = {
  __typename?: 'CmsPage'
  /** CMS page content */
  content?: Maybe<Scalars['String']>
  /** CMS page content heading */
  content_heading?: Maybe<Scalars['String']>
  /** Identifier of the CMS page */
  identifier?: Maybe<Scalars['String']>
  /** CMS page meta description */
  meta_description?: Maybe<Scalars['String']>
  /** CMS page meta keywords */
  meta_keywords?: Maybe<Scalars['String']>
  /** CMS page meta title */
  meta_title?: Maybe<Scalars['String']>
  /** CMS page content heading */
  page_layout?: Maybe<Scalars['String']>
  /** CMS page title */
  title?: Maybe<Scalars['String']>
  /** URL key of CMS page */
  url_key?: Maybe<Scalars['String']>
}

export type ColorSwatchData = SwatchDataInterface & {
  __typename?: 'ColorSwatchData'
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']>
}

export type ComplexTextValue = {
  __typename?: 'ComplexTextValue'
  /** HTML format */
  html: Scalars['String']
}

/** ConfigurableAttributeOption contains the value_index (and other related information) assigned to a configurable product option */
export type ConfigurableAttributeOption = {
  __typename?: 'ConfigurableAttributeOption'
  /** The ID assigned to the attribute */
  code?: Maybe<Scalars['String']>
  /** A string that describes the configurable attribute option */
  label?: Maybe<Scalars['String']>
  /** A unique index number assigned to the configurable product option */
  value_index?: Maybe<Scalars['Int']>
}

export type ConfigurableCartItem = CartItemInterface & {
  __typename?: 'ConfigurableCartItem'
  configurable_options: Array<Maybe<SelectedConfigurableOption>>
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  id: Scalars['String']
  /** Cart item attributes */
  item_attributes?: Maybe<CartItemAttributes>
  prices?: Maybe<CartItemPrices>
  product: ProductInterface
  quantity: Scalars['Float']
}

/** ConfigurableProduct defines basic features of a configurable product and its simple product variants */
export type ConfigurableProduct = CustomizableProductInterface &
  PhysicalProductInterface &
  ProductInterface & {
    __typename?: 'ConfigurableProduct'
    a_z?: Maybe<Scalars['Int']>
    activity?: Maybe<Scalars['String']>
    /** Advisor Tags based on advisor_tags attribute */
    advisor_tags?: Maybe<Array<Maybe<Scalars['String']>>>
    allow_credit_range?: Maybe<Scalars['Int']>
    /** The attribute set assigned to the product. */
    attribute_set_id?: Maybe<Scalars['Int']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    category_gear?: Maybe<Scalars['String']>
    climate?: Maybe<Scalars['String']>
    collar?: Maybe<Scalars['String']>
    color?: Maybe<Scalars['Int']>
    /** An array of linked simple product items */
    configurable_options?: Maybe<Array<Maybe<ConfigurableProductOptions>>>
    content_left_both?: Maybe<Scalars['String']>
    content_left_patient?: Maybe<Scalars['String']>
    content_left_professional?: Maybe<Scalars['String']>
    content_left_public?: Maybe<Scalars['String']>
    content_right_both?: Maybe<Scalars['String']>
    content_right_patient?: Maybe<Scalars['String']>
    content_right_professional?: Maybe<Scalars['String']>
    content_right_public?: Maybe<Scalars['String']>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was created. */
    created_at?: Maybe<Scalars['String']>
    credit_amount?: Maybe<Scalars['Float']>
    credit_rate?: Maybe<Scalars['Float']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    doc_clinical_trials?: Maybe<Scalars['String']>
    doc_patient_education?: Maybe<Scalars['String']>
    doc_tech_sheet?: Maybe<Scalars['String']>
    eco_collection?: Maybe<Scalars['Int']>
    erin_recommends?: Maybe<Scalars['Int']>
    features_bags?: Maybe<Scalars['String']>
    format?: Maybe<Scalars['Int']>
    gender?: Maybe<Scalars['String']>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']>
    heat_sensitive?: Maybe<Scalars['Int']>
    /** The ID number assigned to the product. */
    id?: Maybe<Scalars['Int']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']>
    material?: Maybe<Scalars['String']>
    max_credit?: Maybe<Scalars['Float']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']>
    min_credit?: Maybe<Scalars['Float']>
    /** Store Credit product attributes */
    mp_store_credit_attributes?: Maybe<Array<Maybe<StoreCreditProductAttributes>>>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']>
    natural_product_number?: Maybe<Scalars['String']>
    new?: Maybe<Scalars['Int']>
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    new_from_date?: Maybe<Scalars['String']>
    /** The end date for new product listings. */
    new_to_date?: Maybe<Scalars['String']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']>
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']>
    out_of_stock_message?: Maybe<Scalars['String']>
    pattern?: Maybe<Scalars['String']>
    performance_fabric?: Maybe<Scalars['Int']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    /** Product Form based on product_form attribute */
    product_form?: Maybe<Scalars['String']>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    /** One of simple, virtual, bundle, downloadable, grouped, or configurable. */
    product_type_id?: Maybe<Scalars['String']>
    /** Prop65 Message */
    prop65_message?: Maybe<Scalars['String']>
    prop_sixty_five?: Maybe<Scalars['Int']>
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    sale?: Maybe<Scalars['Int']>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size?: Maybe<Scalars['Int']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']>
    sleeve?: Maybe<Scalars['String']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /** The beginning date that a product has a special price. */
    special_from_date?: Maybe<Scalars['String']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']>
    /** Specialty based on specialty attribute */
    specialty?: Maybe<Array<Maybe<Scalars['String']>>>
    /** Stock Item Configuration */
    stock_configuration?: Maybe<StockItemConfiguration>
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    strap_bags?: Maybe<Scalars['String']>
    style_bags?: Maybe<Scalars['String']>
    style_bottom?: Maybe<Scalars['String']>
    style_general?: Maybe<Scalars['String']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was updated. */
    updated_at?: Maybe<Scalars['String']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']>
    /** An index of product variants */
    variant_index?: Maybe<ConfigurableVariantIndex>
    /** An array of variants of products */
    variants?: Maybe<Array<Maybe<ConfigurableVariant>>>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<Scalars['Float']>
  }

export type ConfigurableProductCartItemInput = {
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>
  data: CartItemInput
  /** Configurable product SKU. */
  parent_sku?: Maybe<Scalars['String']>
  variant_sku?: Maybe<Scalars['String']>
}

/** ConfigurableProductOptions defines configurable attributes for the specified product */
export type ConfigurableProductOptions = {
  __typename?: 'ConfigurableProductOptions'
  /** A string that identifies the attribute */
  attribute_code?: Maybe<Scalars['String']>
  /** The ID assigned to the attribute */
  attribute_id?: Maybe<Scalars['String']>
  /** The configurable option ID number assigned by the system */
  id?: Maybe<Scalars['Int']>
  /** A string that describes the configurable product option, which is displayed on the UI */
  label?: Maybe<Scalars['String']>
  /** A number that indicates the order in which the attribute is displayed */
  position?: Maybe<Scalars['Int']>
  /** This is the same as a product's id field */
  product_id?: Maybe<Scalars['Int']>
  /** Indicates whether the option is the default */
  use_default?: Maybe<Scalars['Boolean']>
  /** An array that defines the value_index codes assigned to the configurable product */
  values?: Maybe<Array<Maybe<ConfigurableProductOptionsValues>>>
}

/** ConfigurableProductOptionsValues contains the index number assigned to a configurable product option */
export type ConfigurableProductOptionsValues = {
  __typename?: 'ConfigurableProductOptionsValues'
  /** The label of the product on the default store */
  default_label?: Maybe<Scalars['String']>
  /** The label of the product */
  label?: Maybe<Scalars['String']>
  /** The label of the product on the current store */
  store_label?: Maybe<Scalars['String']>
  /** Swatch data for configurable product option */
  swatch_data?: Maybe<SwatchDataInterface>
  /** Indicates whether to use the default_label */
  use_default_value?: Maybe<Scalars['Boolean']>
  /** A unique index number assigned to the configurable product option */
  value_index?: Maybe<Scalars['Int']>
}

/** An array containing all the simple product variants of a configurable product */
export type ConfigurableVariant = {
  __typename?: 'ConfigurableVariant'
  attributes?: Maybe<Array<Maybe<ConfigurableAttributeOption>>>
  product?: Maybe<SimpleProduct>
}

/** An index containing all the simple product variants of a configurable product */
export type ConfigurableVariantIndex = {
  __typename?: 'ConfigurableVariantIndex'
  /** An array of used attribute values and available child products */
  attribute_product_list?: Maybe<Scalars['String']>
  /** An array of used attribute values */
  attribute_values?: Maybe<Scalars['String']>
  /** Attribute value to child product mapping */
  mapping?: Maybe<Scalars['String']>
  /** An array of child products of a configurable product with resolved values */
  product_index?: Maybe<Scalars['String']>
  /** An array of used product attributes of a configurable product */
  used_attributes?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type Country = {
  __typename?: 'Country'
  available_regions?: Maybe<Array<Maybe<Region>>>
  full_name_english?: Maybe<Scalars['String']>
  full_name_locale?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  three_letter_abbreviation?: Maybe<Scalars['String']>
  two_letter_abbreviation?: Maybe<Scalars['String']>
}

/** The list of countries codes */
export const CountryCodeEnum = {
  /** Andorra */
  Ad: 'AD',
  /** United Arab Emirates */
  Ae: 'AE',
  /** Afghanistan */
  Af: 'AF',
  /** Antigua & Barbuda */
  Ag: 'AG',
  /** Anguilla */
  Ai: 'AI',
  /** Albania */
  Al: 'AL',
  /** Armenia */
  Am: 'AM',
  /** Netherlands Antilles */
  An: 'AN',
  /** Angola */
  Ao: 'AO',
  /** Antarctica */
  Aq: 'AQ',
  /** Argentina */
  Ar: 'AR',
  /** American Samoa */
  As: 'AS',
  /** Austria */
  At: 'AT',
  /** Australia */
  Au: 'AU',
  /** Aruba */
  Aw: 'AW',
  /** Åland Islands */
  Ax: 'AX',
  /** Azerbaijan */
  Az: 'AZ',
  /** Bosnia & Herzegovina */
  Ba: 'BA',
  /** Barbados */
  Bb: 'BB',
  /** Bangladesh */
  Bd: 'BD',
  /** Belgium */
  Be: 'BE',
  /** Burkina Faso */
  Bf: 'BF',
  /** Bulgaria */
  Bg: 'BG',
  /** Bahrain */
  Bh: 'BH',
  /** Burundi */
  Bi: 'BI',
  /** Benin */
  Bj: 'BJ',
  /** St. Barthélemy */
  Bl: 'BL',
  /** Bermuda */
  Bm: 'BM',
  /** Brunei */
  Bn: 'BN',
  /** Bolivia */
  Bo: 'BO',
  /** Brazil */
  Br: 'BR',
  /** Bahamas */
  Bs: 'BS',
  /** Bhutan */
  Bt: 'BT',
  /** Bouvet Island */
  Bv: 'BV',
  /** Botswana */
  Bw: 'BW',
  /** Belarus */
  By: 'BY',
  /** Belize */
  Bz: 'BZ',
  /** Canada */
  Ca: 'CA',
  /** Cocos (Keeling) Islands */
  Cc: 'CC',
  /** Congo-Kinshasa */
  Cd: 'CD',
  /** Central African Republic */
  Cf: 'CF',
  /** Congo-Brazzaville */
  Cg: 'CG',
  /** Switzerland */
  Ch: 'CH',
  /** Côte d’Ivoire */
  Ci: 'CI',
  /** Cook Islands */
  Ck: 'CK',
  /** Chile */
  Cl: 'CL',
  /** Cameroon */
  Cm: 'CM',
  /** China */
  Cn: 'CN',
  /** Colombia */
  Co: 'CO',
  /** Costa Rica */
  Cr: 'CR',
  /** Cuba */
  Cu: 'CU',
  /** Cape Verde */
  Cv: 'CV',
  /** Christmas Island */
  Cx: 'CX',
  /** Cyprus */
  Cy: 'CY',
  /** Czech Republic */
  Cz: 'CZ',
  /** Germany */
  De: 'DE',
  /** Djibouti */
  Dj: 'DJ',
  /** Denmark */
  Dk: 'DK',
  /** Dominica */
  Dm: 'DM',
  /** Dominican Republic */
  Do: 'DO',
  /** Algeria */
  Dz: 'DZ',
  /** Ecuador */
  Ec: 'EC',
  /** Estonia */
  Ee: 'EE',
  /** Egypt */
  Eg: 'EG',
  /** Western Sahara */
  Eh: 'EH',
  /** Eritrea */
  Er: 'ER',
  /** Spain */
  Es: 'ES',
  /** Ethiopia */
  Et: 'ET',
  /** Finland */
  Fi: 'FI',
  /** Fiji */
  Fj: 'FJ',
  /** Falkland Islands */
  Fk: 'FK',
  /** Micronesia */
  Fm: 'FM',
  /** Faroe Islands */
  Fo: 'FO',
  /** France */
  Fr: 'FR',
  /** Gabon */
  Ga: 'GA',
  /** United Kingdom */
  Gb: 'GB',
  /** Grenada */
  Gd: 'GD',
  /** Georgia */
  Ge: 'GE',
  /** French Guiana */
  Gf: 'GF',
  /** Guernsey */
  Gg: 'GG',
  /** Ghana */
  Gh: 'GH',
  /** Gibraltar */
  Gi: 'GI',
  /** Greenland */
  Gl: 'GL',
  /** Gambia */
  Gm: 'GM',
  /** Guinea */
  Gn: 'GN',
  /** Guadeloupe */
  Gp: 'GP',
  /** Equatorial Guinea */
  Gq: 'GQ',
  /** Greece */
  Gr: 'GR',
  /** South Georgia & South Sandwich Islands */
  Gs: 'GS',
  /** Guatemala */
  Gt: 'GT',
  /** Guam */
  Gu: 'GU',
  /** Guinea-Bissau */
  Gw: 'GW',
  /** Guyana */
  Gy: 'GY',
  /** Hong Kong SAR China */
  Hk: 'HK',
  /** Heard &amp; McDonald Islands */
  Hm: 'HM',
  /** Honduras */
  Hn: 'HN',
  /** Croatia */
  Hr: 'HR',
  /** Haiti */
  Ht: 'HT',
  /** Hungary */
  Hu: 'HU',
  /** Indonesia */
  Id: 'ID',
  /** Ireland */
  Ie: 'IE',
  /** Israel */
  Il: 'IL',
  /** Isle of Man */
  Im: 'IM',
  /** India */
  In: 'IN',
  /** British Indian Ocean Territory */
  Io: 'IO',
  /** Iraq */
  Iq: 'IQ',
  /** Iran */
  Ir: 'IR',
  /** Iceland */
  Is: 'IS',
  /** Italy */
  It: 'IT',
  /** Jersey */
  Je: 'JE',
  /** Jamaica */
  Jm: 'JM',
  /** Jordan */
  Jo: 'JO',
  /** Japan */
  Jp: 'JP',
  /** Kenya */
  Ke: 'KE',
  /** Kyrgyzstan */
  Kg: 'KG',
  /** Cambodia */
  Kh: 'KH',
  /** Kiribati */
  Ki: 'KI',
  /** Comoros */
  Km: 'KM',
  /** St. Kitts & Nevis */
  Kn: 'KN',
  /** North Korea */
  Kp: 'KP',
  /** South Korea */
  Kr: 'KR',
  /** Kuwait */
  Kw: 'KW',
  /** Cayman Islands */
  Ky: 'KY',
  /** Kazakhstan */
  Kz: 'KZ',
  /** Laos */
  La: 'LA',
  /** Lebanon */
  Lb: 'LB',
  /** St. Lucia */
  Lc: 'LC',
  /** Liechtenstein */
  Li: 'LI',
  /** Sri Lanka */
  Lk: 'LK',
  /** Liberia */
  Lr: 'LR',
  /** Lesotho */
  Ls: 'LS',
  /** Lithuania */
  Lt: 'LT',
  /** Luxembourg */
  Lu: 'LU',
  /** Latvia */
  Lv: 'LV',
  /** Libya */
  Ly: 'LY',
  /** Morocco */
  Ma: 'MA',
  /** Monaco */
  Mc: 'MC',
  /** Moldova */
  Md: 'MD',
  /** Montenegro */
  Me: 'ME',
  /** St. Martin */
  Mf: 'MF',
  /** Madagascar */
  Mg: 'MG',
  /** Marshall Islands */
  Mh: 'MH',
  /** Macedonia */
  Mk: 'MK',
  /** Mali */
  Ml: 'ML',
  /** Myanmar (Burma) */
  Mm: 'MM',
  /** Mongolia */
  Mn: 'MN',
  /** Macau SAR China */
  Mo: 'MO',
  /** Northern Mariana Islands */
  Mp: 'MP',
  /** Martinique */
  Mq: 'MQ',
  /** Mauritania */
  Mr: 'MR',
  /** Montserrat */
  Ms: 'MS',
  /** Malta */
  Mt: 'MT',
  /** Mauritius */
  Mu: 'MU',
  /** Maldives */
  Mv: 'MV',
  /** Malawi */
  Mw: 'MW',
  /** Mexico */
  Mx: 'MX',
  /** Malaysia */
  My: 'MY',
  /** Mozambique */
  Mz: 'MZ',
  /** Namibia */
  Na: 'NA',
  /** New Caledonia */
  Nc: 'NC',
  /** Niger */
  Ne: 'NE',
  /** Norfolk Island */
  Nf: 'NF',
  /** Nigeria */
  Ng: 'NG',
  /** Nicaragua */
  Ni: 'NI',
  /** Netherlands */
  Nl: 'NL',
  /** Norway */
  No: 'NO',
  /** Nepal */
  Np: 'NP',
  /** Nauru */
  Nr: 'NR',
  /** Niue */
  Nu: 'NU',
  /** New Zealand */
  Nz: 'NZ',
  /** Oman */
  Om: 'OM',
  /** Panama */
  Pa: 'PA',
  /** Peru */
  Pe: 'PE',
  /** French Polynesia */
  Pf: 'PF',
  /** Papua New Guinea */
  Pg: 'PG',
  /** Philippines */
  Ph: 'PH',
  /** Pakistan */
  Pk: 'PK',
  /** Poland */
  Pl: 'PL',
  /** St. Pierre & Miquelon */
  Pm: 'PM',
  /** Pitcairn Islands */
  Pn: 'PN',
  /** Palestinian Territories */
  Ps: 'PS',
  /** Portugal */
  Pt: 'PT',
  /** Palau */
  Pw: 'PW',
  /** Paraguay */
  Py: 'PY',
  /** Qatar */
  Qa: 'QA',
  /** Réunion */
  Re: 'RE',
  /** Romania */
  Ro: 'RO',
  /** Serbia */
  Rs: 'RS',
  /** Russia */
  Ru: 'RU',
  /** Rwanda */
  Rw: 'RW',
  /** Saudi Arabia */
  Sa: 'SA',
  /** Solomon Islands */
  Sb: 'SB',
  /** Seychelles */
  Sc: 'SC',
  /** Sudan */
  Sd: 'SD',
  /** Sweden */
  Se: 'SE',
  /** Singapore */
  Sg: 'SG',
  /** St. Helena */
  Sh: 'SH',
  /** Slovenia */
  Si: 'SI',
  /** Svalbard & Jan Mayen */
  Sj: 'SJ',
  /** Slovakia */
  Sk: 'SK',
  /** Sierra Leone */
  Sl: 'SL',
  /** San Marino */
  Sm: 'SM',
  /** Senegal */
  Sn: 'SN',
  /** Somalia */
  So: 'SO',
  /** Suriname */
  Sr: 'SR',
  /** São Tomé & Príncipe */
  St: 'ST',
  /** El Salvador */
  Sv: 'SV',
  /** Syria */
  Sy: 'SY',
  /** Swaziland */
  Sz: 'SZ',
  /** Turks & Caicos Islands */
  Tc: 'TC',
  /** Chad */
  Td: 'TD',
  /** French Southern Territories */
  Tf: 'TF',
  /** Togo */
  Tg: 'TG',
  /** Thailand */
  Th: 'TH',
  /** Tajikistan */
  Tj: 'TJ',
  /** Tokelau */
  Tk: 'TK',
  /** Timor-Leste */
  Tl: 'TL',
  /** Turkmenistan */
  Tm: 'TM',
  /** Tunisia */
  Tn: 'TN',
  /** Tonga */
  To: 'TO',
  /** Turkey */
  Tr: 'TR',
  /** Trinidad & Tobago */
  Tt: 'TT',
  /** Tuvalu */
  Tv: 'TV',
  /** Taiwan */
  Tw: 'TW',
  /** Tanzania */
  Tz: 'TZ',
  /** Ukraine */
  Ua: 'UA',
  /** Uganda */
  Ug: 'UG',
  /** U.S. Outlying Islands */
  Um: 'UM',
  /** United States */
  Us: 'US',
  /** Uruguay */
  Uy: 'UY',
  /** Uzbekistan */
  Uz: 'UZ',
  /** Vatican City */
  Va: 'VA',
  /** St. Vincent & Grenadines */
  Vc: 'VC',
  /** Venezuela */
  Ve: 'VE',
  /** British Virgin Islands */
  Vg: 'VG',
  /** U.S. Virgin Islands */
  Vi: 'VI',
  /** Vietnam */
  Vn: 'VN',
  /** Vanuatu */
  Vu: 'VU',
  /** Wallis & Futuna */
  Wf: 'WF',
  /** Samoa */
  Ws: 'WS',
  /** Yemen */
  Ye: 'YE',
  /** Mayotte */
  Yt: 'YT',
  /** South Africa */
  Za: 'ZA',
  /** Zambia */
  Zm: 'ZM',
  /** Zimbabwe */
  Zw: 'ZW',
} as const

export type CountryCodeEnum = typeof CountryCodeEnum[keyof typeof CountryCodeEnum]
/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type CreatePayflowProTokenOutput = {
  __typename?: 'CreatePayflowProTokenOutput'
  response_message: Scalars['String']
  result: Scalars['Int']
  result_code: Scalars['Int']
  secure_token: Scalars['String']
  secure_token_id: Scalars['String']
}

/** Required fields for Payflow Pro and Payments Pro credit card payments */
export type CreditCardDetailsInput = {
  /** Credit card expiration month */
  cc_exp_month: Scalars['Int']
  /** Credit card expiration year */
  cc_exp_year: Scalars['Int']
  /** Last 4 digits of the credit card */
  cc_last_4: Scalars['Int']
  /** Credit card type */
  cc_type: Scalars['String']
}

export type Currency = {
  __typename?: 'Currency'
  available_currency_codes?: Maybe<Array<Maybe<Scalars['String']>>>
  base_currency_code?: Maybe<Scalars['String']>
  base_currency_symbol?: Maybe<Scalars['String']>
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  default_display_currecy_code?: Maybe<Scalars['String']>
  /** @deprecated Symbol was missed. Use `default_display_currency_symbol`. */
  default_display_currecy_symbol?: Maybe<Scalars['String']>
  default_display_currency_code?: Maybe<Scalars['String']>
  default_display_currency_symbol?: Maybe<Scalars['String']>
  exchange_rates?: Maybe<Array<Maybe<ExchangeRate>>>
}

/** The list of available currency codes */
export const CurrencyEnum = {
  Aed: 'AED',
  Afn: 'AFN',
  All: 'ALL',
  Amd: 'AMD',
  Ang: 'ANG',
  Aoa: 'AOA',
  Ars: 'ARS',
  Aud: 'AUD',
  Awg: 'AWG',
  Azm: 'AZM',
  Azn: 'AZN',
  Bam: 'BAM',
  Bbd: 'BBD',
  Bdt: 'BDT',
  Bgn: 'BGN',
  Bhd: 'BHD',
  Bif: 'BIF',
  Bmd: 'BMD',
  Bnd: 'BND',
  Bob: 'BOB',
  Brl: 'BRL',
  Bsd: 'BSD',
  Btn: 'BTN',
  Buk: 'BUK',
  Bwp: 'BWP',
  Byr: 'BYR',
  Bzd: 'BZD',
  Cad: 'CAD',
  Cdf: 'CDF',
  Che: 'CHE',
  Chf: 'CHF',
  Chw: 'CHW',
  Clp: 'CLP',
  Cny: 'CNY',
  Cop: 'COP',
  Crc: 'CRC',
  Cup: 'CUP',
  Cve: 'CVE',
  Czk: 'CZK',
  Djf: 'DJF',
  Dkk: 'DKK',
  Dop: 'DOP',
  Dzd: 'DZD',
  Eek: 'EEK',
  Egp: 'EGP',
  Ern: 'ERN',
  Etb: 'ETB',
  Eur: 'EUR',
  Fjd: 'FJD',
  Fkp: 'FKP',
  Gbp: 'GBP',
  Gek: 'GEK',
  Gel: 'GEL',
  Ghs: 'GHS',
  Gip: 'GIP',
  Gmd: 'GMD',
  Gnf: 'GNF',
  Gqe: 'GQE',
  Gtq: 'GTQ',
  Gyd: 'GYD',
  Hkd: 'HKD',
  Hnl: 'HNL',
  Hrk: 'HRK',
  Htg: 'HTG',
  Huf: 'HUF',
  Idr: 'IDR',
  Ils: 'ILS',
  Inr: 'INR',
  Iqd: 'IQD',
  Irr: 'IRR',
  Isk: 'ISK',
  Jmd: 'JMD',
  Jod: 'JOD',
  Jpy: 'JPY',
  Kes: 'KES',
  Kgs: 'KGS',
  Khr: 'KHR',
  Kmf: 'KMF',
  Kpw: 'KPW',
  Krw: 'KRW',
  Kwd: 'KWD',
  Kyd: 'KYD',
  Kzt: 'KZT',
  Lak: 'LAK',
  Lbp: 'LBP',
  Lkr: 'LKR',
  Lrd: 'LRD',
  Lsl: 'LSL',
  Lsm: 'LSM',
  Ltl: 'LTL',
  Lvl: 'LVL',
  Lyd: 'LYD',
  Mad: 'MAD',
  Mdl: 'MDL',
  Mga: 'MGA',
  Mkd: 'MKD',
  Mmk: 'MMK',
  Mnt: 'MNT',
  Mop: 'MOP',
  Mro: 'MRO',
  Mur: 'MUR',
  Mvr: 'MVR',
  Mwk: 'MWK',
  Mxn: 'MXN',
  Myr: 'MYR',
  Mzn: 'MZN',
  Nad: 'NAD',
  Ngn: 'NGN',
  Nic: 'NIC',
  Nok: 'NOK',
  Npr: 'NPR',
  Nzd: 'NZD',
  Omr: 'OMR',
  Pab: 'PAB',
  Pen: 'PEN',
  Pgk: 'PGK',
  Php: 'PHP',
  Pkr: 'PKR',
  Pln: 'PLN',
  Pyg: 'PYG',
  Qar: 'QAR',
  Rhd: 'RHD',
  Rol: 'ROL',
  Ron: 'RON',
  Rsd: 'RSD',
  Rub: 'RUB',
  Rwf: 'RWF',
  Sar: 'SAR',
  Sbd: 'SBD',
  Scr: 'SCR',
  Sdg: 'SDG',
  Sek: 'SEK',
  Sgd: 'SGD',
  Shp: 'SHP',
  Skk: 'SKK',
  Sll: 'SLL',
  Sos: 'SOS',
  Srd: 'SRD',
  Std: 'STD',
  Svc: 'SVC',
  Syp: 'SYP',
  Szl: 'SZL',
  Thb: 'THB',
  Tjs: 'TJS',
  Tmm: 'TMM',
  Tnd: 'TND',
  Top: 'TOP',
  Trl: 'TRL',
  Try: 'TRY',
  Ttd: 'TTD',
  Twd: 'TWD',
  Tzs: 'TZS',
  Uah: 'UAH',
  Ugx: 'UGX',
  Usd: 'USD',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Veb: 'VEB',
  Vef: 'VEF',
  Vnd: 'VND',
  Vuv: 'VUV',
  Wst: 'WST',
  Xcd: 'XCD',
  Xof: 'XOF',
  Xpf: 'XPF',
  Yer: 'YER',
  Ytl: 'YTL',
  Zar: 'ZAR',
  Zmk: 'ZMK',
  Zwd: 'ZWD',
} as const

export type CurrencyEnum = typeof CurrencyEnum[keyof typeof CurrencyEnum]
/** CustomAttributeMetadata defines an array of attribute_codes and entity_types */
export type CustomAttributeMetadata = {
  __typename?: 'CustomAttributeMetadata'
  /** An array of attributes */
  items?: Maybe<Array<Maybe<Attribute>>>
}

/** Customer defines the customer name and address and other details */
export type Customer = {
  __typename?: 'Customer'
  /** An array containing the customer's shipping and billing addresses */
  addresses?: Maybe<Array<Maybe<CustomerAddress>>>
  /** Timestamp indicating when the account was created */
  created_at?: Maybe<Scalars['String']>
  /** The customer's date of birth */
  date_of_birth?: Maybe<Scalars['String']>
  /** The ID assigned to the billing address */
  default_billing?: Maybe<Scalars['String']>
  /** The ID assigned to the shipping address */
  default_shipping?: Maybe<Scalars['String']>
  /**
   * The customer's date of birth
   * @deprecated Use `date_of_birth` instead
   */
  dob?: Maybe<Scalars['String']>
  /** The customer's email address. Required */
  email?: Maybe<Scalars['String']>
  /** The customer's first name */
  firstname?: Maybe<Scalars['String']>
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: Maybe<Scalars['Int']>
  /** @deprecated Customer group should not be exposed in the storefront scenarios */
  group_id?: Maybe<Scalars['Int']>
  /**
   * The ID assigned to the customer
   * @deprecated id is not needed as part of Customer because on server side it can be identified based on customer token used for authentication. There is no need to know customer ID on the client side.
   */
  id?: Maybe<Scalars['Int']>
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: Maybe<Scalars['Boolean']>
  /** The customer's family name */
  lastname?: Maybe<Scalars['String']>
  /** The customer's middle name */
  middlename?: Maybe<Scalars['String']>
  /** Store Credit customer */
  mp_store_credit?: Maybe<MpStoreCreditCustomersOutput>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>
  /** The customer's Value-added tax (VAT) number (for corporate customers) */
  taxvat?: Maybe<Scalars['String']>
  vip_discount_name?: Maybe<Scalars['String']>
  /** The wishlist query returns the contents of a customer's wish lists */
  wishlist: Wishlist
}

/** CustomerAddress contains detailed information about a customer's billing and shipping addresses */
export type CustomerAddress = {
  __typename?: 'CustomerAddress'
  /** The city or town */
  city?: Maybe<Scalars['String']>
  /** The customer's company */
  company?: Maybe<Scalars['String']>
  /** The customer's country */
  country_code?: Maybe<CountryCodeEnum>
  /**
   * The customer's country
   * @deprecated Use `country_code` instead.
   */
  country_id?: Maybe<Scalars['String']>
  /** @deprecated Custom attributes should not be put into container */
  custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttribute>>>
  /**
   * The customer ID
   * @deprecated customer_id is not needed as part of CustomerAddress, address ID (id) is unique identifier for the addresses.
   */
  customer_id?: Maybe<Scalars['Int']>
  /** Indicates whether the address is the default billing address */
  default_billing?: Maybe<Scalars['Boolean']>
  /** Indicates whether the address is the default shipping address */
  default_shipping?: Maybe<Scalars['Boolean']>
  /** Address extension attributes */
  extension_attributes?: Maybe<Array<Maybe<CustomerAddressAttribute>>>
  /** The fax number */
  fax?: Maybe<Scalars['String']>
  /** The first name of the person associated with the shipping/billing address */
  firstname?: Maybe<Scalars['String']>
  /** The ID assigned to the address object */
  id?: Maybe<Scalars['Int']>
  /** The family name of the person associated with the shipping/billing address */
  lastname?: Maybe<Scalars['String']>
  /** The middle name of the person associated with the shipping/billing address */
  middlename?: Maybe<Scalars['String']>
  /** The customer's ZIP or postal code */
  postcode?: Maybe<Scalars['String']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>
  /** An object containing the region name, region code, and region ID */
  region?: Maybe<CustomerAddressRegion>
  /** @deprecated Region ID is excessive on storefront and region code should suffice for all scenarios */
  region_id?: Maybe<Scalars['Int']>
  /** An array of strings that define the street number and name */
  street?: Maybe<Array<Maybe<Scalars['String']>>>
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>
  /** The telephone number */
  telephone?: Maybe<Scalars['String']>
  /** The customer's Value-added tax (VAT) number (for corporate customers) */
  vat_id?: Maybe<Scalars['String']>
}

export type CustomerAddressAttribute = {
  __typename?: 'CustomerAddressAttribute'
  /** Attribute code */
  attribute_code?: Maybe<Scalars['String']>
  /** Attribute value */
  value?: Maybe<Scalars['String']>
}

export type CustomerAddressAttributeInput = {
  /** Attribute code */
  attribute_code: Scalars['String']
  /** Attribute value */
  value: Scalars['String']
}

export type CustomerAddressInput = {
  /** The city or town */
  city?: Maybe<Scalars['String']>
  /** The customer's company */
  company?: Maybe<Scalars['String']>
  /** The customer's country */
  country_code?: Maybe<CountryCodeEnum>
  /** Deprecated: use `country_code` instead. */
  country_id?: Maybe<CountryCodeEnum>
  /** Deprecated: Custom attributes should not be put into container. */
  custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttributeInput>>>
  /** Indicates whether the address is the default billing address */
  default_billing?: Maybe<Scalars['Boolean']>
  /** Indicates whether the address is the default shipping address */
  default_shipping?: Maybe<Scalars['Boolean']>
  /** The fax number */
  fax?: Maybe<Scalars['String']>
  /** The first name of the person associated with the shipping/billing address */
  firstname?: Maybe<Scalars['String']>
  /** The family name of the person associated with the shipping/billing address */
  lastname?: Maybe<Scalars['String']>
  /** The middle name of the person associated with the shipping/billing address */
  middlename?: Maybe<Scalars['String']>
  /** The customer's ZIP or postal code */
  postcode?: Maybe<Scalars['String']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>
  /** An object containing the region name, region code, and region ID */
  region?: Maybe<CustomerAddressRegionInput>
  /** An array of strings that define the street number and name */
  street?: Maybe<Array<Maybe<Scalars['String']>>>
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>
  /** The telephone number */
  telephone?: Maybe<Scalars['String']>
  /** The customer's Tax/VAT number (for corporate customers) */
  vat_id?: Maybe<Scalars['String']>
}

/** CustomerAddressRegion defines the customer's state or province */
export type CustomerAddressRegion = {
  __typename?: 'CustomerAddressRegion'
  /** The state or province name */
  region?: Maybe<Scalars['String']>
  /** The address region code */
  region_code?: Maybe<Scalars['String']>
  /** @deprecated Region ID is excessive on storefront and region code should suffice for all scenarios */
  region_id?: Maybe<Scalars['Int']>
}

/** CustomerAddressRegionInput defines the customer's state or province */
export type CustomerAddressRegionInput = {
  /** The state or province name */
  region?: Maybe<Scalars['String']>
  /** The address region code */
  region_code?: Maybe<Scalars['String']>
  /** region_id is deprecated. Region ID is excessive on storefront and region code should suffice for all scenarios */
  region_id?: Maybe<Scalars['Int']>
}

export type CustomerDownloadableProduct = {
  __typename?: 'CustomerDownloadableProduct'
  date?: Maybe<Scalars['String']>
  download_url?: Maybe<Scalars['String']>
  order_increment_id?: Maybe<Scalars['String']>
  remaining_downloads?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
}

export type CustomerDownloadableProducts = {
  __typename?: 'CustomerDownloadableProducts'
  /** List of purchased downloadable items */
  items?: Maybe<Array<Maybe<CustomerDownloadableProduct>>>
}

export type CustomerInput = {
  /** The customer's date of birth */
  date_of_birth?: Maybe<Scalars['String']>
  /** Deprecated: Use `date_of_birth` instead */
  dob?: Maybe<Scalars['String']>
  /** The customer's email address. Required for customer creation */
  email?: Maybe<Scalars['String']>
  /** The customer's first name */
  firstname?: Maybe<Scalars['String']>
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: Maybe<Scalars['Int']>
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: Maybe<Scalars['Boolean']>
  /** The customer's family name */
  lastname?: Maybe<Scalars['String']>
  /** The customer's middle name */
  middlename?: Maybe<Scalars['String']>
  /** The customer's password */
  password?: Maybe<Scalars['String']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>
  /** The customer's Tax/VAT number (for corporate customers) */
  taxvat?: Maybe<Scalars['String']>
}

/** Order mapping fields */
export type CustomerOrder = {
  __typename?: 'CustomerOrder'
  created_at?: Maybe<Scalars['String']>
  grand_total?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Int']>
  /** @deprecated Use the order_number instead. */
  increment_id?: Maybe<Scalars['String']>
  /** The order number */
  order_number: Scalars['String']
  status?: Maybe<Scalars['String']>
}

export type CustomerOrders = {
  __typename?: 'CustomerOrders'
  /** Array of orders */
  items?: Maybe<Array<Maybe<CustomerOrder>>>
}

export type CustomerOutput = {
  __typename?: 'CustomerOutput'
  customer: Customer
}

export type CustomerPaymentTokens = {
  __typename?: 'CustomerPaymentTokens'
  /** An array of payment tokens */
  items: Array<Maybe<PaymentToken>>
}

export type CustomerToken = {
  __typename?: 'CustomerToken'
  /** The customer token */
  token?: Maybe<Scalars['String']>
}

/** CustomizableAreaOption contains information about a text area that is defined as part of a customizable option. */
export type CustomizableAreaOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableAreaOption'
  /** Option ID. */
  option_id?: Maybe<Scalars['Int']>
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>
  /** An object that defines a text area. */
  value?: Maybe<CustomizableAreaValue>
}

/** CustomizableAreaValue defines the price and sku of a product whose page contains a customized text area. */
export type CustomizableAreaValue = {
  __typename?: 'CustomizableAreaValue'
  /** The maximum number of characters that can be entered for this customizable option. */
  max_characters?: Maybe<Scalars['Int']>
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>
}

/** CustomizableCheckbbixOption contains information about a set of checkbox values that are defined as part of a customizable option. */
export type CustomizableCheckboxOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableCheckboxOption'
  /** Option ID. */
  option_id?: Maybe<Scalars['Int']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>
  /** An array that defines a set of checkbox values. */
  value?: Maybe<Array<Maybe<CustomizableCheckboxValue>>>
}

/** CustomizableCheckboxValue defines the price and sku of a product whose page contains a customized set of checkbox values. */
export type CustomizableCheckboxValue = {
  __typename?: 'CustomizableCheckboxValue'
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>
  /** The order in which the checkbox value is displayed. */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>
}

/** CustomizableDateOption contains information about a date picker that is defined as part of a customizable option. */
export type CustomizableDateOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableDateOption'
  /** Option ID. */
  option_id?: Maybe<Scalars['Int']>
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>
  /** An object that defines a date field in a customizable option. */
  value?: Maybe<CustomizableDateValue>
}

/** CustomizableDateValue defines the price and sku of a product whose page contains a customized date picker. */
export type CustomizableDateValue = {
  __typename?: 'CustomizableDateValue'
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>
}

/** CustomizableDropDownOption contains information about a drop down menu that is defined as part of a customizable option. */
export type CustomizableDropDownOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableDropDownOption'
  /** Option ID. */
  option_id?: Maybe<Scalars['Int']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>
  /** An array that defines the set of options for a drop down menu. */
  value?: Maybe<Array<Maybe<CustomizableDropDownValue>>>
}

/** CustomizableDropDownValue defines the price and sku of a product whose page contains a customized drop down menu. */
export type CustomizableDropDownValue = {
  __typename?: 'CustomizableDropDownValue'
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>
}

/** CustomizableFieldOption contains information about a text field that is defined as part of a customizable option. */
export type CustomizableFieldOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableFieldOption'
  /** Option ID. */
  option_id?: Maybe<Scalars['Int']>
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>
  /** An object that defines a text field. */
  value?: Maybe<CustomizableFieldValue>
}

/** CustomizableFieldValue defines the price and sku of a product whose page contains a customized text field. */
export type CustomizableFieldValue = {
  __typename?: 'CustomizableFieldValue'
  /** The maximum number of characters that can be entered for this customizable option. */
  max_characters?: Maybe<Scalars['Int']>
  /** The price of the custom value. */
  price?: Maybe<Scalars['Float']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>
}

/** CustomizableFileOption contains information about a file picker that is defined as part of a customizable option. */
export type CustomizableFileOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableFileOption'
  /** Option ID. */
  option_id?: Maybe<Scalars['Int']>
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>
  /** An object that defines a file value. */
  value?: Maybe<CustomizableFileValue>
}

/** CustomizableFileValue defines the price and sku of a product whose page contains a customized file picker. */
export type CustomizableFileValue = {
  __typename?: 'CustomizableFileValue'
  /** The file extension to accept. */
  file_extension?: Maybe<Scalars['String']>
  /** The maximum width of an image. */
  image_size_x?: Maybe<Scalars['Int']>
  /** The maximum height of an image. */
  image_size_y?: Maybe<Scalars['Int']>
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>
}

/** CustomizableMultipleOption contains information about a multiselect that is defined as part of a customizable option. */
export type CustomizableMultipleOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableMultipleOption'
  /** Option ID. */
  option_id?: Maybe<Scalars['Int']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>
  /** An array that defines the set of options for a multiselect. */
  value?: Maybe<Array<Maybe<CustomizableMultipleValue>>>
}

/** CustomizableMultipleValue defines the price and sku of a product whose page contains a customized multiselect. */
export type CustomizableMultipleValue = {
  __typename?: 'CustomizableMultipleValue'
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>
}

export type CustomizableOptionInput = {
  id: Scalars['Int']
  value_string: Scalars['String']
}

/** The CustomizableOptionInterface contains basic information about a customizable option. It can be implemented by several types of configurable options. */
export type CustomizableOptionInterface = {
  /** Option ID. */
  option_id?: Maybe<Scalars['Int']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>
}

/** CustomizableProductInterface contains information about customizable product options. */
export type CustomizableProductInterface = {
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
}

/** CustomizableRadioOption contains information about a set of radio buttons that are defined as part of a customizable option. */
export type CustomizableRadioOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableRadioOption'
  /** Option ID. */
  option_id?: Maybe<Scalars['Int']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>
  /** An array that defines a set of radio buttons. */
  value?: Maybe<Array<Maybe<CustomizableRadioValue>>>
}

/** CustomizableRadioValue defines the price and sku of a product whose page contains a customized set of radio buttons. */
export type CustomizableRadioValue = {
  __typename?: 'CustomizableRadioValue'
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>
  /** The order in which the radio button is displayed. */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>
}

export type DeletePaymentTokenOutput = {
  __typename?: 'DeletePaymentTokenOutput'
  customerPaymentTokens?: Maybe<CustomerPaymentTokens>
  result: Scalars['Boolean']
}

/** Defines an individual discount. A discount can be applied to the cart as a whole or to an item. */
export type Discount = {
  __typename?: 'Discount'
  /** The amount of the discount */
  amount: Money
  /** A description of the discount */
  label: Scalars['String']
}

/** Downloadable Cart Item */
export type DownloadableCartItem = CartItemInterface & {
  __typename?: 'DownloadableCartItem'
  customizable_options?: Maybe<Array<Maybe<SelectedCustomizableOption>>>
  id: Scalars['String']
  /** Cart item attributes */
  item_attributes?: Maybe<CartItemAttributes>
  /** An array containing information about the links for the added to cart downloadable product */
  links?: Maybe<Array<Maybe<DownloadableProductLinks>>>
  prices?: Maybe<CartItemPrices>
  product: ProductInterface
  quantity: Scalars['Float']
  /** DownloadableProductSamples defines characteristics of a downloadable product */
  samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>
}

export const DownloadableFileTypeEnum = {
  File: 'FILE',
  Url: 'URL',
} as const

export type DownloadableFileTypeEnum = typeof DownloadableFileTypeEnum[keyof typeof DownloadableFileTypeEnum]
/** DownloadableProduct defines a product that the customer downloads */
export type DownloadableProduct = CustomizableProductInterface &
  ProductInterface & {
    __typename?: 'DownloadableProduct'
    a_z?: Maybe<Scalars['Int']>
    activity?: Maybe<Scalars['String']>
    /** Advisor Tags based on advisor_tags attribute */
    advisor_tags?: Maybe<Array<Maybe<Scalars['String']>>>
    allow_credit_range?: Maybe<Scalars['Int']>
    /** The attribute set assigned to the product. */
    attribute_set_id?: Maybe<Scalars['Int']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    category_gear?: Maybe<Scalars['String']>
    climate?: Maybe<Scalars['String']>
    collar?: Maybe<Scalars['String']>
    color?: Maybe<Scalars['Int']>
    content_left_both?: Maybe<Scalars['String']>
    content_left_patient?: Maybe<Scalars['String']>
    content_left_professional?: Maybe<Scalars['String']>
    content_left_public?: Maybe<Scalars['String']>
    content_right_both?: Maybe<Scalars['String']>
    content_right_patient?: Maybe<Scalars['String']>
    content_right_professional?: Maybe<Scalars['String']>
    content_right_public?: Maybe<Scalars['String']>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was created. */
    created_at?: Maybe<Scalars['String']>
    credit_amount?: Maybe<Scalars['Float']>
    credit_rate?: Maybe<Scalars['Float']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    doc_clinical_trials?: Maybe<Scalars['String']>
    doc_patient_education?: Maybe<Scalars['String']>
    doc_tech_sheet?: Maybe<Scalars['String']>
    /** An array containing information about the links for this downloadable product */
    downloadable_product_links?: Maybe<Array<Maybe<DownloadableProductLinks>>>
    /** An array containing information about samples of this downloadable product. */
    downloadable_product_samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>
    eco_collection?: Maybe<Scalars['Int']>
    erin_recommends?: Maybe<Scalars['Int']>
    features_bags?: Maybe<Scalars['String']>
    format?: Maybe<Scalars['Int']>
    gender?: Maybe<Scalars['String']>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']>
    heat_sensitive?: Maybe<Scalars['Int']>
    /** The ID number assigned to the product. */
    id?: Maybe<Scalars['Int']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** A value of 1 indicates that each link in the array must be purchased separately */
    links_purchased_separately?: Maybe<Scalars['Int']>
    /** The heading above the list of downloadable products */
    links_title?: Maybe<Scalars['String']>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']>
    material?: Maybe<Scalars['String']>
    max_credit?: Maybe<Scalars['Float']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']>
    min_credit?: Maybe<Scalars['Float']>
    /** Store Credit product attributes */
    mp_store_credit_attributes?: Maybe<Array<Maybe<StoreCreditProductAttributes>>>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']>
    natural_product_number?: Maybe<Scalars['String']>
    new?: Maybe<Scalars['Int']>
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    new_from_date?: Maybe<Scalars['String']>
    /** The end date for new product listings. */
    new_to_date?: Maybe<Scalars['String']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']>
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']>
    out_of_stock_message?: Maybe<Scalars['String']>
    pattern?: Maybe<Scalars['String']>
    performance_fabric?: Maybe<Scalars['Int']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    /** Product Form based on product_form attribute */
    product_form?: Maybe<Scalars['String']>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    /** One of simple, virtual, bundle, downloadable, grouped, or configurable. */
    product_type_id?: Maybe<Scalars['String']>
    /** Prop65 Message */
    prop65_message?: Maybe<Scalars['String']>
    prop_sixty_five?: Maybe<Scalars['Int']>
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    sale?: Maybe<Scalars['Int']>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size?: Maybe<Scalars['Int']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']>
    sleeve?: Maybe<Scalars['String']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /** The beginning date that a product has a special price. */
    special_from_date?: Maybe<Scalars['String']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']>
    /** Specialty based on specialty attribute */
    specialty?: Maybe<Array<Maybe<Scalars['String']>>>
    /** Stock Item Configuration */
    stock_configuration?: Maybe<StockItemConfiguration>
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    strap_bags?: Maybe<Scalars['String']>
    style_bags?: Maybe<Scalars['String']>
    style_bottom?: Maybe<Scalars['String']>
    style_general?: Maybe<Scalars['String']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was updated. */
    updated_at?: Maybe<Scalars['String']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
  }

export type DownloadableProductCartItemInput = {
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>
  data: CartItemInput
  downloadable_product_links?: Maybe<Array<Maybe<DownloadableProductLinksInput>>>
}

/** DownloadableProductLinks defines characteristics of a downloadable product */
export type DownloadableProductLinks = {
  __typename?: 'DownloadableProductLinks'
  /** @deprecated This information should not be exposed on frontend */
  id?: Maybe<Scalars['Int']>
  /** @deprecated This information should not be exposed on frontend */
  is_shareable?: Maybe<Scalars['Boolean']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  link_type?: Maybe<DownloadableFileTypeEnum>
  /** @deprecated This information should not be exposed on frontend */
  number_of_downloads?: Maybe<Scalars['Int']>
  /** The price of the downloadable product */
  price?: Maybe<Scalars['Float']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_file?: Maybe<Scalars['String']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_type?: Maybe<DownloadableFileTypeEnum>
  /** URL to the downloadable sample */
  sample_url?: Maybe<Scalars['String']>
  /** A number indicating the sort order */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name of the link */
  title?: Maybe<Scalars['String']>
}

export type DownloadableProductLinksInput = {
  link_id: Scalars['Int']
}

/** DownloadableProductSamples defines characteristics of a downloadable product */
export type DownloadableProductSamples = {
  __typename?: 'DownloadableProductSamples'
  /** @deprecated This information should not be exposed on frontend */
  id?: Maybe<Scalars['Int']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_file?: Maybe<Scalars['String']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_type?: Maybe<DownloadableFileTypeEnum>
  /** URL to the downloadable sample */
  sample_url?: Maybe<Scalars['String']>
  /** A number indicating the sort order */
  sort_order?: Maybe<Scalars['Int']>
  /** The display name of the sample */
  title?: Maybe<Scalars['String']>
}

/** EntityUrl is an output object containing the `id`, `relative_url`, and `type` attributes */
export type EntityUrl = {
  __typename?: 'EntityUrl'
  /** @deprecated The canonical_url field is deprecated, use relative_url instead. */
  canonical_url?: Maybe<Scalars['String']>
  /** The ID assigned to the object associated with the specified url. This could be a product ID, category ID, or page ID. */
  id?: Maybe<Scalars['Int']>
  /** 301 or 302 HTTP code for url permanent or temporary redirect or 0 for the 200 no redirect */
  redirectCode?: Maybe<Scalars['Int']>
  /** The internal relative URL. If the specified  url is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>
}

export type ExchangeRate = {
  __typename?: 'ExchangeRate'
  currency_to?: Maybe<Scalars['String']>
  rate?: Maybe<Scalars['Float']>
}

/** Defines a filter that matches the input exactly. */
export type FilterEqualTypeInput = {
  /** A string to filter on */
  eq?: Maybe<Scalars['String']>
  /** An array of values to filter on */
  in?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** Defines a filter that performs a fuzzy search. */
export type FilterMatchTypeInput = {
  /** One or more words to filter on */
  match?: Maybe<Scalars['String']>
}

/** Defines a filter that matches a range of values, such as prices or dates. */
export type FilterRangeTypeInput = {
  /** The beginning of the range */
  from?: Maybe<Scalars['String']>
  /** The end of the range */
  to?: Maybe<Scalars['String']>
}

/** FilterTypeInput specifies which action will be performed in a query */
export type FilterTypeInput = {
  /** Equals */
  eq?: Maybe<Scalars['String']>
  finset?: Maybe<Array<Maybe<Scalars['String']>>>
  /** From. Must be used with 'to' */
  from?: Maybe<Scalars['String']>
  /** Greater than */
  gt?: Maybe<Scalars['String']>
  /** Greater than or equal to */
  gteq?: Maybe<Scalars['String']>
  /** In. The value can contain a set of comma-separated values */
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Like. The specified value can contain % (percent signs) to allow matching of 0 or more characters */
  like?: Maybe<Scalars['String']>
  /** Less than */
  lt?: Maybe<Scalars['String']>
  /** Less than or equal to */
  lteq?: Maybe<Scalars['String']>
  /** More than or equal to */
  moreq?: Maybe<Scalars['String']>
  /** Not equal to */
  neq?: Maybe<Scalars['String']>
  /** Not in. The value can contain a set of comma-separated values */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Not null */
  notnull?: Maybe<Scalars['String']>
  /** Is null */
  null?: Maybe<Scalars['String']>
  /** To. Must be used with 'from' */
  to?: Maybe<Scalars['String']>
}

/** A single FPT that can be applied to a product price. */
export type FixedProductTax = {
  __typename?: 'FixedProductTax'
  /** Amount of the FPT as a money object. */
  amount?: Maybe<Money>
  /** The label assigned to the FPT to be displayed on the frontend. */
  label?: Maybe<Scalars['String']>
}

/** This enumeration display settings for the fixed product tax */
export const FixedProductTaxDisplaySettings = {
  /** The displayed price does not include the FPT amount. The values of ProductPrice.fixed_product_taxes and the price including the FPT are displayed separately. This value corresponds to 'Excluding FPT, Including FPT description and final price' */
  ExcludeFptAndIncludeWithDetails: 'EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS',
  /** The displayed price does not include the FPT amount. The values from ProductPrice.fixed_product_taxes are not displayed. This value corresponds to 'Excluding FPT' */
  ExcludeFptWithoutDetails: 'EXCLUDE_FPT_WITHOUT_DETAILS',
  /** The FPT feature is not enabled. You can omit  ProductPrice.fixed_product_taxes from your query */
  FptDisabled: 'FPT_DISABLED',
  /** The displayed price includes the FPT amount without displaying the ProductPrice.fixed_product_taxes values. This value corresponds to 'Including FPT only' */
  IncludeFptWithoutDetails: 'INCLUDE_FPT_WITHOUT_DETAILS',
  /** The displayed price includes the FPT amount while displaying the values of ProductPrice.fixed_product_taxes separately. This value corresponds to 'Including FPT and FPT description' */
  IncludeFptWithDetails: 'INCLUDE_FPT_WITH_DETAILS',
} as const

export type FixedProductTaxDisplaySettings = typeof FixedProductTaxDisplaySettings[keyof typeof FixedProductTaxDisplaySettings]
export type GenerateFlexJsTokenOutput = {
  __typename?: 'GenerateFlexJsTokenOutput'
  key_id?: Maybe<Scalars['String']>
  key_store?: Maybe<Scalars['String']>
}

/** GroupedProduct defines a grouped product */
export type GroupedProduct = PhysicalProductInterface &
  ProductInterface & {
    __typename?: 'GroupedProduct'
    a_z?: Maybe<Scalars['Int']>
    activity?: Maybe<Scalars['String']>
    /** Advisor Tags based on advisor_tags attribute */
    advisor_tags?: Maybe<Array<Maybe<Scalars['String']>>>
    allow_credit_range?: Maybe<Scalars['Int']>
    /** The attribute set assigned to the product. */
    attribute_set_id?: Maybe<Scalars['Int']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    category_gear?: Maybe<Scalars['String']>
    climate?: Maybe<Scalars['String']>
    collar?: Maybe<Scalars['String']>
    color?: Maybe<Scalars['Int']>
    content_left_both?: Maybe<Scalars['String']>
    content_left_patient?: Maybe<Scalars['String']>
    content_left_professional?: Maybe<Scalars['String']>
    content_left_public?: Maybe<Scalars['String']>
    content_right_both?: Maybe<Scalars['String']>
    content_right_patient?: Maybe<Scalars['String']>
    content_right_professional?: Maybe<Scalars['String']>
    content_right_public?: Maybe<Scalars['String']>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was created. */
    created_at?: Maybe<Scalars['String']>
    credit_amount?: Maybe<Scalars['Float']>
    credit_rate?: Maybe<Scalars['Float']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    doc_clinical_trials?: Maybe<Scalars['String']>
    doc_patient_education?: Maybe<Scalars['String']>
    doc_tech_sheet?: Maybe<Scalars['String']>
    eco_collection?: Maybe<Scalars['Int']>
    erin_recommends?: Maybe<Scalars['Int']>
    features_bags?: Maybe<Scalars['String']>
    format?: Maybe<Scalars['Int']>
    gender?: Maybe<Scalars['String']>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']>
    heat_sensitive?: Maybe<Scalars['Int']>
    /** The ID number assigned to the product. */
    id?: Maybe<Scalars['Int']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** An array containing grouped product items */
    items?: Maybe<Array<Maybe<GroupedProductItem>>>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']>
    material?: Maybe<Scalars['String']>
    max_credit?: Maybe<Scalars['Float']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']>
    min_credit?: Maybe<Scalars['Float']>
    /** Store Credit product attributes */
    mp_store_credit_attributes?: Maybe<Array<Maybe<StoreCreditProductAttributes>>>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']>
    natural_product_number?: Maybe<Scalars['String']>
    new?: Maybe<Scalars['Int']>
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    new_from_date?: Maybe<Scalars['String']>
    /** The end date for new product listings. */
    new_to_date?: Maybe<Scalars['String']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']>
    out_of_stock_message?: Maybe<Scalars['String']>
    pattern?: Maybe<Scalars['String']>
    performance_fabric?: Maybe<Scalars['Int']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    /** Product Form based on product_form attribute */
    product_form?: Maybe<Scalars['String']>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    /** One of simple, virtual, bundle, downloadable, grouped, or configurable. */
    product_type_id?: Maybe<Scalars['String']>
    /** Prop65 Message */
    prop65_message?: Maybe<Scalars['String']>
    prop_sixty_five?: Maybe<Scalars['Int']>
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    sale?: Maybe<Scalars['Int']>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size?: Maybe<Scalars['Int']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']>
    sleeve?: Maybe<Scalars['String']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /** The beginning date that a product has a special price. */
    special_from_date?: Maybe<Scalars['String']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']>
    /** Specialty based on specialty attribute */
    specialty?: Maybe<Array<Maybe<Scalars['String']>>>
    /** Stock Item Configuration */
    stock_configuration?: Maybe<StockItemConfiguration>
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    strap_bags?: Maybe<Scalars['String']>
    style_bags?: Maybe<Scalars['String']>
    style_bottom?: Maybe<Scalars['String']>
    style_general?: Maybe<Scalars['String']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was updated. */
    updated_at?: Maybe<Scalars['String']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<Scalars['Float']>
  }

/** GroupedProductItem contains information about an individual grouped product item */
export type GroupedProductItem = {
  __typename?: 'GroupedProductItem'
  /** The relative position of this item compared to the other group items */
  position?: Maybe<Scalars['Int']>
  /** The ProductInterface object, which contains details about this product option */
  product?: Maybe<ProductInterface>
  /** The quantity of this grouped product item */
  qty?: Maybe<Scalars['Float']>
}

/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payments Pro Hosted Solution payment method. */
export type HostedProInput = {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']
  /** The relative URL of the final confirmation page that PayPal will redirect to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']
}

export type HostedProUrl = {
  __typename?: 'HostedProUrl'
  /** Secure Url generated by PayPal */
  secure_form_url?: Maybe<Scalars['String']>
}

/** The required input to request the secure URL for Payments Pro Hosted Solution payment. */
export type HostedProUrlInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String']
}

/** The object details of target path parameters */
export type HttpQueryParameter = {
  __typename?: 'HttpQueryParameter'
  /** Parameter name */
  name?: Maybe<Scalars['String']>
  /** Parameter value */
  value?: Maybe<Scalars['String']>
}

export type ImageSwatchData = SwatchDataInterface & {
  __typename?: 'ImageSwatchData'
  /** Thumbnail swatch image URL */
  thumbnail?: Maybe<Scalars['String']>
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']>
}

export type IsEmailAvailableOutput = {
  __typename?: 'IsEmailAvailableOutput'
  /** Is email availabel value */
  is_email_available?: Maybe<Scalars['Boolean']>
}

export type LayerFilter = {
  __typename?: 'LayerFilter'
  /**
   * Array of filter items.
   * @deprecated Use Aggregation.options instead.
   */
  filter_items?: Maybe<Array<Maybe<LayerFilterItemInterface>>>
  /**
   * Count of filter items in filter group.
   * @deprecated Use Aggregation.count instead.
   */
  filter_items_count?: Maybe<Scalars['Int']>
  /**
   * Layered navigation filter name.
   * @deprecated Use Aggregation.label instead.
   */
  name?: Maybe<Scalars['String']>
  /**
   * Request variable name for filter query.
   * @deprecated Use Aggregation.attribute_code instead.
   */
  request_var?: Maybe<Scalars['String']>
}

export type LayerFilterItem = LayerFilterItemInterface & {
  __typename?: 'LayerFilterItem'
  /**
   * Count of items by filter.
   * @deprecated Use AggregationOption.count instead.
   */
  items_count?: Maybe<Scalars['Int']>
  /**
   * Filter label.
   * @deprecated Use AggregationOption.label instead.
   */
  label?: Maybe<Scalars['String']>
  /**
   * Value for filter request variable to be used in query.
   * @deprecated Use AggregationOption.value instead.
   */
  value_string?: Maybe<Scalars['String']>
}

export type LayerFilterItemInterface = {
  /**
   * Count of items by filter.
   * @deprecated Use AggregationOption.count instead.
   */
  items_count?: Maybe<Scalars['Int']>
  /**
   * Filter label.
   * @deprecated Use AggregationOption.label instead.
   */
  label?: Maybe<Scalars['String']>
  /**
   * Value for filter request variable to be used in query.
   * @deprecated Use AggregationOption.value instead.
   */
  value_string?: Maybe<Scalars['String']>
}

/** MediaGalleryEntry defines characteristics about images and videos associated with a specific product. */
export type MediaGalleryEntry = {
  __typename?: 'MediaGalleryEntry'
  /** Contains a ProductMediaGalleryEntriesContent object. */
  content?: Maybe<ProductMediaGalleryEntriesContent>
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>
  /** The path of the image on the server. */
  file?: Maybe<Scalars['String']>
  /** The identifier assigned to the object. */
  id?: Maybe<Scalars['Int']>
  /** The alt text displayed on the UI when the user points to the image. */
  label?: Maybe<Scalars['String']>
  /** image or video. */
  media_type?: Maybe<Scalars['String']>
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>
  /** Array of image types. It can have the following values: image, small_image, thumbnail. */
  types?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Contains a ProductMediaGalleryEntriesVideoContent object. */
  video_content?: Maybe<ProductMediaGalleryEntriesVideoContent>
}

/** Contains basic information about a product image or video. */
export type MediaGalleryInterface = {
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>
}

/** A Money object defines a monetary value, including a numeric value and a currency code. */
export type Money = {
  __typename?: 'Money'
  /** A three-letter currency code, such as USD or EUR */
  currency?: Maybe<CurrencyEnum>
  /** A number expressing a monetary value */
  value?: Maybe<Scalars['Float']>
}

export type MpStoreCreditCustomerTransaction = {
  __typename?: 'MpStoreCreditCustomerTransaction'
  /** Action */
  action?: Maybe<Scalars['String']>
  /** Admin Note */
  admin_note?: Maybe<Scalars['String']>
  /** Amount */
  amount?: Maybe<Scalars['Float']>
  /** Balance */
  balance?: Maybe<Scalars['Float']>
  /** Created At */
  created_at?: Maybe<Scalars['String']>
  /** Customer Id */
  customer_id?: Maybe<Scalars['Int']>
  /** Customer Note */
  customer_note?: Maybe<Scalars['String']>
  /** Order Id */
  order_id?: Maybe<Scalars['Int']>
  /** Status */
  status?: Maybe<Scalars['Int']>
  /** Title */
  title?: Maybe<Scalars['String']>
  /** Transaction Id */
  transaction_id?: Maybe<Scalars['Int']>
}

export type MpStoreCreditCustomerTransactionFilterInput = {
  /** Action */
  action?: Maybe<FilterTypeInput>
  /** Admin Note */
  admin_note?: Maybe<FilterTypeInput>
  /** Amount */
  amount?: Maybe<FilterTypeInput>
  /** Balance */
  balance?: Maybe<FilterTypeInput>
  /** Created At */
  created_at?: Maybe<FilterTypeInput>
  /** Customer Id */
  customer_id?: Maybe<FilterTypeInput>
  /** Customer Note */
  customer_note?: Maybe<FilterTypeInput>
  /** Order Id */
  order_id?: Maybe<FilterTypeInput>
  /** Status */
  status?: Maybe<FilterTypeInput>
  /** Title */
  title?: Maybe<FilterTypeInput>
  /** Transaction Id */
  transaction_id?: Maybe<FilterTypeInput>
}

export type MpStoreCreditCustomerTransactionListOutput = {
  __typename?: 'MpStoreCreditCustomerTransactionListOutput'
  /** MpStoreCreditCustomerTransaction data is satisfied */
  items?: Maybe<Array<Maybe<MpStoreCreditCustomerTransaction>>>
  /** The total number of results returned when a query is applied */
  total_count?: Maybe<Scalars['Int']>
}

export type MpStoreCreditCustomersOutput = {
  __typename?: 'MpStoreCreditCustomersOutput'
  /** Customer Id */
  customer_id?: Maybe<Scalars['Int']>
  /** Mp Credit Balance */
  mp_credit_balance?: Maybe<Scalars['Float']>
  /** Mp Credit Notification */
  mp_credit_notification?: Maybe<Scalars['Int']>
  /** The query searches for store credit customer. */
  transactions?: Maybe<MpStoreCreditCustomerTransactionListOutput>
}

export type MpStoreCreditCustomersOutputTransactionsArgs = {
  currentPage?: Maybe<Scalars['Int']>
  filter?: Maybe<MpStoreCreditCustomerTransactionFilterInput>
  pageSize?: Maybe<Scalars['Int']>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Spending */
  MpStoreCreditCustomerSpending?: Maybe<Array<Maybe<TotalSegmentsOutput>>>
  /** CreditUpdate */
  MpStoreCreditCustomerUpdate?: Maybe<Scalars['String']>
  addBundleProductsToCart?: Maybe<AddBundleProductsToCartOutput>
  addConfigurableProductsToCart?: Maybe<AddConfigurableProductsToCartOutput>
  addDownloadableProductsToCart?: Maybe<AddDownloadableProductsToCartOutput>
  addSimpleProductsToCart?: Maybe<AddSimpleProductsToCartOutput>
  addVirtualProductsToCart?: Maybe<AddVirtualProductsToCartOutput>
  applyCouponToCart?: Maybe<ApplyCouponToCartOutput>
  /** Changes the password for the logged-in customer */
  changeCustomerPassword?: Maybe<Customer>
  /** Create customer account */
  createCustomer?: Maybe<CustomerOutput>
  /** Create customer address */
  createCustomerAddress?: Maybe<CustomerAddress>
  /** Creates an empty shopping cart for a guest or logged in user */
  createEmptyCart?: Maybe<Scalars['String']>
  /** Initiates a transaction and receives a token. Use this mutation for Payflow Pro and Payments Pro payment methods */
  createPayflowProToken?: Maybe<CreatePayflowProTokenOutput>
  /** Initiates an Express Checkout transaction and receives a token. Use this mutation for Express Checkout and Payments Standard payment methods. */
  createPaypalExpressToken?: Maybe<PaypalExpressTokenOutput>
  /** Create a new stored card */
  createTokenBaseCard?: Maybe<TokenBaseCard>
  /** Delete customer address */
  deleteCustomerAddress?: Maybe<Scalars['Boolean']>
  /** Delete a customer payment token */
  deletePaymentToken?: Maybe<DeletePaymentTokenOutput>
  /** Delete a stored card */
  deleteTokenBaseCard?: Maybe<Scalars['Boolean']>
  /** Retrieve the customer token */
  generateCustomerToken?: Maybe<CustomerToken>
  generateFlexJsToken?: Maybe<GenerateFlexJsTokenOutput>
  /** Handles payment response and saves payment in Quote. Use this mutations for Payflow Pro and Payments Pro payment methods. */
  handlePayflowProResponse?: Maybe<PayflowProResponseOutput>
  /** Merges the source cart into the destination cart */
  mergeCarts: Cart
  placeOrder?: Maybe<PlaceOrderOutput>
  removeCouponFromCart?: Maybe<RemoveCouponFromCartOutput>
  removeItemFromCart?: Maybe<RemoveItemFromCartOutput>
  /** Revoke the customer token */
  revokeCustomerToken?: Maybe<RevokeCustomerTokenOutput>
  /** Recommends Product by Sending Single/Multiple Email */
  sendEmailToFriend?: Maybe<SendEmailToFriendOutput>
  setBillingAddressOnCart?: Maybe<SetBillingAddressOnCartOutput>
  setCartAttributes?: Maybe<SetCartAttributesOnCartOutput>
  setDispensaryPromoCodeOnCart?: Maybe<SetDispensaryPromoCodeOnCartOutput>
  setDropShipOnCart?: Maybe<SetDropShipOnCartOutput>
  setEScriptCampaignOnCart?: Maybe<SetWwOnCartOutput>
  setGuestEmailOnCart?: Maybe<SetGuestEmailOnCartOutput>
  setHoldUntilMondayOnCart?: Maybe<SetHoldUntilMondayOnCartOutput>
  setNetSuiteCustomerIdOnCart?: Maybe<SetNetSuiteCustomerIdOnCartOutput>
  /** @deprecated Should use setPaymentMethodOnCart and placeOrder mutations in single request. */
  setPaymentMethodAndPlaceOrder?: Maybe<PlaceOrderOutput>
  setPaymentMethodOnCart?: Maybe<SetPaymentMethodOnCartOutput>
  setPractitionerCodeOnCart?: Maybe<SetPractitionerCodeOnCartOutput>
  setReferrerUriOnCart?: Maybe<SetReferrerUriOnCartOutput>
  setShipDateOnCart?: Maybe<SetShipDateOnCartOutput>
  setShippingAddressesOnCart?: Maybe<SetShippingAddressesOnCartOutput>
  setShippingMethodsOnCart?: Maybe<SetShippingMethodsOnCartOutput>
  setWwIdOnCart?: Maybe<SetWwOnCartOutput>
  setWwPracticeIdOnCart?: Maybe<SetWwOnCartOutput>
  setWwPractitionerIdOnCart?: Maybe<SetWwOnCartOutput>
  setWwStoreIdOnCart?: Maybe<SetWwOnCartOutput>
  updateCartItems?: Maybe<UpdateCartItemsOutput>
  /** Update the customer's personal information */
  updateCustomer?: Maybe<CustomerOutput>
  /** Update customer address */
  updateCustomerAddress?: Maybe<CustomerAddress>
  /** Update an existing stored card */
  updateTokenBaseCard?: Maybe<TokenBaseCard>
}

export type MutationMpStoreCreditCustomerSpendingArgs = {
  amount: Scalars['Float']
  cart_id: Scalars['String']
}

export type MutationMpStoreCreditCustomerUpdateArgs = {
  amount: Scalars['Float']
}

export type MutationAddBundleProductsToCartArgs = {
  input?: Maybe<AddBundleProductsToCartInput>
}

export type MutationAddConfigurableProductsToCartArgs = {
  input?: Maybe<AddConfigurableProductsToCartInput>
}

export type MutationAddDownloadableProductsToCartArgs = {
  input?: Maybe<AddDownloadableProductsToCartInput>
}

export type MutationAddSimpleProductsToCartArgs = {
  input?: Maybe<AddSimpleProductsToCartInput>
}

export type MutationAddVirtualProductsToCartArgs = {
  input?: Maybe<AddVirtualProductsToCartInput>
}

export type MutationApplyCouponToCartArgs = {
  input?: Maybe<ApplyCouponToCartInput>
}

export type MutationChangeCustomerPasswordArgs = {
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
}

export type MutationCreateCustomerArgs = {
  input: CustomerInput
}

export type MutationCreateCustomerAddressArgs = {
  input: CustomerAddressInput
}

export type MutationCreateEmptyCartArgs = {
  input?: Maybe<CreateEmptyCartInput>
}

export type MutationCreatePayflowProTokenArgs = {
  input: PayflowProTokenInput
}

export type MutationCreatePaypalExpressTokenArgs = {
  input: PaypalExpressTokenInput
}

export type MutationCreateTokenBaseCardArgs = {
  input: TokenBaseCardCreateInput
}

export type MutationDeleteCustomerAddressArgs = {
  id: Scalars['Int']
}

export type MutationDeletePaymentTokenArgs = {
  public_hash: Scalars['String']
}

export type MutationDeleteTokenBaseCardArgs = {
  hash: Scalars['String']
}

export type MutationGenerateCustomerTokenArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationHandlePayflowProResponseArgs = {
  input: PayflowProResponseInput
}

export type MutationMergeCartsArgs = {
  destination_cart_id: Scalars['String']
  source_cart_id: Scalars['String']
}

export type MutationPlaceOrderArgs = {
  input?: Maybe<PlaceOrderInput>
}

export type MutationRemoveCouponFromCartArgs = {
  input?: Maybe<RemoveCouponFromCartInput>
}

export type MutationRemoveItemFromCartArgs = {
  input?: Maybe<RemoveItemFromCartInput>
}

export type MutationSendEmailToFriendArgs = {
  input?: Maybe<SendEmailToFriendInput>
}

export type MutationSetBillingAddressOnCartArgs = {
  input?: Maybe<SetBillingAddressOnCartInput>
}

export type MutationSetCartAttributesArgs = {
  input?: Maybe<SetCartAttributesOnCartInput>
}

export type MutationSetDispensaryPromoCodeOnCartArgs = {
  input?: Maybe<SetDispensaryPromoCodeOnCartInput>
}

export type MutationSetDropShipOnCartArgs = {
  input?: Maybe<SetDropShipOnCartInput>
}

export type MutationSetEScriptCampaignOnCartArgs = {
  input?: Maybe<SetEScriptCampaignOnCartInput>
}

export type MutationSetGuestEmailOnCartArgs = {
  input?: Maybe<SetGuestEmailOnCartInput>
}

export type MutationSetHoldUntilMondayOnCartArgs = {
  input?: Maybe<SetHoldUntilMondayOnCartInput>
}

export type MutationSetNetSuiteCustomerIdOnCartArgs = {
  input?: Maybe<SetNetSuiteCustomerIdOnCartInput>
}

export type MutationSetPaymentMethodAndPlaceOrderArgs = {
  input?: Maybe<SetPaymentMethodAndPlaceOrderInput>
}

export type MutationSetPaymentMethodOnCartArgs = {
  input?: Maybe<SetPaymentMethodOnCartInput>
}

export type MutationSetPractitionerCodeOnCartArgs = {
  input?: Maybe<SetPractitionerCodeOnCartInput>
}

export type MutationSetReferrerUriOnCartArgs = {
  input?: Maybe<SetReferrerUriOnCartInput>
}

export type MutationSetShipDateOnCartArgs = {
  input?: Maybe<SetShipDateOnCartInput>
}

export type MutationSetShippingAddressesOnCartArgs = {
  input?: Maybe<SetShippingAddressesOnCartInput>
}

export type MutationSetShippingMethodsOnCartArgs = {
  input?: Maybe<SetShippingMethodsOnCartInput>
}

export type MutationSetWwIdOnCartArgs = {
  input?: Maybe<SetWwIdOnCartInput>
}

export type MutationSetWwPracticeIdOnCartArgs = {
  input?: Maybe<SetWwPracticeIdOnCartInput>
}

export type MutationSetWwPractitionerIdOnCartArgs = {
  input?: Maybe<SetWwPractitionerIdOnCartInput>
}

export type MutationSetWwStoreIdOnCartArgs = {
  input?: Maybe<SetWwStoreIdOnCartInput>
}

export type MutationUpdateCartItemsArgs = {
  input?: Maybe<UpdateCartItemsInput>
}

export type MutationUpdateCustomerArgs = {
  input: CustomerInput
}

export type MutationUpdateCustomerAddressArgs = {
  id: Scalars['Int']
  input?: Maybe<CustomerAddressInput>
}

export type MutationUpdateTokenBaseCardArgs = {
  input: TokenBaseCardUpdateInput
}

export type Order = {
  __typename?: 'Order'
  /** @deprecated The order_id field is deprecated, use order_number instead. */
  order_id?: Maybe<Scalars['String']>
  order_number: Scalars['String']
}

/** Required input for Payflow Express Checkout payments */
export type PayflowExpressInput = {
  /** The unique ID of the PayPal user */
  payer_id: Scalars['String']
  /** The token returned by the createPaypalExpressToken mutation */
  token: Scalars['String']
}

/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkInput = {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']
  /** The relative URL of the transaction error page that PayPal will redirect to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String']
  /** The relative URL of the order confirmation page that PayPal will redirect to when the payment is successful and additional confirmation is not needed. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']
}

/** Mode for payment: TEST or LIVE. Applies to Payflow Link and Payments Advanced payment methods. */
export const PayflowLinkMode = {
  Live: 'LIVE',
  Test: 'TEST',
} as const

export type PayflowLinkMode = typeof PayflowLinkMode[keyof typeof PayflowLinkMode]
/** Contains information used to generate PayPal iframe for transaction. Applies to Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkToken = {
  __typename?: 'PayflowLinkToken'
  /** Mode for Payflow transaction */
  mode?: Maybe<PayflowLinkMode>
  /** PayPal URL used for requesting Payflow form */
  paypal_url?: Maybe<Scalars['String']>
  /** Secure token generated by PayPal */
  secure_token?: Maybe<Scalars['String']>
  /** Secure token ID generated by PayPal */
  secure_token_id?: Maybe<Scalars['String']>
}

/** Input required to fetch payment token information for Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkTokenInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String']
}

/** Required input for Payflow Pro and Payments Pro payment methods. */
export type PayflowProInput = {
  /** Required input for credit card related information */
  cc_details: CreditCardDetailsInput
}

/** Input required to complete payment. Applies to Payflow Pro and Payments Pro payment methods. */
export type PayflowProResponseInput = {
  cart_id: Scalars['String']
  paypal_payload: Scalars['String']
}

export type PayflowProResponseOutput = {
  __typename?: 'PayflowProResponseOutput'
  cart: Cart
}

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type PayflowProToken = {
  __typename?: 'PayflowProToken'
  response_message: Scalars['String']
  result: Scalars['Int']
  result_code: Scalars['Int']
  secure_token: Scalars['String']
  secure_token_id: Scalars['String']
}

/** Input required to fetch payment token information for Payflow Pro and Payments Pro payment methods. */
export type PayflowProTokenInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String']
  /** A set of relative URLs that PayPal uses for callback. */
  urls: PayflowProUrlInput
}

/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Pro and Payment Pro payment methods. */
export type PayflowProUrlInput = {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']
  /** The relative URL of the transaction error page that PayPal will redirect to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String']
  /** The relative URL of the final confirmation page that PayPal will redirect to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']
}

export type PaymentMethodInput = {
  /** Payment method code */
  code: Scalars['String']
  /** Required input for PayPal Hosted pro payments */
  hosted_pro?: Maybe<HostedProInput>
  /** Required input for Payflow Express Checkout payments */
  payflow_express?: Maybe<PayflowExpressInput>
  /** Required input for PayPal Payflow Link and Payments Advanced payments */
  payflow_link?: Maybe<PayflowLinkInput>
  /** Required input type for PayPal Payflow Pro and Payment Pro payments */
  payflowpro?: Maybe<PayflowProInput>
  /** Required input for Express Checkout and Payments Standard payments */
  paypal_express?: Maybe<PaypalExpressInput>
  /** Purchase order number */
  purchase_order_number?: Maybe<Scalars['String']>
  /** TokenBase payment method input */
  tokenbase_data?: Maybe<TokenBaseCardPaymentInput>
}

/** The stored payment method available to the customer */
export type PaymentToken = {
  __typename?: 'PaymentToken'
  /** Stored account details */
  details?: Maybe<Scalars['String']>
  /** The payment method code associated with the token */
  payment_method_code: Scalars['String']
  /** The public hash of the token */
  public_hash: Scalars['String']
  type: PaymentTokenTypeEnum
}

/** The list of available payment token types */
export const PaymentTokenTypeEnum = {
  Account: 'account',
  Card: 'card',
} as const

export type PaymentTokenTypeEnum = typeof PaymentTokenTypeEnum[keyof typeof PaymentTokenTypeEnum]
/** Required input for Express Checkout and Payments Standard payments */
export type PaypalExpressInput = {
  /** The unique ID of the PayPal user */
  payer_id: Scalars['String']
  /** The token returned by the createPaypalExpressToken mutation */
  token: Scalars['String']
}

/** Deprecated: use type `PaypalExpressTokenOutput` instead */
export type PaypalExpressToken = {
  __typename?: 'PaypalExpressToken'
  /**
   * A set of URLs that allow the buyer to authorize payment and adjust checkout details
   * @deprecated Use field `paypal_urls` of type `PaypalExpressTokenOutput` instead
   */
  paypal_urls?: Maybe<PaypalExpressUrlList>
  /**
   * The token returned by PayPal
   * @deprecated Use field `token` of type `PaypalExpressTokenOutput` instead
   */
  token?: Maybe<Scalars['String']>
}

/** Defines the attributes required to receive a payment token for Express Checkout and Payments Standard payment methods. */
export type PaypalExpressTokenInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String']
  /** Payment method code */
  code: Scalars['String']
  /** Indicates whether the buyer selected the quick checkout button. The default value is false */
  express_button?: Maybe<Scalars['Boolean']>
  /** A set of relative URLs that PayPal uses in response to various actions during the authorization process */
  urls: PaypalExpressUrlsInput
  /** Indicates whether the buyer clicked the PayPal credit button. The default value is false */
  use_paypal_credit?: Maybe<Scalars['Boolean']>
}

/** Contains the token returned by PayPal and a set of URLs that allow the buyer to authorize payment and adjust checkout details. Applies to Express Checkout and Payments Standard payment methods. */
export type PaypalExpressTokenOutput = {
  __typename?: 'PaypalExpressTokenOutput'
  /** A set of URLs that allow the buyer to authorize payment and adjust checkout details */
  paypal_urls?: Maybe<PaypalExpressUrlList>
  /** The token returned by PayPal */
  token?: Maybe<Scalars['String']>
}

/** A set of URLs that allow the buyer to authorize payment and adjust checkout details for Express Checkout and Payments Standard transactions. */
export type PaypalExpressUrlList = {
  __typename?: 'PaypalExpressUrlList'
  /** The PayPal URL that allows the buyer to edit their checkout details */
  edit?: Maybe<Scalars['String']>
  /** The URL to the PayPal login page */
  start?: Maybe<Scalars['String']>
}

/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Express Checkout and Payments Standard payment methods. */
export type PaypalExpressUrlsInput = {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']
  /** The relative URL of the page that PayPal will redirect to when the payment has been put on hold for additional review. This condition mostly applies to ACH transactions, and is not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success_pending.html, the relative URL is paypal/action/success_pending.html. */
  pending_url?: Maybe<Scalars['String']>
  /** The relative URL of the final confirmation page that PayPal will redirect to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']
  /** The relative URL of the order confirmation page that PayPal will redirect to when the payment is successful and additional confirmation is not needed. Not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success.html, the relative URL is paypal/action/success.html. */
  success_url?: Maybe<Scalars['String']>
}

/** PhysicalProductInterface contains attributes specific to tangible products. */
export type PhysicalProductInterface = {
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>
}

export type PlaceOrderInput = {
  cart_id: Scalars['String']
}

export type PlaceOrderOutput = {
  __typename?: 'PlaceOrderOutput'
  order: Order
}

/** Price is deprecated, replaced by ProductPrice. The Price object defines the price of a product as well as any tax-related adjustments. */
export type Price = {
  __typename?: 'Price'
  /**
   * An array that provides information about tax, weee, or weee_tax adjustments.
   * @deprecated Price is deprecated, use ProductPrice.
   */
  adjustments?: Maybe<Array<Maybe<PriceAdjustment>>>
  /**
   * The price of a product plus a three-letter currency code.
   * @deprecated Price is deprecated, use ProductPrice.
   */
  amount?: Maybe<Money>
}

/** PriceAdjustment is deprecated. Taxes will be included or excluded in the price. The PricedAdjustment object defines the amount of money to apply as an adjustment, the type of adjustment to apply, and whether the item is included or excluded from the adjustment. */
export type PriceAdjustment = {
  __typename?: 'PriceAdjustment'
  /** The amount of the price adjustment and its currency code. */
  amount?: Maybe<Money>
  /**
   * Indicates whether the adjustment involves tax, weee, or weee_tax.
   * @deprecated PriceAdjustment is deprecated.
   */
  code?: Maybe<PriceAdjustmentCodesEnum>
  /**
   * Indicates whether the entity described by the code attribute is included or excluded from the adjustment.
   * @deprecated PriceAdjustment is deprecated.
   */
  description?: Maybe<PriceAdjustmentDescriptionEnum>
}

/** PriceAdjustment.code is deprecated. This enumeration contains values defined in modules other than the Catalog module. */
export const PriceAdjustmentCodesEnum = {
  Tax: 'TAX',
  Weee: 'WEEE',
  WeeeTax: 'WEEE_TAX',
} as const

export type PriceAdjustmentCodesEnum = typeof PriceAdjustmentCodesEnum[keyof typeof PriceAdjustmentCodesEnum]
/** PriceAdjustmentDescriptionEnum is deprecated. This enumeration states whether a price adjustment is included or excluded. */
export const PriceAdjustmentDescriptionEnum = {
  Excluded: 'EXCLUDED',
  Included: 'INCLUDED',
} as const

export type PriceAdjustmentDescriptionEnum = typeof PriceAdjustmentDescriptionEnum[keyof typeof PriceAdjustmentDescriptionEnum]
/** Price range for a product. If the product has a single price, the minimum and maximum price will be the same. */
export type PriceRange = {
  __typename?: 'PriceRange'
  /** The highest possible price for the product. */
  maximum_price?: Maybe<ProductPrice>
  /** The lowest possible price for the product. */
  minimum_price: ProductPrice
}

/** This enumeration the price type. */
export const PriceTypeEnum = {
  Dynamic: 'DYNAMIC',
  Fixed: 'FIXED',
  Percent: 'PERCENT',
} as const

export type PriceTypeEnum = typeof PriceTypeEnum[keyof typeof PriceTypeEnum]
/** This enumeration defines whether a bundle product's price is displayed as the lowest possible value or as a range. */
export const PriceViewEnum = {
  AsLowAs: 'AS_LOW_AS',
  PriceRange: 'PRICE_RANGE',
} as const

export type PriceViewEnum = typeof PriceViewEnum[keyof typeof PriceViewEnum]
/** ProductAttributeFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type ProductAttributeFilterInput = {
  /** Attribute label: A-Z */
  a_z?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Activity */
  activity?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Category Gear */
  category_gear?: Maybe<FilterEqualTypeInput>
  /** Filter product by category id */
  category_id?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Climate */
  climate?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Collar */
  collar?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Color */
  color?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Description */
  description?: Maybe<FilterMatchTypeInput>
  /** Attribute label: Eco Collection */
  eco_collection?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Erin Recommends */
  erin_recommends?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Features */
  features_bags?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Format */
  format?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Gender */
  gender?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Material */
  material?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Product Name */
  name?: Maybe<FilterMatchTypeInput>
  /** Attribute label: New */
  new?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Pattern */
  pattern?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Performance Fabric */
  performance_fabric?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Price */
  price?: Maybe<FilterRangeTypeInput>
  /** Attribute label: Product Form */
  product_form?: Maybe<FilterEqualTypeInput>
  /** One of simple, virtual, bundle, downloadable, grouped, or configurable. */
  product_type_id?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Prop65 Message */
  prop65_message?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Sale */
  sale?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Short Description */
  short_description?: Maybe<FilterMatchTypeInput>
  /** Attribute label: Size */
  size?: Maybe<FilterEqualTypeInput>
  /** Attribute label: SKU */
  sku?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Sleeve */
  sleeve?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Specialty */
  specialty?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Strap/Handle */
  strap_bags?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Style Bags */
  style_bags?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Style Bottom */
  style_bottom?: Maybe<FilterEqualTypeInput>
  /** Attribute label: Style General */
  style_general?: Maybe<FilterEqualTypeInput>
  /** The part of the URL that identifies the product */
  url_key?: Maybe<FilterEqualTypeInput>
}

/** ProductAttributeSortInput specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. It's possible to sort products using searchable attributes with enabled 'Use in Filter Options' option */
export type ProductAttributeSortInput = {
  /** Attribute label: Product Name */
  name?: Maybe<SortEnum>
  /** Sort by the position assigned to each product. */
  position?: Maybe<SortEnum>
  /** Attribute label: Price */
  price?: Maybe<SortEnum>
  /** Sort by the search relevance score (default). */
  relevance?: Maybe<SortEnum>
}

/** A discount applied to a product price. */
export type ProductDiscount = {
  __typename?: 'ProductDiscount'
  /** The actual value of the discount. */
  amount_off?: Maybe<Scalars['Float']>
  /** The discount expressed a percentage. */
  percent_off?: Maybe<Scalars['Float']>
}

/** ProductFilterInput is deprecated, use @ProductAttributeFilterInput instead. ProductFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type ProductFilterInput = {
  /** Category ID the product belongs to. */
  category_id?: Maybe<FilterTypeInput>
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<FilterTypeInput>
  /** Timestamp indicating when the product was created. */
  created_at?: Maybe<FilterTypeInput>
  /** The name of a custom layout. */
  custom_layout?: Maybe<FilterTypeInput>
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: Maybe<FilterTypeInput>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<FilterTypeInput>
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<FilterTypeInput>
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: Maybe<FilterTypeInput>
  /** The relative path to the main image on the product page. */
  image?: Maybe<FilterTypeInput>
  /** The label assigned to a product image. */
  image_label?: Maybe<FilterTypeInput>
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<FilterTypeInput>
  /** The numeric maximal price of the product. Do not include the currency code. */
  max_price?: Maybe<FilterTypeInput>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<FilterTypeInput>
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<FilterTypeInput>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<FilterTypeInput>
  /** The numeric minimal price of the product. Do not include the currency code. */
  min_price?: Maybe<FilterTypeInput>
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<FilterTypeInput>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: Maybe<FilterTypeInput>
  /** The end date for new product listings. */
  news_to_date?: Maybe<FilterTypeInput>
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<FilterTypeInput>
  /** The keyword required to perform a logical OR comparison. */
  or?: Maybe<ProductFilterInput>
  /** The price of an item. */
  price?: Maybe<FilterTypeInput>
  /** Indicates whether the product has required options. */
  required_options?: Maybe<FilterTypeInput>
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<FilterTypeInput>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<FilterTypeInput>
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<FilterTypeInput>
  /** The label assigned to a product's small image. */
  small_image_label?: Maybe<FilterTypeInput>
  /** The beginning date that a product has a special price. */
  special_from_date?: Maybe<FilterTypeInput>
  /** The discounted price of the product. Do not include the currency code. */
  special_price?: Maybe<FilterTypeInput>
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<FilterTypeInput>
  /** The file name of a swatch image */
  swatch_image?: Maybe<FilterTypeInput>
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<FilterTypeInput>
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: Maybe<FilterTypeInput>
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: Maybe<FilterTypeInput>
  /** Timestamp indicating when the product was updated. */
  updated_at?: Maybe<FilterTypeInput>
  /** The part of the URL that identifies the product */
  url_key?: Maybe<FilterTypeInput>
  url_path?: Maybe<FilterTypeInput>
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<FilterTypeInput>
}

/** Product image information. Contains the image URL and label. */
export type ProductImage = MediaGalleryInterface & {
  __typename?: 'ProductImage'
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>
}

/** The ProductInterface contains attributes that are common to all types of products. Note that descriptions may not be available for custom and EAV attributes. */
export type ProductInterface = {
  a_z?: Maybe<Scalars['Int']>
  activity?: Maybe<Scalars['String']>
  /** Advisor Tags based on advisor_tags attribute */
  advisor_tags?: Maybe<Array<Maybe<Scalars['String']>>>
  allow_credit_range?: Maybe<Scalars['Int']>
  /** The attribute set assigned to the product. */
  attribute_set_id?: Maybe<Scalars['Int']>
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>
  category_gear?: Maybe<Scalars['String']>
  climate?: Maybe<Scalars['String']>
  collar?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['Int']>
  content_left_both?: Maybe<Scalars['String']>
  content_left_patient?: Maybe<Scalars['String']>
  content_left_professional?: Maybe<Scalars['String']>
  content_left_public?: Maybe<Scalars['String']>
  content_right_both?: Maybe<Scalars['String']>
  content_right_patient?: Maybe<Scalars['String']>
  content_right_professional?: Maybe<Scalars['String']>
  content_right_public?: Maybe<Scalars['String']>
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>
  /** Timestamp indicating when the product was created. */
  created_at?: Maybe<Scalars['String']>
  credit_amount?: Maybe<Scalars['Float']>
  credit_rate?: Maybe<Scalars['Float']>
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>
  doc_clinical_trials?: Maybe<Scalars['String']>
  doc_patient_education?: Maybe<Scalars['String']>
  doc_tech_sheet?: Maybe<Scalars['String']>
  eco_collection?: Maybe<Scalars['Int']>
  erin_recommends?: Maybe<Scalars['Int']>
  features_bags?: Maybe<Scalars['String']>
  format?: Maybe<Scalars['Int']>
  gender?: Maybe<Scalars['String']>
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>
  heat_sensitive?: Maybe<Scalars['Int']>
  /** The ID number assigned to the product. */
  id?: Maybe<Scalars['Int']>
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>
  material?: Maybe<Scalars['String']>
  max_credit?: Maybe<Scalars['Float']>
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>
  min_credit?: Maybe<Scalars['Float']>
  /** Store Credit product attributes */
  mp_store_credit_attributes?: Maybe<Array<Maybe<StoreCreditProductAttributes>>>
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>
  natural_product_number?: Maybe<Scalars['String']>
  new?: Maybe<Scalars['Int']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>
  out_of_stock_message?: Maybe<Scalars['String']>
  pattern?: Maybe<Scalars['String']>
  performance_fabric?: Maybe<Scalars['Int']>
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>
  /** Product Form based on product_form attribute */
  product_form?: Maybe<Scalars['String']>
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** One of simple, virtual, bundle, downloadable, grouped, or configurable. */
  product_type_id?: Maybe<Scalars['String']>
  /** Prop65 Message */
  prop65_message?: Maybe<Scalars['String']>
  prop_sixty_five?: Maybe<Scalars['Int']>
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>
  sale?: Maybe<Scalars['Int']>
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>
  size?: Maybe<Scalars['Int']>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>
  sleeve?: Maybe<Scalars['String']>
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>
  /** The beginning date that a product has a special price. */
  special_from_date?: Maybe<Scalars['String']>
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>
  /** Specialty based on specialty attribute */
  specialty?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Stock Item Configuration */
  stock_configuration?: Maybe<StockItemConfiguration>
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>
  strap_bags?: Maybe<Scalars['String']>
  style_bags?: Maybe<Scalars['String']>
  style_bottom?: Maybe<Scalars['String']>
  style_general?: Maybe<Scalars['String']>
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>
  /** Timestamp indicating when the product was updated. */
  updated_at?: Maybe<Scalars['String']>
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>
}

/** ProductLinks is an implementation of ProductLinksInterface. */
export type ProductLinks = ProductLinksInterface & {
  __typename?: 'ProductLinks'
  /** One of related, associated, upsell, or crosssell. */
  link_type?: Maybe<Scalars['String']>
  /** The SKU of the linked product. */
  linked_product_sku?: Maybe<Scalars['String']>
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linked_product_type?: Maybe<Scalars['String']>
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']>
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']>
}

/** ProductLinks contains information about linked products, including the link type and product type of each item. */
export type ProductLinksInterface = {
  /** One of related, associated, upsell, or crosssell. */
  link_type?: Maybe<Scalars['String']>
  /** The SKU of the linked product. */
  linked_product_sku?: Maybe<Scalars['String']>
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linked_product_type?: Maybe<Scalars['String']>
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']>
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']>
}

/** ProductMediaGalleryEntriesContent contains an image in base64 format and basic information about the image. */
export type ProductMediaGalleryEntriesContent = {
  __typename?: 'ProductMediaGalleryEntriesContent'
  /** The image in base64 format. */
  base64_encoded_data?: Maybe<Scalars['String']>
  /** The file name of the image. */
  name?: Maybe<Scalars['String']>
  /** The MIME type of the file, such as image/png. */
  type?: Maybe<Scalars['String']>
}

/** ProductMediaGalleryEntriesVideoContent contains a link to a video file and basic information about the video. */
export type ProductMediaGalleryEntriesVideoContent = {
  __typename?: 'ProductMediaGalleryEntriesVideoContent'
  /** Must be external-video. */
  media_type?: Maybe<Scalars['String']>
  /** A description of the video. */
  video_description?: Maybe<Scalars['String']>
  /** Optional data about the video. */
  video_metadata?: Maybe<Scalars['String']>
  /** Describes the video source. */
  video_provider?: Maybe<Scalars['String']>
  /** The title of the video. */
  video_title?: Maybe<Scalars['String']>
  /** The URL to the video. */
  video_url?: Maybe<Scalars['String']>
}

/** Represents a product price. */
export type ProductPrice = {
  __typename?: 'ProductPrice'
  /** The price discount. Represents the difference between the regular and final price. */
  discount?: Maybe<ProductDiscount>
  /** The final price of the product after discounts applied. */
  final_price: Money
  /** The multiple FPTs that can be applied to a product price. */
  fixed_product_taxes?: Maybe<Array<Maybe<FixedProductTax>>>
  /** The regular price of the product. */
  regular_price: Money
}

/** ProductPrices is deprecated, replaced by PriceRange. The ProductPrices object contains the regular price of an item, as well as its minimum and maximum prices. Only composite products, which include bundle, configurable, and grouped products, can contain a minimum and maximum price. */
export type ProductPrices = {
  __typename?: 'ProductPrices'
  /**
   * The highest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the to value.
   * @deprecated Use PriceRange.maximum_price.
   */
  maximalPrice?: Maybe<Price>
  /**
   * The lowest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the from value.
   * @deprecated Use PriceRange.minimum_price.
   */
  minimalPrice?: Maybe<Price>
  /**
   * The base price of a product.
   * @deprecated Use regular_price from PriceRange.minimum_price or PriceRange.maximum_price.
   */
  regularPrice?: Maybe<Price>
}

/** ProductSortInput is deprecated, use @ProductAttributeSortInput instead. ProductSortInput specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type ProductSortInput = {
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<SortEnum>
  /** Timestamp indicating when the product was created. */
  created_at?: Maybe<SortEnum>
  /** The name of a custom layout. */
  custom_layout?: Maybe<SortEnum>
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: Maybe<SortEnum>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<SortEnum>
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<SortEnum>
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: Maybe<SortEnum>
  /** The relative path to the main image on the product page. */
  image?: Maybe<SortEnum>
  /** The label assigned to a product image. */
  image_label?: Maybe<SortEnum>
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<SortEnum>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<SortEnum>
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<SortEnum>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<SortEnum>
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<SortEnum>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: Maybe<SortEnum>
  /** The end date for new product listings. */
  news_to_date?: Maybe<SortEnum>
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<SortEnum>
  /** The price of the item. */
  price?: Maybe<SortEnum>
  /** Indicates whether the product has required options. */
  required_options?: Maybe<SortEnum>
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<SortEnum>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<SortEnum>
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<SortEnum>
  /** The label assigned to a product's small image. */
  small_image_label?: Maybe<SortEnum>
  /** The beginning date that a product has a special price. */
  special_from_date?: Maybe<SortEnum>
  /** The discounted price of the product. */
  special_price?: Maybe<SortEnum>
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<SortEnum>
  /** The file name of a swatch image */
  swatch_image?: Maybe<SortEnum>
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<SortEnum>
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: Maybe<SortEnum>
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: Maybe<SortEnum>
  /** Timestamp indicating when the product was updated. */
  updated_at?: Maybe<SortEnum>
  /** The part of the URL that identifies the product */
  url_key?: Maybe<SortEnum>
  url_path?: Maybe<SortEnum>
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<SortEnum>
}

/** This enumeration states whether a product stock status is in stock or out of stock */
export const ProductStockStatus = {
  Backorder: 'BACKORDER',
  InStock: 'IN_STOCK',
  OutOfStock: 'OUT_OF_STOCK',
} as const

export type ProductStockStatus = typeof ProductStockStatus[keyof typeof ProductStockStatus]
/** ProductTierPrices is deprecated and has been replaced by TierPrice. The ProductTierPrices object defines a tier price, which is a quantity discount offered to a specific customer group. */
export type ProductTierPrices = {
  __typename?: 'ProductTierPrices'
  /**
   * The ID of the customer group.
   * @deprecated customer_group_id is not relevant for storefront.
   */
  customer_group_id?: Maybe<Scalars['String']>
  /**
   * The percentage discount of the item.
   * @deprecated ProductTierPrices is deprecated. Use TierPrice.discount.
   */
  percentage_value?: Maybe<Scalars['Float']>
  /**
   * The number of items that must be purchased to qualify for tier pricing.
   * @deprecated ProductTierPrices is deprecated, use TierPrice.quantity.
   */
  qty?: Maybe<Scalars['Float']>
  /**
   * The price of the fixed price item.
   * @deprecated ProductTierPrices is deprecated. Use TierPrice.final_price
   */
  value?: Maybe<Scalars['Float']>
  /**
   * The ID assigned to the website.
   * @deprecated website_id is not relevant for storefront.
   */
  website_id?: Maybe<Scalars['Float']>
}

/** Contains information about a product video. */
export type ProductVideo = MediaGalleryInterface & {
  __typename?: 'ProductVideo'
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>
  /** Contains a ProductMediaGalleryEntriesVideoContent object. */
  video_content?: Maybe<ProductMediaGalleryEntriesVideoContent>
}

/** The Products object is the top-level object returned in a product search. */
export type Products = {
  __typename?: 'Products'
  /** Layered navigation aggregations. */
  aggregations?: Maybe<Array<Maybe<Aggregation>>>
  /**
   * Layered navigation filters array.
   * @deprecated Use aggregations instead
   */
  filters?: Maybe<Array<Maybe<LayerFilter>>>
  /** An array of products that match the specified search criteria. */
  items?: Maybe<Array<Maybe<ProductInterface>>>
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>
  /** An object that includes the default sort field and all available sort fields. */
  sort_fields?: Maybe<SortFields>
  /** The number of products returned. */
  total_count?: Maybe<Scalars['Int']>
}

export type Query = {
  __typename?: 'Query'
  /** Returns information about shopping cart */
  cart?: Maybe<Cart>
  /**
   * The category query searches for categories that match the criteria specified in the search and filter attributes.
   * @deprecated Use 'categoryList' query instead of 'category' query
   */
  category?: Maybe<CategoryTree>
  /** Returns an array of categories based on the specified filters. */
  categoryList?: Maybe<Array<Maybe<CategoryTree>>>
  /** The Checkout Agreements information */
  checkoutAgreements?: Maybe<Array<Maybe<CheckoutAgreement>>>
  /** The CMS block query returns information about CMS blocks */
  cmsBlocks?: Maybe<CmsBlocks>
  /** The CMS page query returns information about a CMS page */
  cmsPage?: Maybe<CmsPage>
  /** The countries query provides information for all countries. */
  countries?: Maybe<Array<Maybe<Country>>>
  /** The countries query provides information for a single country. */
  country?: Maybe<Country>
  /** The currency query returns information about store currency. */
  currency?: Maybe<Currency>
  /** The customAttributeMetadata query returns the attribute type, given an attribute code and entity type */
  customAttributeMetadata?: Maybe<CustomAttributeMetadata>
  /** The customer query returns information about a customer account */
  customer?: Maybe<Customer>
  /** Returns information about the customer shopping cart */
  customerCart: Cart
  /** The query returns the contents of a customer's downloadable products */
  customerDownloadableProducts?: Maybe<CustomerDownloadableProducts>
  /** List of customer orders */
  customerOrders?: Maybe<CustomerOrders>
  /** Return a list of customer payment tokens */
  customerPaymentTokens?: Maybe<CustomerPaymentTokens>
  /** Get CyberSource Cardinal Cruise parameters for completing payer authentication. */
  cyberSourceCardinalCruiseAuthPayload?: Maybe<TokenBaseCyberSourceCardinalCruiseParams>
  /** Get CyberSource Secure Acceptance form URL and parameters for collecting payment data. */
  cyberSourceSecureAcceptParams?: Maybe<TokenBaseCyberSourceSecureAcceptParams>
  /** Retrieve secure PayPal url for Payments Pro Hosted Solution transaction. */
  getHostedProUrl?: Maybe<HostedProUrl>
  /** Retrieve payment credentials for transaction. Use this query for Payflow Link and Payments Advanced payment methods. */
  getPayflowLinkToken?: Maybe<PayflowLinkToken>
  isEmailAvailable?: Maybe<IsEmailAvailableOutput>
  /** The products query searches for products that match the criteria specified in the search and filter attributes. */
  products?: Maybe<Products>
  /** The store config query */
  storeConfig?: Maybe<StoreConfig>
  /** Get the current customer's stored card(s), if any. Takes optional hash for a specific card. */
  tokenBaseCards?: Maybe<Array<Maybe<TokenBaseCard>>>
  /** Get checkout configuration for the given TokenBase payment method. */
  tokenBaseCheckoutConfig?: Maybe<TokenBaseCheckoutConfig>
  /** The urlResolver query returns the relative URL for a specified product, category or CMS page, using as input a url_key appended by the url_suffix, if one exists */
  urlResolver?: Maybe<EntityUrl>
  /**
   * The wishlist query returns the contents of a customer's wish list
   * @deprecated Moved under `Customer` `wishlist`
   */
  wishlist?: Maybe<WishlistOutput>
}

export type QueryCartArgs = {
  cart_id: Scalars['String']
}

export type QueryCategoryArgs = {
  id?: Maybe<Scalars['Int']>
}

export type QueryCategoryListArgs = {
  filters?: Maybe<CategoryFilterInput>
}

export type QueryCmsBlocksArgs = {
  identifiers?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type QueryCmsPageArgs = {
  id?: Maybe<Scalars['Int']>
  identifier?: Maybe<Scalars['String']>
}

export type QueryCountryArgs = {
  id?: Maybe<Scalars['String']>
}

export type QueryCustomAttributeMetadataArgs = {
  attributes: Array<AttributeInput>
}

export type QueryCyberSourceCardinalCruiseAuthPayloadArgs = {
  input: TokenBaseCyberSourceCardinalCruiseAuthInput
}

export type QueryCyberSourceSecureAcceptParamsArgs = {
  input: TokenBaseCyberSourceSecureAcceptInput
}

export type QueryGetHostedProUrlArgs = {
  input: HostedProUrlInput
}

export type QueryGetPayflowLinkTokenArgs = {
  input: PayflowLinkTokenInput
}

export type QueryIsEmailAvailableArgs = {
  email: Scalars['String']
}

export type QueryProductsArgs = {
  currentPage?: Maybe<Scalars['Int']>
  filter?: Maybe<ProductAttributeFilterInput>
  pageSize?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sort?: Maybe<ProductAttributeSortInput>
}

export type QueryTokenBaseCardsArgs = {
  hash?: Maybe<Scalars['String']>
}

export type QueryTokenBaseCheckoutConfigArgs = {
  method: Scalars['String']
}

export type QueryUrlResolverArgs = {
  url: Scalars['String']
}

export type Region = {
  __typename?: 'Region'
  code?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type RemoveCouponFromCartInput = {
  cart_id: Scalars['String']
  coupon_code?: Maybe<Scalars['String']>
}

export type RemoveCouponFromCartOutput = {
  __typename?: 'RemoveCouponFromCartOutput'
  cart?: Maybe<Cart>
}

export type RemoveItemFromCartInput = {
  cart_id: Scalars['String']
  cart_item_id: Scalars['Int']
}

export type RemoveItemFromCartOutput = {
  __typename?: 'RemoveItemFromCartOutput'
  cart: Cart
}

export type RevokeCustomerTokenOutput = {
  __typename?: 'RevokeCustomerTokenOutput'
  result: Scalars['Boolean']
}

/** SearchResultPageInfo provides navigation for the query response */
export type SearchResultPageInfo = {
  __typename?: 'SearchResultPageInfo'
  /** Specifies which page of results to return */
  current_page?: Maybe<Scalars['Int']>
  /** Specifies the maximum number of items to return */
  page_size?: Maybe<Scalars['Int']>
  /** Total pages */
  total_pages?: Maybe<Scalars['Int']>
}

export type SelectedBundleOption = {
  __typename?: 'SelectedBundleOption'
  id: Scalars['Int']
  label: Scalars['String']
  type: Scalars['String']
  values: Array<Maybe<SelectedBundleOptionValue>>
}

export type SelectedBundleOptionValue = {
  __typename?: 'SelectedBundleOptionValue'
  id: Scalars['Int']
  label: Scalars['String']
  price: Scalars['Float']
  quantity: Scalars['Float']
}

export type SelectedConfigurableOption = {
  __typename?: 'SelectedConfigurableOption'
  id: Scalars['Int']
  option_label: Scalars['String']
  value_id: Scalars['Int']
  value_label: Scalars['String']
}

export type SelectedCustomizableOption = {
  __typename?: 'SelectedCustomizableOption'
  id: Scalars['Int']
  is_required: Scalars['Boolean']
  label: Scalars['String']
  sort_order: Scalars['Int']
  values: Array<Maybe<SelectedCustomizableOptionValue>>
}

export type SelectedCustomizableOptionValue = {
  __typename?: 'SelectedCustomizableOptionValue'
  id: Scalars['Int']
  label: Scalars['String']
  price: CartItemSelectedOptionValuePrice
  value: Scalars['String']
}

export type SelectedPaymentMethod = {
  __typename?: 'SelectedPaymentMethod'
  /** The payment method code */
  code: Scalars['String']
  /** The purchase order number. */
  purchase_order_number?: Maybe<Scalars['String']>
  /** The payment method title. */
  title: Scalars['String']
  /** Selected card ID (TokenBase methods only) */
  tokenbase_card_id?: Maybe<Scalars['String']>
  /** Payment info (TokenBase methods only) */
  tokenbase_data?: Maybe<TokenBaseCardAdditional>
  /** Card is being saved for future use (TokenBase methods only) */
  tokenbase_save?: Maybe<Scalars['Boolean']>
}

export type SelectedShippingMethod = {
  __typename?: 'SelectedShippingMethod'
  amount: Money
  /** @deprecated The field should not be used on the storefront */
  base_amount?: Maybe<Money>
  carrier_code: Scalars['String']
  carrier_title: Scalars['String']
  method_code: Scalars['String']
  method_title: Scalars['String']
}

export type SendEmailToFriendInput = {
  product_id: Scalars['Int']
  recipients: Array<Maybe<SendEmailToFriendRecipientInput>>
  sender: SendEmailToFriendSenderInput
}

export type SendEmailToFriendOutput = {
  __typename?: 'SendEmailToFriendOutput'
  recipients?: Maybe<Array<Maybe<SendEmailToFriendRecipient>>>
  sender?: Maybe<SendEmailToFriendSender>
}

export type SendEmailToFriendRecipient = {
  __typename?: 'SendEmailToFriendRecipient'
  email: Scalars['String']
  name: Scalars['String']
}

export type SendEmailToFriendRecipientInput = {
  email: Scalars['String']
  name: Scalars['String']
}

export type SendEmailToFriendSender = {
  __typename?: 'SendEmailToFriendSender'
  email: Scalars['String']
  message: Scalars['String']
  name: Scalars['String']
}

export type SendEmailToFriendSenderInput = {
  email: Scalars['String']
  message: Scalars['String']
  name: Scalars['String']
}

export type SetBillingAddressOnCartInput = {
  billing_address: BillingAddressInput
  cart_id: Scalars['String']
}

export type SetBillingAddressOnCartOutput = {
  __typename?: 'SetBillingAddressOnCartOutput'
  cart: Cart
}

export type SetCartAttributesOnCartInput = {
  cart_id: Scalars['String']
  dispensary_promo_code?: Maybe<Scalars['String']>
  e_script_campaign?: Maybe<Scalars['String']>
  practitioner_code?: Maybe<Scalars['String']>
  ww_id?: Maybe<Scalars['String']>
  ww_practice_id?: Maybe<Scalars['String']>
  ww_practitioner_id?: Maybe<Scalars['String']>
  ww_store_id?: Maybe<Scalars['String']>
}

export type SetCartAttributesOnCartOutput = {
  __typename?: 'SetCartAttributesOnCartOutput'
  cart: Cart
}

export type SetDispensaryPromoCodeOnCartInput = {
  cart_id: Scalars['String']
  dispensary_promo_code: Scalars['String']
}

export type SetDispensaryPromoCodeOnCartOutput = {
  __typename?: 'SetDispensaryPromoCodeOnCartOutput'
  cart: Cart
}

export type SetDropShipOnCartInput = {
  cart_id: Scalars['String']
  drop_ship: Scalars['String']
}

export type SetDropShipOnCartOutput = {
  __typename?: 'SetDropShipOnCartOutput'
  cart: Cart
}

export type SetEScriptCampaignOnCartInput = {
  cart_id: Scalars['String']
  e_script_campaign: Scalars['String']
}

export type SetGuestEmailOnCartInput = {
  cart_id: Scalars['String']
  email: Scalars['String']
}

export type SetGuestEmailOnCartOutput = {
  __typename?: 'SetGuestEmailOnCartOutput'
  cart: Cart
}

export type SetHoldUntilMondayOnCartInput = {
  cart_id: Scalars['String']
  hold_until_monday: Scalars['String']
}

export type SetHoldUntilMondayOnCartOutput = {
  __typename?: 'SetHoldUntilMondayOnCartOutput'
  cart: Cart
}

export type SetNetSuiteCustomerIdOnCartInput = {
  cart_id: Scalars['String']
  ns_customer_id: Scalars['String']
}

export type SetNetSuiteCustomerIdOnCartOutput = {
  __typename?: 'SetNetSuiteCustomerIdOnCartOutput'
  cart: Cart
}

export type SetPaymentMethodAndPlaceOrderInput = {
  cart_id: Scalars['String']
  payment_method: PaymentMethodInput
}

export type SetPaymentMethodOnCartInput = {
  cart_id: Scalars['String']
  payment_method: PaymentMethodInput
}

export type SetPaymentMethodOnCartOutput = {
  __typename?: 'SetPaymentMethodOnCartOutput'
  cart: Cart
}

export type SetPractitionerCodeOnCartInput = {
  cart_id: Scalars['String']
  practitioner_code: Scalars['String']
}

export type SetPractitionerCodeOnCartOutput = {
  __typename?: 'SetPractitionerCodeOnCartOutput'
  cart: Cart
}

export type SetReferrerUriOnCartInput = {
  cart_id: Scalars['String']
  referrer_uri: Scalars['String']
}

export type SetReferrerUriOnCartOutput = {
  __typename?: 'SetReferrerUriOnCartOutput'
  cart: Cart
}

export type SetShipDateOnCartInput = {
  cart_id: Scalars['String']
  ship_date: Scalars['String']
}

export type SetShipDateOnCartOutput = {
  __typename?: 'SetShipDateOnCartOutput'
  cart: Cart
}

export type SetShippingAddressesOnCartInput = {
  cart_id: Scalars['String']
  hold_until_monday?: Maybe<Scalars['String']>
  shipping_addresses: Array<Maybe<ShippingAddressInput>>
}

export type SetShippingAddressesOnCartOutput = {
  __typename?: 'SetShippingAddressesOnCartOutput'
  cart: Cart
}

export type SetShippingMethodsOnCartInput = {
  cart_id: Scalars['String']
  shipping_methods: Array<Maybe<ShippingMethodInput>>
}

export type SetShippingMethodsOnCartOutput = {
  __typename?: 'SetShippingMethodsOnCartOutput'
  cart: Cart
}

export type SetWwIdOnCartInput = {
  cart_id: Scalars['String']
  ww_id: Scalars['String']
}

export type SetWwOnCartOutput = {
  __typename?: 'SetWwOnCartOutput'
  cart: Cart
}

export type SetWwPracticeIdOnCartInput = {
  cart_id: Scalars['String']
  ww_practice_id: Scalars['String']
}

export type SetWwPractitionerIdOnCartInput = {
  cart_id: Scalars['String']
  ww_practitioner_id: Scalars['String']
}

export type SetWwStoreIdOnCartInput = {
  cart_id: Scalars['String']
  ww_store_id: Scalars['String']
}

/** This enumeration defines whether bundle items must be shipped together. */
export const ShipBundleItemsEnum = {
  Separately: 'SEPARATELY',
  Together: 'TOGETHER',
} as const

export type ShipBundleItemsEnum = typeof ShipBundleItemsEnum[keyof typeof ShipBundleItemsEnum]
export type ShippingAddressInput = {
  address?: Maybe<CartAddressInput>
  customer_address_id?: Maybe<Scalars['Int']>
  customer_notes?: Maybe<Scalars['String']>
}

export type ShippingCartAddress = CartAddressInterface & {
  __typename?: 'ShippingCartAddress'
  available_shipping_methods?: Maybe<Array<Maybe<AvailableShippingMethod>>>
  /** @deprecated `cart_items_v2` should be used instead */
  cart_items?: Maybe<Array<Maybe<CartItemQuantity>>>
  cart_items_v2?: Maybe<Array<Maybe<CartItemInterface>>>
  city: Scalars['String']
  company?: Maybe<Scalars['String']>
  country: CartAddressCountry
  customer_notes?: Maybe<Scalars['String']>
  firstname: Scalars['String']
  /** @deprecated This information shoud not be exposed on frontend */
  items_weight?: Maybe<Scalars['Float']>
  lastname: Scalars['String']
  postcode?: Maybe<Scalars['String']>
  region?: Maybe<CartAddressRegion>
  selected_shipping_method?: Maybe<SelectedShippingMethod>
  street: Array<Maybe<Scalars['String']>>
  telephone: Scalars['String']
}

export type ShippingMethodInput = {
  carrier_code: Scalars['String']
  method_code: Scalars['String']
}

/** Simple Cart Item */
export type SimpleCartItem = CartItemInterface & {
  __typename?: 'SimpleCartItem'
  customizable_options?: Maybe<Array<Maybe<SelectedCustomizableOption>>>
  id: Scalars['String']
  /** Cart item attributes */
  item_attributes?: Maybe<CartItemAttributes>
  prices?: Maybe<CartItemPrices>
  product: ProductInterface
  quantity: Scalars['Float']
}

/** A simple product is tangible and are usually sold as single units or in fixed quantities. */
export type SimpleProduct = CustomizableProductInterface &
  PhysicalProductInterface &
  ProductInterface & {
    __typename?: 'SimpleProduct'
    a_z?: Maybe<Scalars['Int']>
    activity?: Maybe<Scalars['String']>
    /** Advisor Tags based on advisor_tags attribute */
    advisor_tags?: Maybe<Array<Maybe<Scalars['String']>>>
    allow_credit_range?: Maybe<Scalars['Int']>
    /** The attribute set assigned to the product. */
    attribute_set_id?: Maybe<Scalars['Int']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    category_gear?: Maybe<Scalars['String']>
    climate?: Maybe<Scalars['String']>
    collar?: Maybe<Scalars['String']>
    color?: Maybe<Scalars['Int']>
    content_left_both?: Maybe<Scalars['String']>
    content_left_patient?: Maybe<Scalars['String']>
    content_left_professional?: Maybe<Scalars['String']>
    content_left_public?: Maybe<Scalars['String']>
    content_right_both?: Maybe<Scalars['String']>
    content_right_patient?: Maybe<Scalars['String']>
    content_right_professional?: Maybe<Scalars['String']>
    content_right_public?: Maybe<Scalars['String']>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was created. */
    created_at?: Maybe<Scalars['String']>
    credit_amount?: Maybe<Scalars['Float']>
    credit_rate?: Maybe<Scalars['Float']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    doc_clinical_trials?: Maybe<Scalars['String']>
    doc_patient_education?: Maybe<Scalars['String']>
    doc_tech_sheet?: Maybe<Scalars['String']>
    eco_collection?: Maybe<Scalars['Int']>
    erin_recommends?: Maybe<Scalars['Int']>
    features_bags?: Maybe<Scalars['String']>
    format?: Maybe<Scalars['Int']>
    gender?: Maybe<Scalars['String']>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']>
    heat_sensitive?: Maybe<Scalars['Int']>
    /** The ID number assigned to the product. */
    id?: Maybe<Scalars['Int']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']>
    material?: Maybe<Scalars['String']>
    max_credit?: Maybe<Scalars['Float']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']>
    min_credit?: Maybe<Scalars['Float']>
    /** Store Credit product attributes */
    mp_store_credit_attributes?: Maybe<Array<Maybe<StoreCreditProductAttributes>>>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']>
    natural_product_number?: Maybe<Scalars['String']>
    new?: Maybe<Scalars['Int']>
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    new_from_date?: Maybe<Scalars['String']>
    /** The end date for new product listings. */
    new_to_date?: Maybe<Scalars['String']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']>
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']>
    out_of_stock_message?: Maybe<Scalars['String']>
    pattern?: Maybe<Scalars['String']>
    performance_fabric?: Maybe<Scalars['Int']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    /** Product Form based on product_form attribute */
    product_form?: Maybe<Scalars['String']>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    /** One of simple, virtual, bundle, downloadable, grouped, or configurable. */
    product_type_id?: Maybe<Scalars['String']>
    /** Prop65 Message */
    prop65_message?: Maybe<Scalars['String']>
    prop_sixty_five?: Maybe<Scalars['Int']>
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    sale?: Maybe<Scalars['Int']>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size?: Maybe<Scalars['Int']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']>
    sleeve?: Maybe<Scalars['String']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /** The beginning date that a product has a special price. */
    special_from_date?: Maybe<Scalars['String']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']>
    /** Specialty based on specialty attribute */
    specialty?: Maybe<Array<Maybe<Scalars['String']>>>
    /** Stock Item Configuration */
    stock_configuration?: Maybe<StockItemConfiguration>
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    strap_bags?: Maybe<Scalars['String']>
    style_bags?: Maybe<Scalars['String']>
    style_bottom?: Maybe<Scalars['String']>
    style_general?: Maybe<Scalars['String']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was updated. */
    updated_at?: Maybe<Scalars['String']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']>
    /** An index of a product variant */
    variant_index?: Maybe<SimpleVariantIndex>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<Scalars['Float']>
  }

export type SimpleProductCartItemInput = {
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>
  data: CartItemInput
}

/** An index containing information for a simple product variant */
export type SimpleVariantIndex = {
  __typename?: 'SimpleVariantIndex'
  /** An array of used attribute values */
  attribute_values?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /** Parent configurable product */
  parent?: Maybe<SimpleVariantParent>
  short_description?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
  /** An array of used product attributes of a simple product */
  used_attributes?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SimpleVariantParent = {
  __typename?: 'SimpleVariantParent'
  product?: Maybe<ProductInterface>
  sku?: Maybe<Scalars['String']>
}

/** This enumeration indicates whether to return results in ascending or descending order */
export const SortEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const

export type SortEnum = typeof SortEnum[keyof typeof SortEnum]
export type SortField = {
  __typename?: 'SortField'
  /** Label of sort field. */
  label?: Maybe<Scalars['String']>
  /** Attribute code of sort field. */
  value?: Maybe<Scalars['String']>
}

/** SortFields contains a default value for sort fields and all available sort fields. */
export type SortFields = {
  __typename?: 'SortFields'
  /** Default value of sort fields. */
  default?: Maybe<Scalars['String']>
  /** Available sort fields. */
  options?: Maybe<Array<Maybe<SortField>>>
}

/** Stock Item Configuration */
export type StockItemConfiguration = {
  __typename?: 'StockItemConfiguration'
  /** Maximum Salable Quantity */
  max_sale_qty?: Maybe<Scalars['String']>
  /** Minimum Salable Quantity */
  min_sale_qty?: Maybe<Scalars['String']>
  /** Quantity Increments */
  qty_increments?: Maybe<Scalars['String']>
}

/** The type contains information about a store config */
export type StoreConfig = {
  __typename?: 'StoreConfig'
  /** Footer Miscellaneous HTML */
  absolute_footer?: Maybe<Scalars['String']>
  /** Base currency code */
  base_currency_code?: Maybe<Scalars['String']>
  /** Base link URL for the store */
  base_link_url?: Maybe<Scalars['String']>
  /** Base media URL for the store */
  base_media_url?: Maybe<Scalars['String']>
  /** Base static URL for the store */
  base_static_url?: Maybe<Scalars['String']>
  /** Base URL for the store */
  base_url?: Maybe<Scalars['String']>
  /** Default Sort By. */
  catalog_default_sort_by?: Maybe<Scalars['String']>
  /** Corresponds to the 'Display Prices In Product Lists' field. It indicates how FPT information is displayed on category pages */
  category_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>
  /** Category URL Suffix. */
  category_url_suffix?: Maybe<Scalars['String']>
  /** CMS Home Page */
  cms_home_page?: Maybe<Scalars['String']>
  /** CMS No Cookies Page */
  cms_no_cookies?: Maybe<Scalars['String']>
  /** CMS No Route Page */
  cms_no_route?: Maybe<Scalars['String']>
  /** A code assigned to the store to identify it */
  code?: Maybe<Scalars['String']>
  /** Copyright */
  copyright?: Maybe<Scalars['String']>
  /** Default Meta Description */
  default_description?: Maybe<Scalars['String']>
  /** Default display currency code */
  default_display_currency_code?: Maybe<Scalars['String']>
  /** Default Meta Keywords */
  default_keywords?: Maybe<Scalars['String']>
  /** Default Page Title */
  default_title?: Maybe<Scalars['String']>
  /** Display Demo Store Notice */
  demonotice?: Maybe<Scalars['Int']>
  /** Default Web URL */
  front?: Maybe<Scalars['String']>
  /** Products per Page on Grid Default Value. */
  grid_per_page?: Maybe<Scalars['Int']>
  /** Products per Page on Grid Allowed Values. */
  grid_per_page_values?: Maybe<Scalars['String']>
  /** Scripts and Style Sheets */
  head_includes?: Maybe<Scalars['String']>
  /** Favicon Icon */
  head_shortcut_icon?: Maybe<Scalars['String']>
  /** Logo Image */
  header_logo_src?: Maybe<Scalars['String']>
  /** The ID number assigned to the store */
  id?: Maybe<Scalars['Int']>
  /** List Mode. */
  list_mode?: Maybe<Scalars['String']>
  /** Products per Page on List Default Value. */
  list_per_page?: Maybe<Scalars['Int']>
  /** Products per Page on List Allowed Values. */
  list_per_page_values?: Maybe<Scalars['String']>
  /** Store locale */
  locale?: Maybe<Scalars['String']>
  /** Logo Image Alt */
  logo_alt?: Maybe<Scalars['String']>
  /** Logo Attribute Height */
  logo_height?: Maybe<Scalars['Int']>
  /** Logo Attribute Width */
  logo_width?: Maybe<Scalars['Int']>
  /** Default No-route URL */
  no_route?: Maybe<Scalars['String']>
  /** Corresponds to the 'Display Prices On Product View Page' field. It indicates how FPT information is displayed on product pages */
  product_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>
  /** Product URL Suffix. */
  product_url_suffix?: Maybe<Scalars['String']>
  /** The ID of the root category */
  root_category_id?: Maybe<Scalars['Int']>
  /** Corresponds to the 'Display Prices In Sales Modules' field. It indicates how FPT information is displayed on cart, checkout, and order pages */
  sales_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>
  /** Secure base link URL for the store */
  secure_base_link_url?: Maybe<Scalars['String']>
  /** Secure base media URL for the store */
  secure_base_media_url?: Maybe<Scalars['String']>
  /** Secure base static URL for the store */
  secure_base_static_url?: Maybe<Scalars['String']>
  /** Secure base URL for the store */
  secure_base_url?: Maybe<Scalars['String']>
  /** Show Breadcrumbs for CMS Pages */
  show_cms_breadcrumbs?: Maybe<Scalars['Int']>
  /** Name of the store */
  store_name?: Maybe<Scalars['String']>
  /** Timezone of the store */
  timezone?: Maybe<Scalars['String']>
  /** Page Title Prefix */
  title_prefix?: Maybe<Scalars['String']>
  /** Page Title Separator. */
  title_separator?: Maybe<Scalars['String']>
  /** Page Title Suffix */
  title_suffix?: Maybe<Scalars['String']>
  /** The ID number assigned to the website store belongs */
  website_id?: Maybe<Scalars['Int']>
  /** The unit of weight */
  weight_unit?: Maybe<Scalars['String']>
  /** Welcome Text */
  welcome?: Maybe<Scalars['String']>
}

/** Store Credit defines basic features of a bundle product and contains multiple BundleItems. */
export type StoreCreditProduct = CustomizableProductInterface &
  ProductInterface & {
    __typename?: 'StoreCreditProduct'
    a_z?: Maybe<Scalars['Int']>
    activity?: Maybe<Scalars['String']>
    /** Advisor Tags based on advisor_tags attribute */
    advisor_tags?: Maybe<Array<Maybe<Scalars['String']>>>
    allow_credit_range?: Maybe<Scalars['Int']>
    /** The attribute set assigned to the product. */
    attribute_set_id?: Maybe<Scalars['Int']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    category_gear?: Maybe<Scalars['String']>
    climate?: Maybe<Scalars['String']>
    collar?: Maybe<Scalars['String']>
    color?: Maybe<Scalars['Int']>
    content_left_both?: Maybe<Scalars['String']>
    content_left_patient?: Maybe<Scalars['String']>
    content_left_professional?: Maybe<Scalars['String']>
    content_left_public?: Maybe<Scalars['String']>
    content_right_both?: Maybe<Scalars['String']>
    content_right_patient?: Maybe<Scalars['String']>
    content_right_professional?: Maybe<Scalars['String']>
    content_right_public?: Maybe<Scalars['String']>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was created. */
    created_at?: Maybe<Scalars['String']>
    credit_amount?: Maybe<Scalars['Float']>
    credit_rate?: Maybe<Scalars['Float']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    doc_clinical_trials?: Maybe<Scalars['String']>
    doc_patient_education?: Maybe<Scalars['String']>
    doc_tech_sheet?: Maybe<Scalars['String']>
    eco_collection?: Maybe<Scalars['Int']>
    erin_recommends?: Maybe<Scalars['Int']>
    features_bags?: Maybe<Scalars['String']>
    format?: Maybe<Scalars['Int']>
    gender?: Maybe<Scalars['String']>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']>
    heat_sensitive?: Maybe<Scalars['Int']>
    /** The ID number assigned to the product. */
    id?: Maybe<Scalars['Int']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']>
    material?: Maybe<Scalars['String']>
    max_credit?: Maybe<Scalars['Float']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']>
    min_credit?: Maybe<Scalars['Float']>
    /** Store Credit product attributes */
    mp_store_credit_attributes?: Maybe<Array<Maybe<StoreCreditProductAttributes>>>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']>
    natural_product_number?: Maybe<Scalars['String']>
    new?: Maybe<Scalars['Int']>
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    new_from_date?: Maybe<Scalars['String']>
    /** The end date for new product listings. */
    new_to_date?: Maybe<Scalars['String']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']>
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']>
    out_of_stock_message?: Maybe<Scalars['String']>
    pattern?: Maybe<Scalars['String']>
    performance_fabric?: Maybe<Scalars['Int']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    /** Product Form based on product_form attribute */
    product_form?: Maybe<Scalars['String']>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    /** One of simple, virtual, bundle, downloadable, grouped, or configurable. */
    product_type_id?: Maybe<Scalars['String']>
    /** Prop65 Message */
    prop65_message?: Maybe<Scalars['String']>
    prop_sixty_five?: Maybe<Scalars['Int']>
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    sale?: Maybe<Scalars['Int']>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size?: Maybe<Scalars['Int']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']>
    sleeve?: Maybe<Scalars['String']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /** The beginning date that a product has a special price. */
    special_from_date?: Maybe<Scalars['String']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']>
    /** Specialty based on specialty attribute */
    specialty?: Maybe<Array<Maybe<Scalars['String']>>>
    /** Stock Item Configuration */
    stock_configuration?: Maybe<StockItemConfiguration>
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    strap_bags?: Maybe<Scalars['String']>
    style_bags?: Maybe<Scalars['String']>
    style_bottom?: Maybe<Scalars['String']>
    style_general?: Maybe<Scalars['String']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was updated. */
    updated_at?: Maybe<Scalars['String']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
  }

export type StoreCreditProductAttributes = {
  __typename?: 'StoreCreditProductAttributes'
  /** Attribute code */
  attribute_code?: Maybe<Scalars['String']>
  /** Attribute value */
  value?: Maybe<Scalars['String']>
}

export type SwatchData = {
  __typename?: 'SwatchData'
  /** Type of swatch filter item: 1 - text, 2 - image */
  type?: Maybe<Scalars['String']>
  /** Value for swatch item (text or image link) */
  value?: Maybe<Scalars['String']>
}

export type SwatchDataInterface = {
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']>
}

export type SwatchLayerFilterItem = LayerFilterItemInterface &
  SwatchLayerFilterItemInterface & {
    __typename?: 'SwatchLayerFilterItem'
    /**
     * Count of items by filter.
     * @deprecated Use AggregationOption.count instead.
     */
    items_count?: Maybe<Scalars['Int']>
    /**
     * Filter label.
     * @deprecated Use AggregationOption.label instead.
     */
    label?: Maybe<Scalars['String']>
    /** Data required to render swatch filter item */
    swatch_data?: Maybe<SwatchData>
    /**
     * Value for filter request variable to be used in query.
     * @deprecated Use AggregationOption.value instead.
     */
    value_string?: Maybe<Scalars['String']>
  }

export type SwatchLayerFilterItemInterface = {
  /** Data required to render swatch filter item */
  swatch_data?: Maybe<SwatchData>
}

export type TextSwatchData = SwatchDataInterface & {
  __typename?: 'TextSwatchData'
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']>
}

/** A price based on the quantity purchased. */
export type TierPrice = {
  __typename?: 'TierPrice'
  /** The price discount that this tier represents. */
  discount?: Maybe<ProductDiscount>
  final_price?: Maybe<Money>
  /** The minimum number of items that must be purchased to qualify for this price tier. */
  quantity?: Maybe<Scalars['Float']>
}

/** A stored payment account/credit card. */
export type TokenBaseCard = {
  __typename?: 'TokenBaseCard'
  /** Is card active */
  active?: Maybe<Scalars['Boolean']>
  /** Card payment data */
  additional?: Maybe<TokenBaseCardAdditional>
  /** Card billing address */
  address?: Maybe<CustomerAddress>
  /** Created-at date */
  created_at?: Maybe<Scalars['String']>
  /** Customer email */
  customer_email?: Maybe<Scalars['String']>
  /** Customer ID */
  customer_id?: Maybe<Scalars['Int']>
  /** Created-by IP */
  customer_ip?: Maybe<Scalars['String']>
  /** Expiration date */
  expires?: Maybe<Scalars['String']>
  /** Card identifier hash */
  hash?: Maybe<Scalars['String']>
  /** Card label */
  label?: Maybe<Scalars['String']>
  /** Last used date */
  last_use?: Maybe<Scalars['String']>
  /** Payment method code */
  method?: Maybe<Scalars['String']>
  /** Card gateway payment ID */
  payment_id?: Maybe<Scalars['String']>
  /** Card gateway profile ID */
  profile_id?: Maybe<Scalars['String']>
  /** Last updated date */
  updated_at?: Maybe<Scalars['String']>
}

/** Details and metadata for a stored CC/ACH. */
export type TokenBaseCardAdditional = {
  __typename?: 'TokenBaseCardAdditional'
  /** CC Bin (First-6) */
  cc_bin?: Maybe<Scalars['String']>
  /** CC Expiration Month */
  cc_exp_month?: Maybe<Scalars['String']>
  /** CC Expiration Year */
  cc_exp_year?: Maybe<Scalars['String']>
  /** CC Last-4 */
  cc_last4?: Maybe<Scalars['String']>
  /** CC Owner */
  cc_owner?: Maybe<Scalars['String']>
  /** CC Type */
  cc_type?: Maybe<Scalars['String']>
  /** ACH Account Name */
  echeck_account_name?: Maybe<Scalars['String']>
  /** ACH Account Number */
  echeck_account_no?: Maybe<Scalars['String']>
  /** ACH Account Number Last-4 */
  echeck_account_number_last4?: Maybe<Scalars['String']>
  /** ACH Account type */
  echeck_account_type?: Maybe<TokenBaseEcheckAccountType>
  /** ACH Bank Name */
  echeck_bank_name?: Maybe<Scalars['String']>
  /** ACH Routing Number */
  echeck_routing_no?: Maybe<Scalars['String']>
  /** ACH Routing Number Last-4 */
  echeck_routing_number_last4?: Maybe<Scalars['String']>
}

/** Input for creating a stored card. */
export type TokenBaseCardCreateInput = {
  /** Is card active */
  active?: Maybe<Scalars['Boolean']>
  /** Card payment data */
  additional?: Maybe<TokenBaseCardPaymentInput>
  /** Card billing address */
  address?: Maybe<CustomerAddressInput>
  /** Customer email (required) */
  customer_email: Scalars['String']
  /** Created-by IP */
  customer_ip?: Maybe<Scalars['String']>
  /** Card expiration date (YYYY-MM-DD 23:59:59) */
  expires?: Maybe<Scalars['String']>
  /** Payment method code (required) */
  method: Scalars['String']
  /** Card gateway payment ID */
  payment_id?: Maybe<Scalars['String']>
  /** Card gateway profile ID */
  profile_id?: Maybe<Scalars['String']>
}

/** Payment data for a stored card. Note, the specific fields that are relevant depend on the payment method. */
export type TokenBaseCardPaymentInput = {
  /** TokenBase Card Hash (used only during checkout) */
  card_id?: Maybe<Scalars['String']>
  /** CC Bin (First-6) */
  cc_bin?: Maybe<Scalars['String']>
  /** CC CVV */
  cc_cid?: Maybe<Scalars['String']>
  /** CC Expiration Month */
  cc_exp_month?: Maybe<Scalars['String']>
  /** CC Expiration Year */
  cc_exp_year?: Maybe<Scalars['String']>
  /** CC Last-4 */
  cc_last4?: Maybe<Scalars['String']>
  /** CC Number */
  cc_number?: Maybe<Scalars['String']>
  /** CC Owner */
  cc_owner?: Maybe<Scalars['String']>
  /** CC Type */
  cc_type?: Maybe<Scalars['String']>
  /** ACH Account Name */
  echeck_account_name?: Maybe<Scalars['String']>
  /** ACH Account Number */
  echeck_account_no?: Maybe<Scalars['String']>
  /** ACH Account Type */
  echeck_account_type?: Maybe<TokenBaseEcheckAccountType>
  /** ACH Bank Name */
  echeck_bank_name?: Maybe<Scalars['String']>
  /** ACH Routing Number */
  echeck_routing_no?: Maybe<Scalars['String']>
  payerauth_session_id?: Maybe<Scalars['String']>
  response_jwt?: Maybe<Scalars['String']>
  /** Save card (used only during checkout) */
  save?: Maybe<Scalars['Boolean']>
}

/** Input for updating a stored card. */
export type TokenBaseCardUpdateInput = {
  /** Is card active */
  active?: Maybe<Scalars['Boolean']>
  /** Card payment data */
  additional?: Maybe<TokenBaseCardPaymentInput>
  /** Card billing address */
  address?: Maybe<CustomerAddressInput>
  /** Customer email */
  customer_email?: Maybe<Scalars['String']>
  /** Created-by IP */
  customer_ip?: Maybe<Scalars['String']>
  /** Card expiration date (YYYY-MM-DD 23:59:59) */
  expires?: Maybe<Scalars['String']>
  /** Card identifier hash to update (required) */
  hash: Scalars['String']
  /** Payment method code */
  method?: Maybe<Scalars['String']>
}

/** Checkout configuration for a TokenBase payment method. */
export type TokenBaseCheckoutConfig = {
  __typename?: 'TokenBaseCheckoutConfig'
  /** Available CC types */
  availableTypes?: Maybe<Array<Maybe<TokenBaseKeyValue>>>
  /** Can cards be saved? */
  canSaveCard?: Maybe<Scalars['Boolean']>
  /** Is CC BIN (first6) storage enabled? */
  canStoreBin?: Maybe<Scalars['Boolean']>
  cardinalAuthUrl?: Maybe<Scalars['String']>
  cardinalJWT?: Maybe<Scalars['String']>
  cardinalScript?: Maybe<Scalars['String']>
  /** CVV helper image URL */
  cvvImageUrl?: Maybe<Scalars['String']>
  /** Hash of the default card to select */
  defaultSaveCard?: Maybe<Scalars['Boolean']>
  fingerprintUrl?: Maybe<Scalars['String']>
  /** Is card saving forced? */
  forceSaveCard?: Maybe<Scalars['Boolean']>
  /** Is CVV enabled? */
  hasVerification?: Maybe<Scalars['Boolean']>
  /** Is CC type detection enabled? */
  isCcDetectionEnabled?: Maybe<Scalars['Boolean']>
  /** Payment logo image URL (if enabled) */
  logoImage?: Maybe<Scalars['String']>
  /** Payment method code */
  method?: Maybe<Scalars['String']>
  /** Available CC Exp Months */
  months?: Maybe<Array<Maybe<TokenBaseKeyValue>>>
  paramUrl?: Maybe<Scalars['String']>
  /** Is CVV required for stored cards? */
  requireCcv?: Maybe<Scalars['Boolean']>
  /** Is the payment gateway in sandbox mode? */
  sandbox?: Maybe<Scalars['Boolean']>
  /** Are stored cards enabled? */
  useVault?: Maybe<Scalars['Boolean']>
  /** Available CC Exp Years */
  years?: Maybe<Array<Maybe<TokenBaseKeyValue>>>
}

export type TokenBaseCyberSourceCardinalCruiseAuthInput = {
  cardId?: Maybe<Scalars['String']>
  cartId: Scalars['String']
  guestEmail?: Maybe<Scalars['String']>
}

export type TokenBaseCyberSourceCardinalCruiseParams = {
  __typename?: 'TokenBaseCyberSourceCardinalCruiseParams'
  JWT?: Maybe<Scalars['String']>
  authPayload?: Maybe<Scalars['String']>
  orderPayload?: Maybe<Scalars['String']>
}

export type TokenBaseCyberSourceSecureAcceptInput = {
  billingAddress?: Maybe<CustomerAddressInput>
  cardId?: Maybe<Scalars['String']>
  cartId?: Maybe<Scalars['String']>
  guestEmail?: Maybe<Scalars['String']>
  source: TokenBaseCyberSourceSecureAcceptSource
}

export type TokenBaseCyberSourceSecureAcceptParams = {
  __typename?: 'TokenBaseCyberSourceSecureAcceptParams'
  iframeAction?: Maybe<Scalars['String']>
  iframeParams?: Maybe<Scalars['String']>
}

export const TokenBaseCyberSourceSecureAcceptSource = {
  Checkout: 'checkout',
  Paymentinfo: 'paymentinfo',
} as const

export type TokenBaseCyberSourceSecureAcceptSource = typeof TokenBaseCyberSourceSecureAcceptSource[keyof typeof TokenBaseCyberSourceSecureAcceptSource]
export const TokenBaseEcheckAccountType = {
  BusinessChecking: 'businessChecking',
  Checking: 'checking',
  Savings: 'savings',
} as const

export type TokenBaseEcheckAccountType = typeof TokenBaseEcheckAccountType[keyof typeof TokenBaseEcheckAccountType]
/** Container for generic key/value data. */
export type TokenBaseKeyValue = {
  __typename?: 'TokenBaseKeyValue'
  /** Generic key */
  key?: Maybe<Scalars['String']>
  /** Generic value */
  value?: Maybe<Scalars['String']>
}

export type TotalSegmentsOutput = {
  __typename?: 'TotalSegmentsOutput'
  code?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type UpdateCartItemsInput = {
  cart_id: Scalars['String']
  cart_items: Array<Maybe<CartItemUpdateInput>>
}

export type UpdateCartItemsOutput = {
  __typename?: 'UpdateCartItemsOutput'
  cart: Cart
}

/** The object contains URL rewrite details */
export type UrlRewrite = {
  __typename?: 'UrlRewrite'
  /** Request parameters */
  parameters?: Maybe<Array<Maybe<HttpQueryParameter>>>
  /** Request URL */
  url?: Maybe<Scalars['String']>
}

/** This enumeration defines the entity type. */
export const UrlRewriteEntityTypeEnum = {
  Category: 'CATEGORY',
  CmsPage: 'CMS_PAGE',
  Product: 'PRODUCT',
} as const

export type UrlRewriteEntityTypeEnum = typeof UrlRewriteEntityTypeEnum[keyof typeof UrlRewriteEntityTypeEnum]
/** Virtual Cart Item */
export type VirtualCartItem = CartItemInterface & {
  __typename?: 'VirtualCartItem'
  customizable_options?: Maybe<Array<Maybe<SelectedCustomizableOption>>>
  id: Scalars['String']
  /** Cart item attributes */
  item_attributes?: Maybe<CartItemAttributes>
  prices?: Maybe<CartItemPrices>
  product: ProductInterface
  quantity: Scalars['Float']
}

/** A virtual product is non-tangible product that does not require shipping and is not kept in inventory. */
export type VirtualProduct = CustomizableProductInterface &
  ProductInterface & {
    __typename?: 'VirtualProduct'
    a_z?: Maybe<Scalars['Int']>
    activity?: Maybe<Scalars['String']>
    /** Advisor Tags based on advisor_tags attribute */
    advisor_tags?: Maybe<Array<Maybe<Scalars['String']>>>
    allow_credit_range?: Maybe<Scalars['Int']>
    /** The attribute set assigned to the product. */
    attribute_set_id?: Maybe<Scalars['Int']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    category_gear?: Maybe<Scalars['String']>
    climate?: Maybe<Scalars['String']>
    collar?: Maybe<Scalars['String']>
    color?: Maybe<Scalars['Int']>
    content_left_both?: Maybe<Scalars['String']>
    content_left_patient?: Maybe<Scalars['String']>
    content_left_professional?: Maybe<Scalars['String']>
    content_left_public?: Maybe<Scalars['String']>
    content_right_both?: Maybe<Scalars['String']>
    content_right_patient?: Maybe<Scalars['String']>
    content_right_professional?: Maybe<Scalars['String']>
    content_right_public?: Maybe<Scalars['String']>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was created. */
    created_at?: Maybe<Scalars['String']>
    credit_amount?: Maybe<Scalars['Float']>
    credit_rate?: Maybe<Scalars['Float']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    doc_clinical_trials?: Maybe<Scalars['String']>
    doc_patient_education?: Maybe<Scalars['String']>
    doc_tech_sheet?: Maybe<Scalars['String']>
    eco_collection?: Maybe<Scalars['Int']>
    erin_recommends?: Maybe<Scalars['Int']>
    features_bags?: Maybe<Scalars['String']>
    format?: Maybe<Scalars['Int']>
    gender?: Maybe<Scalars['String']>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']>
    heat_sensitive?: Maybe<Scalars['Int']>
    /** The ID number assigned to the product. */
    id?: Maybe<Scalars['Int']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']>
    material?: Maybe<Scalars['String']>
    max_credit?: Maybe<Scalars['Float']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']>
    min_credit?: Maybe<Scalars['Float']>
    /** Store Credit product attributes */
    mp_store_credit_attributes?: Maybe<Array<Maybe<StoreCreditProductAttributes>>>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']>
    natural_product_number?: Maybe<Scalars['String']>
    new?: Maybe<Scalars['Int']>
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    new_from_date?: Maybe<Scalars['String']>
    /** The end date for new product listings. */
    new_to_date?: Maybe<Scalars['String']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']>
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']>
    out_of_stock_message?: Maybe<Scalars['String']>
    pattern?: Maybe<Scalars['String']>
    performance_fabric?: Maybe<Scalars['Int']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    /** Product Form based on product_form attribute */
    product_form?: Maybe<Scalars['String']>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    /** One of simple, virtual, bundle, downloadable, grouped, or configurable. */
    product_type_id?: Maybe<Scalars['String']>
    /** Prop65 Message */
    prop65_message?: Maybe<Scalars['String']>
    prop_sixty_five?: Maybe<Scalars['Int']>
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    sale?: Maybe<Scalars['Int']>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size?: Maybe<Scalars['Int']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']>
    sleeve?: Maybe<Scalars['String']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /** The beginning date that a product has a special price. */
    special_from_date?: Maybe<Scalars['String']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']>
    /** Specialty based on specialty attribute */
    specialty?: Maybe<Array<Maybe<Scalars['String']>>>
    /** Stock Item Configuration */
    stock_configuration?: Maybe<StockItemConfiguration>
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    strap_bags?: Maybe<Scalars['String']>
    style_bags?: Maybe<Scalars['String']>
    style_bottom?: Maybe<Scalars['String']>
    style_general?: Maybe<Scalars['String']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']>
    /** Timestamp indicating when the product was updated. */
    updated_at?: Maybe<Scalars['String']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
  }

export type VirtualProductCartItemInput = {
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>
  data: CartItemInput
}

/** Website is deprecated because it is should not be used on storefront. The type contains information about a website */
export type Website = {
  __typename?: 'Website'
  /**
   * A code assigned to the website to identify it
   * @deprecated The field should not be used on the storefront.
   */
  code?: Maybe<Scalars['String']>
  /**
   * The default group ID that the website has
   * @deprecated The field should not be used on the storefront.
   */
  default_group_id?: Maybe<Scalars['String']>
  /**
   * The ID number assigned to the website
   * @deprecated The field should not be used on the storefront.
   */
  id?: Maybe<Scalars['Int']>
  /**
   * Specifies if this is the default website
   * @deprecated The field should not be used on the storefront.
   */
  is_default?: Maybe<Scalars['Boolean']>
  /**
   * The website name. Websites use this name to identify it easier.
   * @deprecated The field should not be used on the storefront.
   */
  name?: Maybe<Scalars['String']>
  /**
   * The attribute to use for sorting websites
   * @deprecated The field should not be used on the storefront.
   */
  sort_order?: Maybe<Scalars['Int']>
}

export type Wishlist = {
  __typename?: 'Wishlist'
  /** Wishlist unique identifier */
  id?: Maybe<Scalars['ID']>
  /** An array of items in the customer's wish list */
  items?: Maybe<Array<Maybe<WishlistItem>>>
  /** The number of items in the wish list */
  items_count?: Maybe<Scalars['Int']>
  /** An encrypted code that Magento uses to link to the wish list */
  sharing_code?: Maybe<Scalars['String']>
  /** The time of the last modification to the wish list */
  updated_at?: Maybe<Scalars['String']>
}

export type WishlistItem = {
  __typename?: 'WishlistItem'
  /** The time when the customer added the item to the wish list */
  added_at?: Maybe<Scalars['String']>
  /** The customer's comment about this item */
  description?: Maybe<Scalars['String']>
  /** The wish list item ID */
  id?: Maybe<Scalars['Int']>
  product?: Maybe<ProductInterface>
  /** The quantity of this wish list item */
  qty?: Maybe<Scalars['Float']>
}

/** Deprecated: `Wishlist` type should be used instead */
export type WishlistOutput = {
  __typename?: 'WishlistOutput'
  /**
   * An array of items in the customer's wish list
   * @deprecated Use field `items` from type `Wishlist` instead
   */
  items?: Maybe<Array<Maybe<WishlistItem>>>
  /**
   * The number of items in the wish list
   * @deprecated Use field `items_count` from type `Wishlist` instead
   */
  items_count?: Maybe<Scalars['Int']>
  /**
   * When multiple wish lists are enabled, the name the customer assigns to the wishlist
   * @deprecated This field is related to Commerce functionality and is always `null` in Open Source edition
   */
  name?: Maybe<Scalars['String']>
  /**
   * An encrypted code that Magento uses to link to the wish list
   * @deprecated Use field `sharing_code` from type `Wishlist` instead
   */
  sharing_code?: Maybe<Scalars['String']>
  /**
   * The time of the last modification to the wish list
   * @deprecated Use field `updated_at` from type `Wishlist` instead
   */
  updated_at?: Maybe<Scalars['String']>
}

export type CreateEmptyCartInput = {
  cart_id?: Maybe<Scalars['String']>
}

export type CategoryQueryVariables = Exact<{
  id: Scalars['String']
}>

export type CategoryQuery = { __typename?: 'Query' } & {
  categoryList?: Maybe<
    Array<
      Maybe<
        { __typename?: 'CategoryTree' } & Pick<CategoryTree, 'id' | 'name' | 'url_path'> & {
            breadcrumbs?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Breadcrumb' } & Pick<
                    Breadcrumb,
                    'category_name' | 'category_url_key' | 'category_url_path'
                  >
                >
              >
            >
          }
      >
    >
  >
}

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>

export type GetCountriesQuery = { __typename?: 'Query' } & {
  countries?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Country' } & Pick<
          Country,
          | 'id'
          | 'two_letter_abbreviation'
          | 'three_letter_abbreviation'
          | 'full_name_locale'
          | 'full_name_english'
        > & {
            available_regions?: Maybe<
              Array<Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'code' | 'name'>>>
            >
          }
      >
    >
  >
}

export type CreateCustomerAddressMutationVariables = Exact<{
  input: CustomerAddressInput
}>

export type CreateCustomerAddressMutation = { __typename?: 'Mutation' } & {
  createCustomerAddress?: Maybe<{ __typename?: 'CustomerAddress' } & CustomerAddressFragment>
}

export type DeleteCustomerAddressMutationVariables = Exact<{
  id: Scalars['Int']
}>

export type DeleteCustomerAddressMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteCustomerAddress'
>

export type UpdateCustomerAddressMutationVariables = Exact<{
  id: Scalars['Int']
  input?: Maybe<CustomerAddressInput>
}>

export type UpdateCustomerAddressMutation = { __typename?: 'Mutation' } & {
  updateCustomerAddress?: Maybe<{ __typename?: 'CustomerAddress' } & CustomerAddressFragment>
}

export type CustomerAddressFragment = { __typename?: 'CustomerAddress' } & Pick<
  CustomerAddress,
  | 'id'
  | 'country_code'
  | 'street'
  | 'telephone'
  | 'postcode'
  | 'company'
  | 'city'
  | 'firstname'
  | 'lastname'
  | 'default_shipping'
  | 'default_billing'
> & {
    region?: Maybe<
      { __typename?: 'CustomerAddressRegion' } & Pick<
        CustomerAddressRegion,
        'region' | 'region_code'
      >
    >
  }

export type GetCustomerAddressesQueryVariables = Exact<{ [key: string]: never }>

export type GetCustomerAddressesQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & Pick<
      Customer,
      'default_billing' | 'default_shipping' | 'email'
    > & {
        addresses?: Maybe<
          Array<Maybe<{ __typename?: 'CustomerAddress' } & CustomerAddressFragment>>
        >
      }
  >
}

export type CreateCustomerCardMutationVariables = Exact<{
  input: TokenBaseCardCreateInput
}>

export type CreateCustomerCardMutation = { __typename?: 'Mutation' } & {
  createTokenBaseCard?: Maybe<{ __typename?: 'TokenBaseCard' } & TokenBaseCardFragment>
}

export type DeleteCustomerCardMutationVariables = Exact<{
  hash: Scalars['String']
}>

export type DeleteCustomerCardMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteTokenBaseCard'
>

export type AddSimpleAndVirtualProductsToCartMutationVariables = Exact<{
  cartId: Scalars['String']
  simpleCartItems: Array<Maybe<SimpleProductCartItemInput>> | Maybe<SimpleProductCartItemInput>
  virtualCartItems: Array<Maybe<VirtualProductCartItemInput>> | Maybe<VirtualProductCartItemInput>
}>

export type AddSimpleAndVirtualProductsToCartMutation = { __typename?: 'Mutation' } & {
  addSimpleProductsToCart?: Maybe<
    { __typename?: 'AddSimpleProductsToCartOutput' } & {
      cart: { __typename?: 'Cart' } & Pick<Cart, 'id'>
    }
  >
  addVirtualProductsToCart?: Maybe<
    { __typename?: 'AddVirtualProductsToCartOutput' } & {
      cart: { __typename?: 'Cart' } & CartFragment
    }
  >
}

export type AddSimpleProductsToCartMutationVariables = Exact<{
  cartId: Scalars['String']
  cartItems: Array<Maybe<SimpleProductCartItemInput>> | Maybe<SimpleProductCartItemInput>
}>

export type AddSimpleProductsToCartMutation = { __typename?: 'Mutation' } & {
  addSimpleProductsToCart?: Maybe<
    { __typename?: 'AddSimpleProductsToCartOutput' } & {
      cart: { __typename?: 'Cart' } & CartFragment
    }
  >
}

export type ApplyCouponToCartMutationVariables = Exact<{
  cartId: Scalars['String']
  couponCode: Scalars['String']
}>

export type ApplyCouponToCartMutation = { __typename?: 'Mutation' } & {
  applyCouponToCart?: Maybe<
    { __typename?: 'ApplyCouponToCartOutput' } & { cart: { __typename?: 'Cart' } & CartFragment }
  >
}

export type SetBillingAddressAndPaymentMethodOnCartMutationVariables = Exact<{
  cartId: Scalars['String']
  billingAddress: BillingAddressInput
  paymentMethod: PaymentMethodInput
}>

export type SetBillingAddressAndPaymentMethodOnCartMutation = { __typename?: 'Mutation' } & {
  setBillingAddressOnCart?: Maybe<
    { __typename?: 'SetBillingAddressOnCartOutput' } & {
      cart: { __typename?: 'Cart' } & Pick<Cart, 'id'>
    }
  >
  setPaymentMethodOnCart?: Maybe<
    { __typename?: 'SetPaymentMethodOnCartOutput' } & {
      cart: { __typename?: 'Cart' } & CartFragment
    }
  >
}

export type SetBillingAddressOnCartMutationVariables = Exact<{
  cartId: Scalars['String']
  billingAddress: BillingAddressInput
}>

export type SetBillingAddressOnCartMutation = { __typename?: 'Mutation' } & {
  setBillingAddressOnCart?: Maybe<
    { __typename?: 'SetBillingAddressOnCartOutput' } & {
      cart: { __typename?: 'Cart' } & CartFragment
    }
  >
}

export type CreateEmptyCartMutationVariables = Exact<{ [key: string]: never }>

export type CreateEmptyCartMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'createEmptyCart'
>

export type SetDropShipOnCartMutationVariables = Exact<{
  cartId: Scalars['String']
  dropShip: Scalars['String']
}>

export type SetDropShipOnCartMutation = { __typename?: 'Mutation' } & {
  setDropShipOnCart?: Maybe<
    { __typename?: 'SetDropShipOnCartOutput' } & { cart: { __typename?: 'Cart' } & CartFragment }
  >
}

export type GetGuestCartQueryVariables = Exact<{
  cartId: Scalars['String']
}>

export type GetGuestCartQuery = { __typename?: 'Query' } & {
  cart?: Maybe<{ __typename?: 'Cart' } & CartFragment>
}

export type SetHoldUntilMondayOnCartMutationVariables = Exact<{
  cartId: Scalars['String']
  holdUntilMonday: Scalars['String']
}>

export type SetHoldUntilMondayOnCartMutation = { __typename?: 'Mutation' } & {
  setHoldUntilMondayOnCart?: Maybe<
    { __typename?: 'SetHoldUntilMondayOnCartOutput' } & {
      cart: { __typename?: 'Cart' } & CartFragment
    }
  >
}

export type MergeCartsMutationVariables = Exact<{
  sourceCartId: Scalars['String']
  destinationCartId: Scalars['String']
}>

export type MergeCartsMutation = { __typename?: 'Mutation' } & {
  mergeCarts: { __typename?: 'Cart' } & CartFragment
}

export type SetPaymentMethodOnCartMutationVariables = Exact<{
  cartId: Scalars['String']
  paymentMethod: PaymentMethodInput
}>

export type SetPaymentMethodOnCartMutation = { __typename?: 'Mutation' } & {
  setPaymentMethodOnCart?: Maybe<
    { __typename?: 'SetPaymentMethodOnCartOutput' } & {
      cart: { __typename?: 'Cart' } & CartFragment
    }
  >
}

export type RemoveCouponFromCartMutationVariables = Exact<{
  cartId: Scalars['String']
  couponCode?: Maybe<Scalars['String']>
}>

export type RemoveCouponFromCartMutation = { __typename?: 'Mutation' } & {
  removeCouponFromCart?: Maybe<
    { __typename?: 'RemoveCouponFromCartOutput' } & {
      cart?: Maybe<{ __typename?: 'Cart' } & CartFragment>
    }
  >
}

export type RemoveItemFromCartMutationVariables = Exact<{
  cartId: Scalars['String']
  itemId: Scalars['Int']
}>

export type RemoveItemFromCartMutation = { __typename?: 'Mutation' } & {
  removeItemFromCart?: Maybe<
    { __typename?: 'RemoveItemFromCartOutput' } & { cart: { __typename?: 'Cart' } & CartFragment }
  >
}

export type SetCartAttributesMutationVariables = Exact<{
  cartId: Scalars['String']
  practitionerCode?: Maybe<Scalars['String']>
  wellWorldId?: Maybe<Scalars['String']>
  wellWorldPracticeId?: Maybe<Scalars['String']>
  wellWorldPractitionerId?: Maybe<Scalars['String']>
  wellWorldStoreId?: Maybe<Scalars['String']>
  dispensaryPromoCode?: Maybe<Scalars['String']>
  eScriptCampaign?: Maybe<Scalars['String']>
}>

export type SetCartAttributesMutation = { __typename?: 'Mutation' } & {
  setCartAttributes?: Maybe<
    { __typename?: 'SetCartAttributesOnCartOutput' } & {
      cart: { __typename?: 'Cart' } & CartFragment
    }
  >
}

export type SetDispensaryPromoCodeOnCartMutationVariables = Exact<{
  cartId: Scalars['String']
  dispensaryPromoCode: Scalars['String']
}>

export type SetDispensaryPromoCodeOnCartMutation = { __typename?: 'Mutation' } & {
  setDispensaryPromoCodeOnCart?: Maybe<
    { __typename?: 'SetDispensaryPromoCodeOnCartOutput' } & {
      cart: { __typename?: 'Cart' } & CartFragment
    }
  >
}

export type SetShippingAddressesOnCartMutationVariables = Exact<{
  cartId: Scalars['String']
  shippingAddress: Array<Maybe<ShippingAddressInput>> | Maybe<ShippingAddressInput>
}>

export type SetShippingAddressesOnCartMutation = { __typename?: 'Mutation' } & {
  setShippingAddressesOnCart?: Maybe<
    { __typename?: 'SetShippingAddressesOnCartOutput' } & {
      cart: { __typename?: 'Cart' } & CartFragment
    }
  >
}

export type SetShippingMethodsOnCartMutationVariables = Exact<{
  cartId: Scalars['String']
  shippingMethods: Array<Maybe<ShippingMethodInput>> | Maybe<ShippingMethodInput>
}>

export type SetShippingMethodsOnCartMutation = { __typename?: 'Mutation' } & {
  setShippingMethodsOnCart?: Maybe<
    { __typename?: 'SetShippingMethodsOnCartOutput' } & {
      cart: { __typename?: 'Cart' } & CartFragment
    }
  >
}

export type UpdateCartItemsMutationVariables = Exact<{
  cartId: Scalars['String']
  cartItems: Array<Maybe<CartItemUpdateInput>> | Maybe<CartItemUpdateInput>
}>

export type UpdateCartItemsMutation = { __typename?: 'Mutation' } & {
  updateCartItems?: Maybe<
    { __typename?: 'UpdateCartItemsOutput' } & { cart: { __typename?: 'Cart' } & CartFragment }
  >
}

export type CartFragment = { __typename?: 'Cart' } & Pick<
  Cart,
  | 'id'
  | 'hold_until_monday'
  | 'drop_ship'
  | 'email'
  | 'total_quantity'
  | 'dispensary_promo_code'
  | 'practitioner_code'
  | 'ww_id'
  | 'ww_store_id'
  | 'ww_practice_id'
  | 'ww_practitioner_id'
  | 'e_script_campaign'
> & {
    available_payment_methods?: Maybe<
      Array<
        Maybe<
          { __typename?: 'AvailablePaymentMethod' } & Pick<AvailablePaymentMethod, 'code' | 'title'>
        >
      >
    >
    billing_address?: Maybe<
      { __typename?: 'BillingCartAddress' } & Pick<
        BillingCartAddress,
        'city' | 'firstname' | 'lastname' | 'postcode' | 'street' | 'telephone'
      > & {
          country: { __typename?: 'CartAddressCountry' } & Pick<
            CartAddressCountry,
            'code' | 'label'
          >
          region?: Maybe<
            { __typename?: 'CartAddressRegion' } & Pick<CartAddressRegion, 'code' | 'label'>
          >
        }
    >
    items?: Maybe<
      Array<
        Maybe<
          | ({ __typename?: 'BundleCartItem' } & Pick<BundleCartItem, 'id' | 'quantity'> & {
                prices?: Maybe<
                  { __typename?: 'CartItemPrices' } & {
                    price: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                    row_total: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                  }
                >
                item_attributes?: Maybe<
                  { __typename?: 'CartItemAttributes' } & Pick<
                    CartItemAttributes,
                    | 'size'
                    | 'thumbnail'
                    | 'dfh_autoship_interval'
                    | 'dfh_order_type'
                    | 'description'
                    | 'dosage'
                    | 'flavor'
                    | 'flavor_size'
                    | 'heat_sensitive'
                    | 'prop_sixty_five'
                    | 'short_description'
                    | 'prop65_message'
                  >
                >
                product:
                  | ({ __typename?: 'BundleProduct' } & Pick<
                      BundleProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'ConfigurableProduct' } & Pick<
                      ConfigurableProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'DownloadableProduct' } & Pick<
                      DownloadableProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'GroupedProduct' } & Pick<
                      GroupedProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'SimpleProduct' } & Pick<
                      SimpleProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'StoreCreditProduct' } & Pick<
                      StoreCreditProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'VirtualProduct' } & Pick<
                      VirtualProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
              })
          | ({ __typename?: 'ConfigurableCartItem' } & Pick<
              ConfigurableCartItem,
              'id' | 'quantity'
            > & {
                prices?: Maybe<
                  { __typename?: 'CartItemPrices' } & {
                    price: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                    row_total: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                  }
                >
                item_attributes?: Maybe<
                  { __typename?: 'CartItemAttributes' } & Pick<
                    CartItemAttributes,
                    | 'size'
                    | 'thumbnail'
                    | 'dfh_autoship_interval'
                    | 'dfh_order_type'
                    | 'description'
                    | 'dosage'
                    | 'flavor'
                    | 'flavor_size'
                    | 'heat_sensitive'
                    | 'prop_sixty_five'
                    | 'short_description'
                    | 'prop65_message'
                  >
                >
                product:
                  | ({ __typename?: 'BundleProduct' } & Pick<
                      BundleProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'ConfigurableProduct' } & Pick<
                      ConfigurableProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'DownloadableProduct' } & Pick<
                      DownloadableProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'GroupedProduct' } & Pick<
                      GroupedProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'SimpleProduct' } & Pick<
                      SimpleProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'StoreCreditProduct' } & Pick<
                      StoreCreditProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'VirtualProduct' } & Pick<
                      VirtualProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
              })
          | ({ __typename?: 'DownloadableCartItem' } & Pick<
              DownloadableCartItem,
              'id' | 'quantity'
            > & {
                prices?: Maybe<
                  { __typename?: 'CartItemPrices' } & {
                    price: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                    row_total: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                  }
                >
                item_attributes?: Maybe<
                  { __typename?: 'CartItemAttributes' } & Pick<
                    CartItemAttributes,
                    | 'size'
                    | 'thumbnail'
                    | 'dfh_autoship_interval'
                    | 'dfh_order_type'
                    | 'description'
                    | 'dosage'
                    | 'flavor'
                    | 'flavor_size'
                    | 'heat_sensitive'
                    | 'prop_sixty_five'
                    | 'short_description'
                    | 'prop65_message'
                  >
                >
                product:
                  | ({ __typename?: 'BundleProduct' } & Pick<
                      BundleProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'ConfigurableProduct' } & Pick<
                      ConfigurableProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'DownloadableProduct' } & Pick<
                      DownloadableProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'GroupedProduct' } & Pick<
                      GroupedProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'SimpleProduct' } & Pick<
                      SimpleProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'StoreCreditProduct' } & Pick<
                      StoreCreditProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'VirtualProduct' } & Pick<
                      VirtualProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
              })
          | ({ __typename?: 'SimpleCartItem' } & Pick<SimpleCartItem, 'id' | 'quantity'> & {
                prices?: Maybe<
                  { __typename?: 'CartItemPrices' } & {
                    price: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                    row_total: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                  }
                >
                item_attributes?: Maybe<
                  { __typename?: 'CartItemAttributes' } & Pick<
                    CartItemAttributes,
                    | 'size'
                    | 'thumbnail'
                    | 'dfh_autoship_interval'
                    | 'dfh_order_type'
                    | 'description'
                    | 'dosage'
                    | 'flavor'
                    | 'flavor_size'
                    | 'heat_sensitive'
                    | 'prop_sixty_five'
                    | 'short_description'
                    | 'prop65_message'
                  >
                >
                product:
                  | ({ __typename?: 'BundleProduct' } & Pick<
                      BundleProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'ConfigurableProduct' } & Pick<
                      ConfigurableProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'DownloadableProduct' } & Pick<
                      DownloadableProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'GroupedProduct' } & Pick<
                      GroupedProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'SimpleProduct' } & Pick<
                      SimpleProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'StoreCreditProduct' } & Pick<
                      StoreCreditProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'VirtualProduct' } & Pick<
                      VirtualProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
              })
          | ({ __typename?: 'VirtualCartItem' } & Pick<VirtualCartItem, 'id' | 'quantity'> & {
                customizable_options?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'SelectedCustomizableOption' } & Pick<
                        SelectedCustomizableOption,
                        'id' | 'label' | 'sort_order'
                      > & {
                          values: Array<
                            Maybe<
                              { __typename?: 'SelectedCustomizableOptionValue' } & Pick<
                                SelectedCustomizableOptionValue,
                                'id' | 'label' | 'value'
                              >
                            >
                          >
                        }
                    >
                  >
                >
                prices?: Maybe<
                  { __typename?: 'CartItemPrices' } & {
                    price: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                    row_total: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                  }
                >
                item_attributes?: Maybe<
                  { __typename?: 'CartItemAttributes' } & Pick<
                    CartItemAttributes,
                    | 'size'
                    | 'thumbnail'
                    | 'dfh_autoship_interval'
                    | 'dfh_order_type'
                    | 'description'
                    | 'dosage'
                    | 'flavor'
                    | 'flavor_size'
                    | 'heat_sensitive'
                    | 'prop_sixty_five'
                    | 'short_description'
                    | 'prop65_message'
                  >
                >
                product:
                  | ({ __typename?: 'BundleProduct' } & Pick<
                      BundleProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'ConfigurableProduct' } & Pick<
                      ConfigurableProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'DownloadableProduct' } & Pick<
                      DownloadableProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'GroupedProduct' } & Pick<
                      GroupedProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'SimpleProduct' } & Pick<
                      SimpleProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'StoreCreditProduct' } & Pick<
                      StoreCreditProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
                  | ({ __typename?: 'VirtualProduct' } & Pick<
                      VirtualProduct,
                      | 'name'
                      | 'sku'
                      | 'url_key'
                      | 'natural_product_number'
                      | 'stock_status'
                      | 'out_of_stock_message'
                    > & {
                        stock_configuration?: Maybe<
                          { __typename?: 'StockItemConfiguration' } & Pick<
                            StockItemConfiguration,
                            'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                          >
                        >
                      })
              })
        >
      >
    >
    prices?: Maybe<
      { __typename?: 'CartPrices' } & {
        applied_taxes?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CartTaxItem' } & Pick<CartTaxItem, 'label'> & {
                  amount: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                }
            >
          >
        >
        colorado_retail_delivery_fee?: Maybe<
          { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
        >
        discounts?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Discount' } & Pick<Discount, 'label'> & {
                  amount: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                }
            >
          >
        >
        grand_total?: Maybe<{ __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>>
        subtotal_excluding_tax?: Maybe<{ __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>>
        subtotal_including_tax?: Maybe<{ __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>>
        subtotal_with_discount_excluding_tax?: Maybe<
          { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
        >
      }
    >
    applied_coupons?: Maybe<
      Array<Maybe<{ __typename?: 'AppliedCoupon' } & Pick<AppliedCoupon, 'code'>>>
    >
    rebate?: Maybe<{ __typename?: 'Money' } & Pick<Money, 'value'>>
    shipping_addresses: Array<
      Maybe<
        { __typename?: 'ShippingCartAddress' } & Pick<
          ShippingCartAddress,
          'city' | 'firstname' | 'lastname' | 'postcode' | 'street' | 'telephone'
        > & {
            country: { __typename?: 'CartAddressCountry' } & Pick<
              CartAddressCountry,
              'code' | 'label'
            >
            region?: Maybe<
              { __typename?: 'CartAddressRegion' } & Pick<CartAddressRegion, 'code' | 'label'>
            >
            available_shipping_methods?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'AvailableShippingMethod' } & Pick<
                    AvailableShippingMethod,
                    | 'available'
                    | 'carrier_code'
                    | 'carrier_title'
                    | 'error_message'
                    | 'method_code'
                    | 'method_title'
                  > & { amount: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'> }
                >
              >
            >
            selected_shipping_method?: Maybe<
              { __typename?: 'SelectedShippingMethod' } & Pick<
                SelectedShippingMethod,
                'carrier_code' | 'carrier_title' | 'method_code' | 'method_title'
              > & { amount: { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'> }
            >
          }
      >
    >
    selected_payment_method?: Maybe<
      { __typename?: 'SelectedPaymentMethod' } & Pick<
        SelectedPaymentMethod,
        'code' | 'purchase_order_number' | 'title' | 'tokenbase_card_id'
      > & {
          tokenbase_data?: Maybe<
            { __typename?: 'TokenBaseCardAdditional' } & Pick<
              TokenBaseCardAdditional,
              'cc_last4' | 'cc_exp_year' | 'cc_exp_month' | 'cc_type'
            >
          >
        }
    >
  }

export type GetCustomerCartQueryVariables = Exact<{ [key: string]: never }>

export type GetCustomerCartQuery = { __typename?: 'Query' } & {
  customerCart: { __typename?: 'Cart' } & CartFragment
}

export type CybersourceCardListQueryVariables = Exact<{ [key: string]: never }>

export type CybersourceCardListQuery = { __typename?: 'Query' } & {
  tokenBaseCards?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TokenBaseCard' } & Pick<TokenBaseCard, 'hash' | 'label'> & {
            additional?: Maybe<
              { __typename?: 'TokenBaseCardAdditional' } & Pick<
                TokenBaseCardAdditional,
                'cc_exp_month' | 'cc_exp_year' | 'cc_last4' | 'cc_type'
              >
            >
            address?: Maybe<
              { __typename?: 'CustomerAddress' } & Pick<
                CustomerAddress,
                | 'city'
                | 'company'
                | 'country_code'
                | 'country_id'
                | 'firstname'
                | 'lastname'
                | 'postcode'
                | 'street'
                | 'telephone'
              > & {
                  region?: Maybe<
                    { __typename?: 'CustomerAddressRegion' } & Pick<
                      CustomerAddressRegion,
                      'region' | 'region_code' | 'region_id'
                    >
                  >
                }
            >
          }
      >
    >
  >
}

export type PlaceOrderWithPaymentMethodMutationVariables = Exact<{
  cartId: Scalars['String']
  paymentMethod: PaymentMethodInput
}>

export type PlaceOrderWithPaymentMethodMutation = { __typename?: 'Mutation' } & {
  setPaymentMethodOnCart?: Maybe<
    { __typename?: 'SetPaymentMethodOnCartOutput' } & {
      cart: { __typename?: 'Cart' } & Pick<Cart, 'id'> & {
          selected_payment_method?: Maybe<
            { __typename?: 'SelectedPaymentMethod' } & Pick<
              SelectedPaymentMethod,
              'code' | 'purchase_order_number' | 'title' | 'tokenbase_card_id'
            > & {
                tokenbase_data?: Maybe<
                  { __typename?: 'TokenBaseCardAdditional' } & Pick<
                    TokenBaseCardAdditional,
                    'cc_last4' | 'cc_exp_year' | 'cc_exp_month' | 'cc_type'
                  >
                >
              }
          >
        }
    }
  >
  placeOrder?: Maybe<
    { __typename?: 'PlaceOrderOutput' } & {
      order: { __typename?: 'Order' } & Pick<Order, 'order_number'>
    }
  >
}

export type PlaceOrderMutationVariables = Exact<{
  cartId: Scalars['String']
}>

export type PlaceOrderMutation = { __typename?: 'Mutation' } & {
  placeOrder?: Maybe<
    { __typename?: 'PlaceOrderOutput' } & {
      order: { __typename?: 'Order' } & Pick<Order, 'order_number'>
    }
  >
}

export type ApplyCustomerStoreCreditMutationVariables = Exact<{
  cartId: Scalars['String']
  amount: Scalars['Float']
}>

export type ApplyCustomerStoreCreditMutation = { __typename?: 'Mutation' } & {
  MpStoreCreditCustomerSpending?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TotalSegmentsOutput' } & Pick<
          TotalSegmentsOutput,
          'code' | 'title' | 'value'
        >
      >
    >
  >
}

export type UpdateCustomerStoreCreditMutationVariables = Exact<{
  amount: Scalars['Float']
}>

export type UpdateCustomerStoreCreditMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'MpStoreCreditCustomerUpdate'
>

export type GetCustomerStoreCreditQueryVariables = Exact<{ [key: string]: never }>

export type GetCustomerStoreCreditQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & Pick<Customer, 'email'> & {
        mp_store_credit?: Maybe<
          { __typename?: 'MpStoreCreditCustomersOutput' } & Pick<
            MpStoreCreditCustomersOutput,
            'mp_credit_balance'
          >
        >
      }
  >
}

export type TokenBaseCardFragment = { __typename?: 'TokenBaseCard' } & Pick<
  TokenBaseCard,
  'active' | 'hash' | 'label' | 'last_use' | 'updated_at'
> & {
    additional?: Maybe<
      { __typename?: 'TokenBaseCardAdditional' } & Pick<
        TokenBaseCardAdditional,
        'cc_bin' | 'cc_exp_month' | 'cc_exp_year' | 'cc_last4' | 'cc_owner' | 'cc_type'
      >
    >
    address?: Maybe<
      { __typename?: 'CustomerAddress' } & Pick<
        CustomerAddress,
        | 'city'
        | 'company'
        | 'country_code'
        | 'country_id'
        | 'firstname'
        | 'lastname'
        | 'postcode'
        | 'street'
        | 'telephone'
      > & {
          region?: Maybe<
            { __typename?: 'CustomerAddressRegion' } & Pick<
              CustomerAddressRegion,
              'region' | 'region_code'
            >
          >
        }
    >
  }

export type TokenBaseCardsQueryVariables = Exact<{ [key: string]: never }>

export type TokenBaseCardsQuery = { __typename?: 'Query' } & {
  tokenBaseCards?: Maybe<Array<Maybe<{ __typename?: 'TokenBaseCard' } & TokenBaseCardFragment>>>
}

export type CyberSourceFlexKeyMutationVariables = Exact<{ [key: string]: never }>

export type CyberSourceFlexKeyMutation = { __typename?: 'Mutation' } & {
  generateFlexJsToken?: Maybe<
    { __typename?: 'GenerateFlexJsTokenOutput' } & Pick<
      GenerateFlexJsTokenOutput,
      'key_id' | 'key_store'
    >
  >
}

export type GetAuthenticatedProductsQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>
  currentPage?: Maybe<Scalars['Int']>
  filterInput?: Maybe<ProductAttributeFilterInput>
  search?: Maybe<Scalars['String']>
  sort?: Maybe<ProductAttributeSortInput>
}>

export type GetAuthenticatedProductsQuery = { __typename?: 'Query' } & {
  products?: Maybe<
    { __typename?: 'Products' } & Pick<Products, 'total_count'> & {
        page_info?: Maybe<
          { __typename?: 'SearchResultPageInfo' } & Pick<
            SearchResultPageInfo,
            'page_size' | 'total_pages' | 'current_page'
          >
        >
        aggregations?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Aggregation' } & Pick<
                Aggregation,
                'attribute_code' | 'label' | 'count'
              > & {
                  options?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'AggregationOption' } & Pick<
                          AggregationOption,
                          'label' | 'value' | 'count'
                        >
                      >
                    >
                  >
                }
            >
          >
        >
        items?: Maybe<
          Array<
            Maybe<
              | ({ __typename?: 'BundleProduct' } & Pick<
                  BundleProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
              | ({ __typename?: 'ConfigurableProduct' } & Pick<
                  ConfigurableProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    variant_index?: Maybe<
                      { __typename?: 'ConfigurableVariantIndex' } & Pick<
                        ConfigurableVariantIndex,
                        | 'used_attributes'
                        | 'attribute_values'
                        | 'mapping'
                        | 'product_index'
                        | 'attribute_product_list'
                      >
                    >
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
              | ({ __typename?: 'DownloadableProduct' } & Pick<
                  DownloadableProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
              | ({ __typename?: 'GroupedProduct' } & Pick<
                  GroupedProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
              | ({ __typename?: 'SimpleProduct' } & Pick<
                  SimpleProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
              | ({ __typename?: 'StoreCreditProduct' } & Pick<
                  StoreCreditProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
              | ({ __typename?: 'VirtualProduct' } & Pick<
                  VirtualProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
            >
          >
        >
      }
  >
}

export type GetBuilderProductsQueryVariables = Exact<{
  sku?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>
}>

export type GetBuilderProductsQuery = { __typename?: 'Query' } & {
  products?: Maybe<
    { __typename?: 'Products' } & {
      items?: Maybe<
        Array<
          Maybe<
            | ({ __typename?: 'BundleProduct' } & Pick<BundleProduct, 'id' | 'name' | 'sku'> & {
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'label' | 'url'>
                  >
                })
            | ({ __typename?: 'ConfigurableProduct' } & Pick<
                ConfigurableProduct,
                'id' | 'name' | 'sku'
              > & {
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'label' | 'url'>
                  >
                })
            | ({ __typename?: 'DownloadableProduct' } & Pick<
                DownloadableProduct,
                'id' | 'name' | 'sku'
              > & {
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'label' | 'url'>
                  >
                })
            | ({ __typename?: 'GroupedProduct' } & Pick<GroupedProduct, 'id' | 'name' | 'sku'> & {
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'label' | 'url'>
                  >
                })
            | ({ __typename?: 'SimpleProduct' } & Pick<
                SimpleProduct,
                'url_key' | 'id' | 'name' | 'sku'
              > & {
                  price_tiers?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'TierPrice' } & {
                          final_price?: Maybe<
                            { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                          >
                        }
                      >
                    >
                  >
                  variant_index?: Maybe<
                    { __typename?: 'SimpleVariantIndex' } & Pick<
                      SimpleVariantIndex,
                      | 'used_attributes'
                      | 'attribute_values'
                      | 'description'
                      | 'short_description'
                      | 'thumbnail'
                    >
                  >
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'label' | 'url'>
                  >
                })
            | ({ __typename?: 'StoreCreditProduct' } & Pick<
                StoreCreditProduct,
                'id' | 'name' | 'sku'
              > & {
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'label' | 'url'>
                  >
                })
            | ({ __typename?: 'VirtualProduct' } & Pick<VirtualProduct, 'id' | 'name' | 'sku'> & {
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'label' | 'url'>
                  >
                })
          >
        >
      >
    }
  >
}

export type GetGuestProductsQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>
  currentPage?: Maybe<Scalars['Int']>
  filterInput?: Maybe<ProductAttributeFilterInput>
  search?: Maybe<Scalars['String']>
  sort?: Maybe<ProductAttributeSortInput>
}>

export type GetGuestProductsQuery = { __typename?: 'Query' } & {
  products?: Maybe<
    { __typename?: 'Products' } & Pick<Products, 'total_count'> & {
        page_info?: Maybe<
          { __typename?: 'SearchResultPageInfo' } & Pick<
            SearchResultPageInfo,
            'page_size' | 'total_pages' | 'current_page'
          >
        >
        aggregations?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Aggregation' } & Pick<
                Aggregation,
                'attribute_code' | 'label' | 'count'
              > & {
                  options?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'AggregationOption' } & Pick<
                          AggregationOption,
                          'label' | 'value' | 'count'
                        >
                      >
                    >
                  >
                }
            >
          >
        >
        items?: Maybe<
          Array<
            Maybe<
              | ({ __typename?: 'BundleProduct' } & Pick<
                  BundleProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
              | ({ __typename?: 'ConfigurableProduct' } & Pick<
                  ConfigurableProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    variant_index?: Maybe<
                      { __typename?: 'ConfigurableVariantIndex' } & Pick<
                        ConfigurableVariantIndex,
                        | 'used_attributes'
                        | 'attribute_values'
                        | 'mapping'
                        | 'product_index'
                        | 'attribute_product_list'
                      >
                    >
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
              | ({ __typename?: 'DownloadableProduct' } & Pick<
                  DownloadableProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
              | ({ __typename?: 'GroupedProduct' } & Pick<
                  GroupedProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
              | ({ __typename?: 'SimpleProduct' } & Pick<
                  SimpleProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
              | ({ __typename?: 'StoreCreditProduct' } & Pick<
                  StoreCreditProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
              | ({ __typename?: 'VirtualProduct' } & Pick<
                  VirtualProduct,
                  'id' | 'name' | 'stock_status' | 'url_key' | 'product_type_id' | 'specialty'
                > & {
                    image?: Maybe<
                      { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                    >
                  })
            >
          >
        >
      }
  >
}

export type GetOrderProductsQueryVariables = Exact<{
  sku?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>
}>

export type GetOrderProductsQuery = { __typename?: 'Query' } & {
  products?: Maybe<
    { __typename?: 'Products' } & {
      items?: Maybe<
        Array<
          Maybe<
            | ({ __typename?: 'BundleProduct' } & Pick<
                BundleProduct,
                'id' | 'name' | 'sku' | 'natural_product_number'
              > & {
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                })
            | ({ __typename?: 'ConfigurableProduct' } & Pick<
                ConfigurableProduct,
                'id' | 'name' | 'sku' | 'natural_product_number'
              > & {
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                })
            | ({ __typename?: 'DownloadableProduct' } & Pick<
                DownloadableProduct,
                'id' | 'name' | 'sku' | 'natural_product_number'
              > & {
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                })
            | ({ __typename?: 'GroupedProduct' } & Pick<
                GroupedProduct,
                'id' | 'name' | 'sku' | 'natural_product_number'
              > & {
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                })
            | ({ __typename?: 'SimpleProduct' } & Pick<
                SimpleProduct,
                'url_key' | 'id' | 'name' | 'sku' | 'natural_product_number'
              > & {
                  price_tiers?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'TierPrice' } & {
                          final_price?: Maybe<
                            { __typename?: 'Money' } & Pick<Money, 'currency' | 'value'>
                          >
                        }
                      >
                    >
                  >
                  stock_configuration?: Maybe<
                    { __typename?: 'StockItemConfiguration' } & Pick<
                      StockItemConfiguration,
                      'min_sale_qty'
                    >
                  >
                  variant_index?: Maybe<
                    { __typename?: 'SimpleVariantIndex' } & Pick<
                      SimpleVariantIndex,
                      | 'used_attributes'
                      | 'attribute_values'
                      | 'description'
                      | 'short_description'
                      | 'thumbnail'
                    >
                  >
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                })
            | ({ __typename?: 'StoreCreditProduct' } & Pick<
                StoreCreditProduct,
                'id' | 'name' | 'sku' | 'natural_product_number'
              > & {
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                })
            | ({ __typename?: 'VirtualProduct' } & Pick<
                VirtualProduct,
                'id' | 'name' | 'sku' | 'natural_product_number'
              > & {
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                })
          >
        >
      >
    }
  >
}

export type GetProductsByUrlKeyQueryVariables = Exact<{
  urlKey?: Maybe<Scalars['String']>
}>

export type GetProductsByUrlKeyQuery = { __typename?: 'Query' } & {
  products?: Maybe<
    { __typename?: 'Products' } & {
      items?: Maybe<
        Array<
          Maybe<
            | ({ __typename?: 'BundleProduct' } & Pick<
                BundleProduct,
                | 'id'
                | 'name'
                | 'sku'
                | 'natural_product_number'
                | 'stock_status'
                | 'product_form'
                | 'specialty'
                | 'url_key'
                | 'doc_patient_education'
                | 'doc_tech_sheet'
                | 'doc_clinical_trials'
                | 'advisor_tags'
                | 'content_left_public'
                | 'content_left_patient'
                | 'content_left_professional'
                | 'content_left_both'
                | 'content_right_public'
                | 'content_right_patient'
                | 'content_right_professional'
                | 'content_right_both'
              > & {
                  description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  short_description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                  media_gallery?: Maybe<
                    Array<
                      Maybe<
                        | ({ __typename?: 'ProductImage' } & Pick<
                            ProductImage,
                            'url' | 'position' | 'label'
                          >)
                        | ({ __typename?: 'ProductVideo' } & Pick<
                            ProductVideo,
                            'url' | 'position' | 'label'
                          >)
                      >
                    >
                  >
                })
            | ({ __typename?: 'ConfigurableProduct' } & Pick<
                ConfigurableProduct,
                | 'id'
                | 'name'
                | 'sku'
                | 'natural_product_number'
                | 'stock_status'
                | 'product_form'
                | 'specialty'
                | 'url_key'
                | 'doc_patient_education'
                | 'doc_tech_sheet'
                | 'doc_clinical_trials'
                | 'advisor_tags'
                | 'content_left_public'
                | 'content_left_patient'
                | 'content_left_professional'
                | 'content_left_both'
                | 'content_right_public'
                | 'content_right_patient'
                | 'content_right_professional'
                | 'content_right_both'
              > & {
                  variant_index?: Maybe<
                    { __typename?: 'ConfigurableVariantIndex' } & Pick<
                      ConfigurableVariantIndex,
                      | 'used_attributes'
                      | 'attribute_values'
                      | 'mapping'
                      | 'product_index'
                      | 'attribute_product_list'
                    >
                  >
                  variants?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ConfigurableVariant' } & {
                          product?: Maybe<
                            { __typename?: 'SimpleProduct' } & Pick<
                              SimpleProduct,
                              | 'weight'
                              | 'id'
                              | 'name'
                              | 'sku'
                              | 'natural_product_number'
                              | 'specialty'
                              | 'stock_status'
                              | 'out_of_stock_message'
                              | 'doc_patient_education'
                              | 'doc_tech_sheet'
                              | 'doc_clinical_trials'
                              | 'advisor_tags'
                              | 'content_left_public'
                              | 'content_left_patient'
                              | 'content_left_professional'
                              | 'content_left_both'
                              | 'content_right_public'
                              | 'content_right_patient'
                              | 'content_right_professional'
                              | 'content_right_both'
                            > & {
                                stock_configuration?: Maybe<
                                  { __typename?: 'StockItemConfiguration' } & Pick<
                                    StockItemConfiguration,
                                    'min_sale_qty' | 'max_sale_qty' | 'qty_increments'
                                  >
                                >
                                description?: Maybe<
                                  { __typename?: 'ComplexTextValue' } & Pick<
                                    ComplexTextValue,
                                    'html'
                                  >
                                >
                                short_description?: Maybe<
                                  { __typename?: 'ComplexTextValue' } & Pick<
                                    ComplexTextValue,
                                    'html'
                                  >
                                >
                                price_tiers?: Maybe<
                                  Array<
                                    Maybe<
                                      { __typename?: 'TierPrice' } & {
                                        final_price?: Maybe<
                                          { __typename?: 'Money' } & Pick<
                                            Money,
                                            'currency' | 'value'
                                          >
                                        >
                                      }
                                    >
                                  >
                                >
                                image?: Maybe<
                                  { __typename?: 'ProductImage' } & Pick<
                                    ProductImage,
                                    'url' | 'label'
                                  >
                                >
                                media_gallery?: Maybe<
                                  Array<
                                    Maybe<
                                      | ({ __typename?: 'ProductImage' } & Pick<
                                          ProductImage,
                                          'url' | 'position' | 'disabled' | 'label'
                                        >)
                                      | ({ __typename?: 'ProductVideo' } & Pick<
                                          ProductVideo,
                                          'url' | 'position' | 'disabled' | 'label'
                                        >)
                                    >
                                  >
                                >
                              }
                          >
                          attributes?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'ConfigurableAttributeOption' } & Pick<
                                  ConfigurableAttributeOption,
                                  'label' | 'code' | 'value_index'
                                >
                              >
                            >
                          >
                        }
                      >
                    >
                  >
                  description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  short_description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                  media_gallery?: Maybe<
                    Array<
                      Maybe<
                        | ({ __typename?: 'ProductImage' } & Pick<
                            ProductImage,
                            'url' | 'position' | 'label'
                          >)
                        | ({ __typename?: 'ProductVideo' } & Pick<
                            ProductVideo,
                            'url' | 'position' | 'label'
                          >)
                      >
                    >
                  >
                })
            | ({ __typename?: 'DownloadableProduct' } & Pick<
                DownloadableProduct,
                | 'id'
                | 'name'
                | 'sku'
                | 'natural_product_number'
                | 'stock_status'
                | 'product_form'
                | 'specialty'
                | 'url_key'
                | 'doc_patient_education'
                | 'doc_tech_sheet'
                | 'doc_clinical_trials'
                | 'advisor_tags'
                | 'content_left_public'
                | 'content_left_patient'
                | 'content_left_professional'
                | 'content_left_both'
                | 'content_right_public'
                | 'content_right_patient'
                | 'content_right_professional'
                | 'content_right_both'
              > & {
                  description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  short_description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                  media_gallery?: Maybe<
                    Array<
                      Maybe<
                        | ({ __typename?: 'ProductImage' } & Pick<
                            ProductImage,
                            'url' | 'position' | 'label'
                          >)
                        | ({ __typename?: 'ProductVideo' } & Pick<
                            ProductVideo,
                            'url' | 'position' | 'label'
                          >)
                      >
                    >
                  >
                })
            | ({ __typename?: 'GroupedProduct' } & Pick<
                GroupedProduct,
                | 'id'
                | 'name'
                | 'sku'
                | 'natural_product_number'
                | 'stock_status'
                | 'product_form'
                | 'specialty'
                | 'url_key'
                | 'doc_patient_education'
                | 'doc_tech_sheet'
                | 'doc_clinical_trials'
                | 'advisor_tags'
                | 'content_left_public'
                | 'content_left_patient'
                | 'content_left_professional'
                | 'content_left_both'
                | 'content_right_public'
                | 'content_right_patient'
                | 'content_right_professional'
                | 'content_right_both'
              > & {
                  description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  short_description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                  media_gallery?: Maybe<
                    Array<
                      Maybe<
                        | ({ __typename?: 'ProductImage' } & Pick<
                            ProductImage,
                            'url' | 'position' | 'label'
                          >)
                        | ({ __typename?: 'ProductVideo' } & Pick<
                            ProductVideo,
                            'url' | 'position' | 'label'
                          >)
                      >
                    >
                  >
                })
            | ({ __typename?: 'SimpleProduct' } & Pick<
                SimpleProduct,
                | 'id'
                | 'name'
                | 'sku'
                | 'natural_product_number'
                | 'stock_status'
                | 'product_form'
                | 'specialty'
                | 'url_key'
                | 'doc_patient_education'
                | 'doc_tech_sheet'
                | 'doc_clinical_trials'
                | 'advisor_tags'
                | 'content_left_public'
                | 'content_left_patient'
                | 'content_left_professional'
                | 'content_left_both'
                | 'content_right_public'
                | 'content_right_patient'
                | 'content_right_professional'
                | 'content_right_both'
              > & {
                  description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  short_description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                  media_gallery?: Maybe<
                    Array<
                      Maybe<
                        | ({ __typename?: 'ProductImage' } & Pick<
                            ProductImage,
                            'url' | 'position' | 'label'
                          >)
                        | ({ __typename?: 'ProductVideo' } & Pick<
                            ProductVideo,
                            'url' | 'position' | 'label'
                          >)
                      >
                    >
                  >
                })
            | ({ __typename?: 'StoreCreditProduct' } & Pick<
                StoreCreditProduct,
                | 'id'
                | 'name'
                | 'sku'
                | 'natural_product_number'
                | 'stock_status'
                | 'product_form'
                | 'specialty'
                | 'url_key'
                | 'doc_patient_education'
                | 'doc_tech_sheet'
                | 'doc_clinical_trials'
                | 'advisor_tags'
                | 'content_left_public'
                | 'content_left_patient'
                | 'content_left_professional'
                | 'content_left_both'
                | 'content_right_public'
                | 'content_right_patient'
                | 'content_right_professional'
                | 'content_right_both'
              > & {
                  description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  short_description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                  media_gallery?: Maybe<
                    Array<
                      Maybe<
                        | ({ __typename?: 'ProductImage' } & Pick<
                            ProductImage,
                            'url' | 'position' | 'label'
                          >)
                        | ({ __typename?: 'ProductVideo' } & Pick<
                            ProductVideo,
                            'url' | 'position' | 'label'
                          >)
                      >
                    >
                  >
                })
            | ({ __typename?: 'VirtualProduct' } & Pick<
                VirtualProduct,
                | 'id'
                | 'name'
                | 'sku'
                | 'natural_product_number'
                | 'stock_status'
                | 'product_form'
                | 'specialty'
                | 'url_key'
                | 'doc_patient_education'
                | 'doc_tech_sheet'
                | 'doc_clinical_trials'
                | 'advisor_tags'
                | 'content_left_public'
                | 'content_left_patient'
                | 'content_left_professional'
                | 'content_left_both'
                | 'content_right_public'
                | 'content_right_patient'
                | 'content_right_professional'
                | 'content_right_both'
              > & {
                  description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  short_description?: Maybe<
                    { __typename?: 'ComplexTextValue' } & Pick<ComplexTextValue, 'html'>
                  >
                  image?: Maybe<
                    { __typename?: 'ProductImage' } & Pick<ProductImage, 'url' | 'label'>
                  >
                  media_gallery?: Maybe<
                    Array<
                      Maybe<
                        | ({ __typename?: 'ProductImage' } & Pick<
                            ProductImage,
                            'url' | 'position' | 'label'
                          >)
                        | ({ __typename?: 'ProductVideo' } & Pick<
                            ProductVideo,
                            'url' | 'position' | 'label'
                          >)
                      >
                    >
                  >
                })
          >
        >
      >
    }
  >
}

export const CustomerAddressFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'customerAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomerAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region_code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default_shipping' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default_billing' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CustomerAddressFragment, unknown>
export const CartFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'cart' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Cart' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hold_until_monday' } },
          { kind: 'Field', name: { kind: 'Name', value: 'drop_ship' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'available_payment_methods' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billing_address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'region' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'telephone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'row_total' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'item_attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dfh_autoship_interval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dfh_order_type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dosage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'flavor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'flavor_size' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heat_sensitive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'prop_sixty_five' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'short_description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'prop65_message' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url_key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stock_configuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'min_sale_qty' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max_sale_qty' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'qty_increments' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'natural_product_number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stock_status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'out_of_stock_message' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VirtualCartItem' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customizable_options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sort_order' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applied_taxes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorado_retail_delivery_fee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grand_total' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtotal_excluding_tax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtotal_including_tax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtotal_with_discount_excluding_tax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applied_coupons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'code' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rebate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipping_addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'region' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'telephone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'available_shipping_methods' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'available' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'carrier_title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'error_message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'method_code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'method_title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selected_shipping_method' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'carrier_title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'method_code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'method_title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selected_payment_method' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'purchase_order_number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tokenbase_card_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokenbase_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cc_last4' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cc_exp_year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cc_exp_month' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cc_type' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'total_quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dispensary_promo_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'practitioner_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ww_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ww_store_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ww_practice_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ww_practitioner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'e_script_campaign' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CartFragment, unknown>
export const TokenBaseCardFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'tokenBaseCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TokenBaseCard' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additional' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cc_bin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cc_exp_month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cc_exp_year' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cc_last4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cc_owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cc_type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'region' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'region_code' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'telephone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_use' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<TokenBaseCardFragment, unknown>
export const CategoryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'category' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ids' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url_path' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'breadcrumbs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'category_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'category_url_key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'category_url_path' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CategoryQuery, CategoryQueryVariables>
export const GetCountriesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCountries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'two_letter_abbreviation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'three_letter_abbreviation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'full_name_locale' } },
                { kind: 'Field', name: { kind: 'Name', value: 'full_name_english' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'available_regions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetCountriesQuery, GetCountriesQueryVariables>
export const CreateCustomerAddressDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCustomerAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomerAddressInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCustomerAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'customerAddress' } },
              ],
            },
          },
        ],
      },
    },
    ...CustomerAddressFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>
export const DeleteCustomerAddressDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCustomerAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomerAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DeleteCustomerAddressMutation, DeleteCustomerAddressMutationVariables>
export const UpdateCustomerAddressDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCustomerAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomerAddressInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomerAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'customerAddress' } },
              ],
            },
          },
        ],
      },
    },
    ...CustomerAddressFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>
export const GetCustomerAddressesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerAddresses' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'default_billing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'default_shipping' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'customerAddress' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CustomerAddressFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<GetCustomerAddressesQuery, GetCustomerAddressesQueryVariables>
export const CreateCustomerCardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCustomerCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TokenBaseCardCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTokenBaseCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'tokenBaseCard' } },
              ],
            },
          },
        ],
      },
    },
    ...TokenBaseCardFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<CreateCustomerCardMutation, CreateCustomerCardMutationVariables>
export const DeleteCustomerCardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCustomerCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hash' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTokenBaseCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hash' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hash' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DeleteCustomerCardMutation, DeleteCustomerCardMutationVariables>
export const AddSimpleAndVirtualProductsToCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addSimpleAndVirtualProductsToCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'simpleCartItems' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SimpleProductCartItemInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualCartItems' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'VirtualProductCartItemInput' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addSimpleProductsToCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_items' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'simpleCartItems' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addVirtualProductsToCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_items' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'virtualCartItems' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  AddSimpleAndVirtualProductsToCartMutation,
  AddSimpleAndVirtualProductsToCartMutationVariables
>
export const AddSimpleProductsToCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addSimpleProductsToCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartItems' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SimpleProductCartItemInput' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addSimpleProductsToCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_items' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartItems' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  AddSimpleProductsToCartMutation,
  AddSimpleProductsToCartMutationVariables
>
export const ApplyCouponToCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'applyCouponToCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'couponCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyCouponToCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'coupon_code' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'couponCode' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<ApplyCouponToCartMutation, ApplyCouponToCartMutationVariables>
export const SetBillingAddressAndPaymentMethodOnCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setBillingAddressAndPaymentMethodOnCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingAddressInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentMethod' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentMethodInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setBillingAddressOnCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'billing_address' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'billingAddress' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setPaymentMethodOnCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'payment_method' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentMethod' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  SetBillingAddressAndPaymentMethodOnCartMutation,
  SetBillingAddressAndPaymentMethodOnCartMutationVariables
>
export const SetBillingAddressOnCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setBillingAddressOnCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingAddressInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setBillingAddressOnCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'billing_address' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'billingAddress' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  SetBillingAddressOnCartMutation,
  SetBillingAddressOnCartMutationVariables
>
export const CreateEmptyCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEmptyCart' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'createEmptyCart' } }],
      },
    },
  ],
} as unknown) as DocumentNode<CreateEmptyCartMutation, CreateEmptyCartMutationVariables>
export const SetDropShipOnCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setDropShipOnCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dropShip' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDropShipOnCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'drop_ship' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'dropShip' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<SetDropShipOnCartMutation, SetDropShipOnCartMutationVariables>
export const GetGuestCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGuestCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cart_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<GetGuestCartQuery, GetGuestCartQueryVariables>
export const SetHoldUntilMondayOnCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setHoldUntilMondayOnCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'holdUntilMonday' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setHoldUntilMondayOnCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hold_until_monday' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'holdUntilMonday' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  SetHoldUntilMondayOnCartMutation,
  SetHoldUntilMondayOnCartMutationVariables
>
export const MergeCartsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'mergeCarts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceCartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'destinationCartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mergeCarts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'source_cart_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceCartId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destination_cart_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'destinationCartId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<MergeCartsMutation, MergeCartsMutationVariables>
export const SetPaymentMethodOnCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setPaymentMethodOnCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentMethod' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentMethodInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setPaymentMethodOnCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'payment_method' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentMethod' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  SetPaymentMethodOnCartMutation,
  SetPaymentMethodOnCartMutationVariables
>
export const RemoveCouponFromCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeCouponFromCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'couponCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCouponFromCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'coupon_code' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'couponCode' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<RemoveCouponFromCartMutation, RemoveCouponFromCartMutationVariables>
export const RemoveItemFromCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeItemFromCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'itemId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeItemFromCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_item_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'itemId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>
export const SetCartAttributesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setCartAttributes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'practitionerCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'wellWorldId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'wellWorldPracticeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'wellWorldPractitionerId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'wellWorldStoreId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dispensaryPromoCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'eScriptCampaign' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setCartAttributes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'practitioner_code' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'practitionerCode' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ww_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'wellWorldId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ww_practice_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'wellWorldPracticeId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ww_practitioner_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'wellWorldPractitionerId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ww_store_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'wellWorldStoreId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dispensary_promo_code' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dispensaryPromoCode' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'e_script_campaign' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'eScriptCampaign' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<SetCartAttributesMutation, SetCartAttributesMutationVariables>
export const SetDispensaryPromoCodeOnCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setDispensaryPromoCodeOnCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dispensaryPromoCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDispensaryPromoCodeOnCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dispensary_promo_code' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dispensaryPromoCode' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  SetDispensaryPromoCodeOnCartMutation,
  SetDispensaryPromoCodeOnCartMutationVariables
>
export const SetShippingAddressesOnCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setShippingAddressesOnCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shippingAddress' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShippingAddressInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setShippingAddressesOnCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shipping_addresses' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'shippingAddress' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  SetShippingAddressesOnCartMutation,
  SetShippingAddressesOnCartMutationVariables
>
export const SetShippingMethodsOnCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setShippingMethodsOnCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shippingMethods' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShippingMethodInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setShippingMethodsOnCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shipping_methods' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'shippingMethods' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  SetShippingMethodsOnCartMutation,
  SetShippingMethodsOnCartMutationVariables
>
export const UpdateCartItemsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCartItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartItems' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'CartItemUpdateInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCartItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_items' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartItems' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<UpdateCartItemsMutation, UpdateCartItemsMutationVariables>
export const GetCustomerCartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerCart' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerCart' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'cart' } }],
            },
          },
        ],
      },
    },
    ...CartFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<GetCustomerCartQuery, GetCustomerCartQueryVariables>
export const CybersourceCardListDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'cybersourceCardList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tokenBaseCards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cc_exp_month' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cc_exp_year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cc_last4' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cc_type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country_code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'region_code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'region_id' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'telephone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CybersourceCardListQuery, CybersourceCardListQueryVariables>
export const PlaceOrderWithPaymentMethodDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'placeOrderWithPaymentMethod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentMethod' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentMethodInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setPaymentMethodOnCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'payment_method' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentMethod' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selected_payment_method' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'purchase_order_number' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tokenbase_card_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tokenbase_data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'cc_last4' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'cc_exp_year' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'cc_exp_month' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'cc_type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'placeOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order_number' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  PlaceOrderWithPaymentMethodMutation,
  PlaceOrderWithPaymentMethodMutationVariables
>
export const PlaceOrderDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'placeOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'placeOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cart_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order_number' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PlaceOrderMutation, PlaceOrderMutationVariables>
export const ApplyCustomerStoreCreditDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApplyCustomerStoreCredit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MpStoreCreditCustomerSpending' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cart_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cartId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'amount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ApplyCustomerStoreCreditMutation,
  ApplyCustomerStoreCreditMutationVariables
>
export const UpdateCustomerStoreCreditDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCustomerStoreCredit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MpStoreCreditCustomerUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'amount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateCustomerStoreCreditMutation,
  UpdateCustomerStoreCreditMutationVariables
>
export const GetCustomerStoreCreditDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCustomerStoreCredit' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mp_store_credit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'mp_credit_balance' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetCustomerStoreCreditQuery, GetCustomerStoreCreditQueryVariables>
export const TokenBaseCardsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'tokenBaseCards' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tokenBaseCards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'tokenBaseCard' } },
              ],
            },
          },
        ],
      },
    },
    ...TokenBaseCardFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<TokenBaseCardsQuery, TokenBaseCardsQueryVariables>
export const CyberSourceFlexKeyDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cyberSourceFlexKey' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateFlexJsToken' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key_store' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CyberSourceFlexKeyMutation, CyberSourceFlexKeyMutationVariables>
export const GetAuthenticatedProductsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAuthenticatedProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductAttributeFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductAttributeSortInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filterInput' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currentPage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currentPage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page_info' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'page_size' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total_pages' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'current_page' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'attribute_code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stock_status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url_key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product_type_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'specialty' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ConfigurableProduct' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variant_index' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'used_attributes' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attribute_values' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'mapping' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'product_index' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attribute_product_list' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetAuthenticatedProductsQuery, GetAuthenticatedProductsQueryVariables>
export const GetBuilderProductsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBuilderProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sku' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SimpleProduct' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price_tiers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'final_price' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currency' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'url_key' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variant_index' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'used_attributes' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attribute_values' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'short_description' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetBuilderProductsQuery, GetBuilderProductsQueryVariables>
export const GetGuestProductsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGuestProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductAttributeFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductAttributeSortInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filterInput' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currentPage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currentPage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page_info' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'page_size' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total_pages' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'current_page' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'attribute_code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stock_status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url_key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product_type_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'specialty' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ConfigurableProduct' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variant_index' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'used_attributes' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attribute_values' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'mapping' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'product_index' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attribute_product_list' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetGuestProductsQuery, GetGuestProductsQueryVariables>
export const GetOrderProductsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrderProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sku' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'natural_product_number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SimpleProduct' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price_tiers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'final_price' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currency' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stock_configuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'min_sale_qty' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'url_key' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variant_index' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'used_attributes' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attribute_values' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'short_description' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetOrderProductsQuery, GetOrderProductsQueryVariables>
export const GetProductsByUrlKeyDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProductsByUrlKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'urlKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'url_key' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'urlKey' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'natural_product_number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stock_status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product_form' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'specialty' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url_key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'doc_patient_education' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'doc_tech_sheet' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'doc_clinical_trials' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'advisor_tags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content_left_public' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content_left_patient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content_left_professional' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content_left_both' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content_right_public' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content_right_patient' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content_right_professional' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'content_right_both' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'html' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'short_description' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'html' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media_gallery' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ConfigurableProduct' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variant_index' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'used_attributes' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attribute_values' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'mapping' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'product_index' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attribute_product_list' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variants' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'product' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'stock_configuration' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'min_sale_qty' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'max_sale_qty' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'qty_increments' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'natural_product_number' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'specialty' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'stock_status' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'out_of_stock_message' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'doc_patient_education' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'doc_tech_sheet' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'doc_clinical_trials' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'advisor_tags' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'content_left_public' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'content_left_patient' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'content_left_professional',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'content_left_both' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'content_right_public' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'content_right_patient' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'content_right_professional',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'content_right_both' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'description' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'short_description' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'html' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {
                                              kind: 'Name',
                                              value: 'PhysicalProductInterface',
                                            },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'weight' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'price_tiers' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'final_price' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'currency' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'value' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'image' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'media_gallery' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'position' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'disabled' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attributes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'value_index' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetProductsByUrlKeyQuery, GetProductsByUrlKeyQueryVariables>
