/* eslint-disable react/jsx-filename-extension */
import '@designsforhealth/dfh-fonts-gotham'
import '@designsforhealth/dfh-fonts-gotham-condensed'
import '@designsforhealth/dfh-fonts-gotham-narrow'
import '@designsforhealth/dfh-fonts-gotham-xnarrow'
import '@fontsource/herr-von-muellerhoff'

import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import Auth0WithFallbackProvider from './src/context/Auth0WithFallbackProvider'
import AuthorizedApolloProvider from './src/context/AuthorizedApolloProvider'
import CartContextProvider from './src/context/CartContextProvider'
import ClickWrapModalContextProvider from './src/context/ClickWrapModalContextProvider'
import CompletedCartsContextProvider from './src/context/CompletedCartsContextProvider'
import HubSpotContextProvider from './src/context/HubSpotContextProvider'
import {
  LocationContextProvider,
  LocationContextUpdate,
} from './src/context/LocationContextProvider'
import SanityUserRolesContextProvider from './src/context/SanityUserRolesContextProvider'
import StyledModalProvider from './src/context/StyledModalProvider'
import ToastContextProvider from './src/context/ToastContextProvider'
import { ToggleCartContextProvider } from './src/context/ToggleCartContextProvider'
import TokenContextProvider from './src/context/TokenContextProvider'
import { getLoginCallbackUrl } from './src/lib/auth'
import { onRedirectCallback } from './src/lib/auth/callback'
import theme from './src/theme'
import customTheme from './src/theme/mui'

import '@fontsource/roboto-condensed/latin.css'
import '@fontsource/roboto/latin.css'

// Cypress tests use localStorage for tokens
const auth0CacheLocation =
  process.env.GATSBY_AUTH0_CACHE_LOCATION === 'localStorage' ? 'localstorage' : 'memory'

export const wrapRootElement = ({ element }) => (
  <Auth0WithFallbackProvider
    domain={process.env.GATSBY_AUTH0_DOMAIN}
    clientId={process.env.GATSBY_AUTH0_CLIENTID}
    audience={process.env.GATSBY_AUTH0_AUDIENCE}
    scope={`openid profile email read:professional_library read:customers
    update:customers magento_user accept_agreement:customers purchase:test_kit`}
    redirectUri={getLoginCallbackUrl()}
    onRedirectCallback={onRedirectCallback}
    cacheLocation={auth0CacheLocation}
  >
    <TokenContextProvider>
      <AuthorizedApolloProvider>
        <LocationContextProvider>
          <ToggleCartContextProvider>
            <CartContextProvider>
              <ToastContextProvider>
                <CompletedCartsContextProvider>
                  <SanityUserRolesContextProvider>
                    <StyledThemeProvider theme={theme}>
                      <MuiThemeProvider theme={customTheme}>
                        <ClickWrapModalContextProvider>
                          <StyledModalProvider>
                            <HubSpotContextProvider>{element}</HubSpotContextProvider>
                          </StyledModalProvider>
                        </ClickWrapModalContextProvider>
                      </MuiThemeProvider>
                    </StyledThemeProvider>
                  </SanityUserRolesContextProvider>
                </CompletedCartsContextProvider>
              </ToastContextProvider>
            </CartContextProvider>
          </ToggleCartContextProvider>
        </LocationContextProvider>
      </AuthorizedApolloProvider>
    </TokenContextProvider>
  </Auth0WithFallbackProvider>
)

export const wrapPageElement = ({ element, props: { location } }) => {
  return <LocationContextUpdate location={location}>{element}</LocationContextUpdate>
}
