import { useCallback } from 'react'

import { eScript20230411Version } from '../../../escript/config'
import { EScriptParamsLoader } from '../../../escript/types'

export type UseEmptyCart = [triggerEvent: () => Promise<void>]
export interface UseGtagEventTriggerOptions {
  eScriptParamsLoader: EScriptParamsLoader
}

const sendGtagEvent = (eventName: string, eventData = {}) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', eventName, eventData)
  }
}

export const useGtagEventTrigger = ({
  eScriptParamsLoader,
}: UseGtagEventTriggerOptions): UseEmptyCart => {
  const { loadParams } = eScriptParamsLoader

  const triggerEvent = useCallback(async (): Promise<void> => {
    const [{ version, cartAttributes, link }] = await Promise.all([loadParams()])

    if (version === 'well_world' && cartAttributes?.type === 'wellWorld') {
      sendGtagEvent('add_escript_to_cart', {
        escript_version: version,
        escript_campaign: 'well_world',
        escript_link: link,
      })
    }

    if (version === eScript20230411Version && cartAttributes?.type === 'eScriptCampaign') {
      sendGtagEvent('add_escript_to_cart', {
        escript_version: version,
        escript_campaign: cartAttributes?.attributes.eScriptCampaign,
        escript_link: link,
      })
    }
  }, [loadParams])

  return [triggerEvent]
}
